/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import { Checkbox } from '../../../../components/checkbox/checkbox';
import { approverFormSchema } from '../../config/forms/validation';

import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Form,
  FormLabel,
} from '../../../../components/form/form';
import SaveApproveRequestModal from '../Modal/SaveApproveRequestModal';
import Button from '../../../../components/button/button';
import {
  addApprovers,
  getApprovers,
  getFormResponseDetailById,
} from '../../../../redux/features/forms/formSlice';
import { Role } from '../../../../constants/role';

export default function FormResponseDetailApproverTabContent() {
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const { responseId } = useParams();
  const { isApproversAddLoading } = useSelector((state) => state.form);
  const { user } = useSelector((state) => state.user);

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(approverFormSchema),
  });

  const dispatch = useDispatch();

  const { approvers, formResponseDetail } = useSelector((state) => state.form);

  const onSaveApproval = async () => {
    try {
      const result = await dispatch(
        addApprovers({
          approvers: form.watch('approvers'),
          formResponseId: responseId,
        }),
      );

      unwrapResult(result);
      dispatch(getFormResponseDetailById(responseId));

      toast.success('Approval access saved successfully');
      setIsApproveModalOpen(false);
    } catch (error) {
      toast.error('Failed to save approval access');
    }
  };

  useEffect(() => {
    dispatch(getApprovers());
  }, []);

  const approverOptions = approvers.map((approver) => ({
    id: approver.id,
    name: approver.fullname || approver.email,
  }));

  useEffect(() => {
    if (approvers.length > 0 && formResponseDetail) {
      const approversId = formResponseDetail?.approvers?.map((approver) => approver.id);
      form.setValue('approvers', approversId);
    }
  }, [approvers, formResponseDetail]);

  return (
    <Form {...form}>
      <h2 className="font-semibold text-neutral-color-darker text-base">
        Who has access to approve this request
      </h2>

      <div className="my-4">
        <FormField
          control={form.control}
          name="approvers"
          render={() => (
            <FormItem className="grid grid-cols-2 gap-4 space-y-0">
              {approverOptions?.map((approver) => (
                <FormField
                  key={approver.id}
                  control={form.control}
                  name="approvers"
                  render={({ field }) => {
                    return (
                      <FormItem className="space-y-0">
                        <FormControl className="flex items-center space-x-2">
                          <Checkbox
                            disabled={
                              formResponseDetail?.approvers?.length > 0 || user.role !== Role.Admin
                            }
                            checked={field.value?.includes(approver.id)}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              return checked
                                ? field.onChange([...(field.value || []), approver.id])
                                : field.onChange(
                                    field.value?.filter((value) => value !== approver.id),
                                  );
                            }}
                          />
                          <FormLabel className="text-sm font-normal">{approver.name}</FormLabel>
                        </FormControl>
                      </FormItem>
                    );
                  }}
                />
              ))}
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className="border border-neutral-color-divider py-3 px-4 rounded-lg bg-neutral-variant-color-lighter">
        <h2 className="mb-0 font-semibold text-neutral-color-darker text-sm">
          Please review the form details carefully before you approved or rejected the request.
        </h2>
      </div>

      <div className="flex justify-end mt-4">
        <Button
          className="w-[100px] h-[42px] rounded-[8px]"
          onClick={() => {
            if (form.formState.errors && form.watch('approvers')) {
              setIsApproveModalOpen(true);
            }
          }}
          disabled={formResponseDetail?.approvers?.length > 0 || user.role !== Role.Admin}
        >
          Save
        </Button>
      </div>

      <SaveApproveRequestModal
        open={isApproveModalOpen}
        onClose={() => setIsApproveModalOpen(false)}
        onSaveApproval={onSaveApproval}
        isLoading={isApproversAddLoading}
      />
    </Form>
  );
}
