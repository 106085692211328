import React from 'react';
import { Drawer as MaterialDrawer } from '@material-ui/core';

function Drawer({ open, onClose, children }) {
  return (
    <MaterialDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        className: 'w-full max-w-[600px] h-full',
      }}
    >
      {children}
    </MaterialDrawer>
  );
}

export default Drawer;
