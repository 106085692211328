import React from 'react';

export default function DownIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.7}
        d="m14.5 5.503-5.734 5.734a1.083 1.083 0 0 1-1.532 0L1.5 5.503"
      />
    </svg>
  );
}
