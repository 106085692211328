import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '../../components/button/button';
import FormPreviewHeader from './components/FormPreview/FormPreviewHeader';
import FormPreviewSection from './components/FormPreview/FormPreviewSection';
import { getFormDetailById } from '../../redux/features/forms/formSlice';
import { Form } from '../../components/form/form';
import { generateValidationSchema } from './utils/schema';

export default function FormPreview() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const formSessionStorage = window.sessionStorage.getItem('form')
    ? JSON.parse(window.sessionStorage.getItem('form'))
    : null;

  const schema = formSessionStorage.forms[currentIndex].items
    ? generateValidationSchema(formSessionStorage.forms[currentIndex].items)
    : undefined;

  const form = useForm({
    mode: 'onChange',
    resolver: schema ? yupResolver(schema) : undefined,
  });

  useEffect(() => {
    if (id) {
      dispatch(getFormDetailById(id));
    }
  }, [id]);

  return (
    <Form {...form}>
      <form className="py-[73px] bg-lightGray max-w-[848px] mx-auto px-4 h-full shadow-sm space-y-6">
        <FormPreviewHeader />
        <FormPreviewSection currentIndex={currentIndex} data={formSessionStorage} />

        <div className="flex items-center justify-between">
          {currentIndex !== 0 && (
            <div>
              <Button
                variant="transparent"
                className="w-[56px] h-[42px]"
                onClick={() => setCurrentIndex((prev) => prev - 1)}
              >
                Back
              </Button>
            </div>
          )}

          {currentIndex !== formSessionStorage.forms.length - 1 && (
            <div>
              <Button
                variant="transparent"
                className="w-[56px] h-[42px]"
                onClick={() => setCurrentIndex((prev) => prev + 1)}
              >
                Next
              </Button>
            </div>
          )}
        </div>
      </form>
    </Form>
  );
}
