/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable  no-lone-blocks */
/* eslint-disable  no-unused-expressions */
/* eslint-disable   react/jsx-filename-extension */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import classes from '../assetsTable/assetsTable.module.css';
import { cn } from '../../utils/utilClassNames';

export default function Pagination({
  totalRows,
  pageChangeHandler,
  rowPerPage,
  currentPage,
  changeRowsPerPage,
  className,
  withRedux = true,
}) {
  const totalPageCount = Math.ceil(+totalRows / +rowPerPage);

  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  const [jumpToPageNumber, setJumpToPageNumber] = useState(null);
  const [showJumpToPage, setShowJumpToPage] = useState(false);

  const dispatch = useDispatch();

  // Onclick handlers for the butons
  const onNextPage = () => {
    if (withRedux) {
      dispatch(pageChangeHandler(currentPage + 1));
    } else {
      pageChangeHandler(currentPage + 1);
    }
  };
  const onPrevPage = () => {
    if (withRedux) {
      dispatch(pageChangeHandler(currentPage - 1));
    } else {
      pageChangeHandler(currentPage - 1);
    }
  };

  // Disable previous and next buttons in the first and last page
  // respectively
  useEffect(() => {
    if (totalPageCount === currentPage || !totalPageCount) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [totalPageCount, currentPage]);

  const handelJumpToPage = () => {
    if (+jumpToPageNumber >= 1 || +jumpToPageNumber <= totalPageCount) {
      if (withRedux) {
        dispatch(pageChangeHandler(+jumpToPageNumber));
      } else {
        pageChangeHandler(+jumpToPageNumber);
      }

      setJumpToPageNumber(null);
      setShowJumpToPage(false);
    }
  };

  return (
    <>
      {showJumpToPage && (
        <div onClick={() => setShowJumpToPage(!showJumpToPage)} className={classes.overlay} />
      )}
      <div className={cn(classes.pagination, className)}>
        {showJumpToPage && (
          <div className={classes.jumpToBageWrapper}>
            <span>Jump to page</span>
            <input
              type="number"
              value={jumpToPageNumber}
              onChange={(e) => setJumpToPageNumber(e.target.value)}
            />
            of {totalPageCount === 0 ? 1 : totalPageCount}
            <button
              type="button"
              disabled={+jumpToPageNumber < 1 || +jumpToPageNumber > totalPageCount}
              onClick={handelJumpToPage}
            >
              Go
            </button>
          </div>
        )}
        <select
          className={classes.select}
          value={rowPerPage}
          onChange={(e) => {
            if (withRedux) {
              dispatch(changeRowsPerPage(e.target.value));
            } else {
              changeRowsPerPage(e.target.value);
            }
          }}
        >
          {[10, 25, 50, 100].map((size) => (
            <option key={size} value={size}>
              {' '}
              {size} Result per page
            </option>
          ))}
        </select>
        <div className={classes.buttons}>
          <button disabled={!canGoBack} type="button" onClick={onPrevPage}>
            Previous
          </button>
          <span onClick={() => setShowJumpToPage(!showJumpToPage)}>
            <div className="mr-1">
              {currentPage} of {totalPageCount === 0 ? 1 : totalPageCount} pages
            </div>{' '}
            <FontAwesomeIcon icon={faAngleDown} />{' '}
          </span>
          <button disabled={!canGoNext} type="button" onClick={onNextPage}>
            Next
          </button>
        </div>
      </div>
    </>
  );
}
