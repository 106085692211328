import React from 'react';
import { useSelector } from 'react-redux';

import {
  FormInfoContainer,
  FormInfoDescription,
  FormInfoDescriptionTitle,
  FormInfoTitle,
} from '../Form/FormInfo';
import CircleIcon from '../../../../assets/svg-icons/CircleIcon';

export default function FormDetailResponsesInfo() {
  const { formResponseList } = useSelector((state) => state.form);

  return (
    <div className="flex items-center gap-4 mt-4 mb-6 flex-wrap">
      <FormInfoContainer>
        <FormInfoTitle>{formResponseList.total_responses || 0}</FormInfoTitle>
        <FormInfoDescription>
          <FormInfoDescriptionTitle>Total Respond</FormInfoDescriptionTitle>
        </FormInfoDescription>
      </FormInfoContainer>

      <FormInfoContainer>
        <FormInfoTitle>{formResponseList.new_request_count || 0}</FormInfoTitle>
        <FormInfoDescription>
          <CircleIcon className="text-theme-color-primary-alpha-secondary" />

          <FormInfoDescriptionTitle>New Request</FormInfoDescriptionTitle>
        </FormInfoDescription>
      </FormInfoContainer>

      <FormInfoContainer>
        <FormInfoTitle>{formResponseList?.approved_count || 0}</FormInfoTitle>
        <FormInfoDescription>
          <CircleIcon className="text-[#20BB7DA3]" />

          <FormInfoDescriptionTitle>Approved</FormInfoDescriptionTitle>
        </FormInfoDescription>
      </FormInfoContainer>

      <FormInfoContainer>
        <FormInfoTitle>{formResponseList.waiting_approval_count || 0}</FormInfoTitle>
        <FormInfoDescription>
          <CircleIcon className="text-generic-color-alpha-orange" />

          <FormInfoDescriptionTitle>Awaiting Approval</FormInfoDescriptionTitle>
        </FormInfoDescription>
      </FormInfoContainer>

      <FormInfoContainer>
        <FormInfoTitle>{formResponseList.closed_count_count || 0}</FormInfoTitle>
        <FormInfoDescription>
          <CircleIcon className="text-darkGrey" />

          <FormInfoDescriptionTitle>Closed</FormInfoDescriptionTitle>
        </FormInfoDescription>
      </FormInfoContainer>

      <FormInfoContainer>
        <FormInfoTitle>{formResponseList?.canceled_count || 0}</FormInfoTitle>
        <FormInfoDescription>
          <CircleIcon className="text-[#E57586]" />

          <FormInfoDescriptionTitle>Cancelled</FormInfoDescriptionTitle>
        </FormInfoDescription>
      </FormInfoContainer>
    </div>
  );
}
