import React from 'react';

export default function QRIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.75}
        d="M8.542 8.542h.25m-.25.25h.25m-.25 3.75h.25m-.25.25h.25m1.75-2.25h.25m-.25.25h.25m1.75-2.25h.25m-.25.25h.25m-.25 3.75h.25m-.25.25h.25M2.333 5.667h2c.737 0 1.334-.597 1.334-1.334v-2C5.667 1.597 5.07 1 4.333 1h-2C1.597 1 1 1.597 1 2.333v2c0 .737.597 1.334 1.333 1.334Zm0 7.333h2c.737 0 1.334-.597 1.334-1.333v-2c0-.737-.597-1.334-1.334-1.334h-2C1.597 8.333 1 8.93 1 9.667v2C1 12.403 1.597 13 2.333 13Zm7.334-7.333h2c.736 0 1.333-.597 1.333-1.334v-2C13 1.597 12.403 1 11.667 1h-2c-.737 0-1.334.597-1.334 1.333v2c0 .737.597 1.334 1.334 1.334Zm-.667 3a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0Zm0 4a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0Zm2-2a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0Zm2-2a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0Zm0 4a.333.333 0 1 1-.667 0 .333.333 0 0 1 .667 0Z"
      />
    </svg>
  );
}
