import React, { forwardRef } from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { cn } from '../../utils/utilClassNames';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverContent = forwardRef(
  ({ className, align = 'center', isPortal = true, sideOffset = 4, ...props }, ref) => {
    const Portal = isPortal ? PopoverPrimitive.Portal : React.Fragment;
    return (
      <Portal>
        <PopoverPrimitive.Content
          ref={ref}
          align={align}
          sideOffset={sideOffset}
          className={cn(
            'z-50 w-72 rounded-md border bg-neutral-color-theme-color-light shadow-md outline-none',
            className,
          )}
          {...props}
        />
      </Portal>
    );
  },
);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent, PopoverAnchor };
