import React from 'react';

export default function ShareIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={13} height={14} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeWidth={1.5}
        d="M4.997 7a1.667 1.667 0 1 1-3.333 0 1.667 1.667 0 0 1 3.333 0Z"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M8.33 3.333 4.995 5.668m3.333 5L4.996 8.334"
      />
      <path
        stroke="currentColor"
        strokeWidth={1.5}
        d="M11.665 11.333a1.666 1.666 0 1 1-3.332 0 1.666 1.666 0 0 1 3.332 0Zm0-8.666a1.667 1.667 0 1 1-3.333 0 1.667 1.667 0 0 1 3.333 0Z"
      />
    </svg>
  );
}
