import React from 'react';

export default function Rotate360Icon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.75}
        d="M12.963 3.286A6.667 6.667 0 0 0 1.582 8m1.953 4.714A6.667 6.667 0 0 0 14.915 8m-1.333-6.667v2.6c0 .037-.03.067-.067.067h-2.6m-8 10.667v-2.6c0-.037.03-.067.067-.067h2.6"
      />
    </svg>
  );
}
