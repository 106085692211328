import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { unwrapResult } from '@reduxjs/toolkit';

import FormDetailResponsesInfo from '../FormDetail/FormDetailResponsesInfo';
import FormDetailResponsesFilter from '../FormDetail/FormDetailResponsesFilter';
import { useDetailFormRequestResponsesFilter } from '../../hooks/useDetailFormRequestResponsesFilter';
import DeleteFormModal from '../Modal/DeleteFormModal';

import BulkDeleteFormModal from '../Modal/BulkDeleteFormModal';
import Table from '../../../../components/crudTable/crudTable';
import {
  bulkDeleteFormResponseByIds,
  deleteFormResponseById,
  getApprovers,
  getFormResponseListById,
} from '../../../../redux/features/forms/formSlice';
import TakeActionResponseFormModal from '../Modal/TakeActionResponseFormModal';

export default function FormRequestDetailResponses() {
  const [isTakeActionDrawerOpen, setIsTakeActionDrawerOpen] = React.useState(false);
  const [isDeleteFormModalOpen, setIsDeleteFormModalOpen] = React.useState(false);
  const [isBulkDeleteFormModalOpen, setIsBulkDeleteFormModalOpen] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const { id } = useParams();

  const { allColumns, filteredColumns, selectedFilter, setSelectedFilter } =
    useDetailFormRequestResponsesFilter({
      setIsDeleteFormModalOpen,
      setIsTakeActionDrawerOpen,
    });

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    formResponseList,
    filterStatusResponse,
    selectedFormResponseId,
    filterRequesterResponse,
    isFormResponseDeleteLoading,
    isFormResponseBulkDeleteByIdsLoading,
    filterSentOnDateState,
    filterApproverResponse,
  } = useSelector((state) => state.form);

  const getFormData = () => {
    dispatch(
      getFormResponseListById({
        form_id: id,
        response_status: filterStatusResponse,
        approver_id: filterApproverResponse,
        submitted_by_id: filterRequesterResponse?.map((author) => author.value).join(','),
        submitted_at_start: filterSentOnDateState?.find((item) => item.startDate)?.startDate
          ? moment(filterSentOnDateState?.find((item) => item.startDate)?.startDate).format(
              'YYYY-MM-DD',
            )
          : '',
        submitted_at_end: filterSentOnDateState?.find((item) => item.startDate)?.startDate
          ? moment(filterSentOnDateState?.find((item) => item.endDate)?.endDate).format(
              'YYYY-MM-DD',
            )
          : '',
      }),
    );
  };
  const onDeleteFormResponseById = async () => {
    try {
      const result = await dispatch(deleteFormResponseById(selectedFormResponseId));
      unwrapResult(result);
      toast.success('Form response deleted successfully');
      getFormData();
      setIsDeleteFormModalOpen(false);
    } catch (error) {
      toast.error('Error deleting form response');
    }
  };

  const onBulkDeleteFormResponseByIds = async () => {
    const formResponseIds = selectedRows.map((row) => row.form_response_id);
    try {
      const result = await dispatch(bulkDeleteFormResponseByIds({ formResponseIds }));
      unwrapResult(result);
      toast.success('Selected form responses deleted successfully');
      getFormData();
      setIsBulkDeleteFormModalOpen(false);
    } catch (error) {
      toast.error('Error deleting selected form responses');
    }
  };

  useEffect(() => {
    dispatch(getFormResponseListById({ form_id: id }));
  }, [id]);

  useEffect(() => {
    dispatch(getApprovers());
  }, []);

  return (
    <div>
      <FormDetailResponsesInfo />

      <FormDetailResponsesFilter
        allColumns={allColumns}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
        onOpenBulkDelete={() => setIsBulkDeleteFormModalOpen(true)}
        selectedForms={selectedRows}
        getFormData={getFormData}
      />

      <Table
        columns={filteredColumns}
        data={formResponseList?.results || []}
        onRowClick={(row) => {
          history.push(`/forms/detail/${id}/responses/${row.form_response_id}`);
        }}
        onSelectionChange={(selectedFlatRows) => {
          setSelectedRows(selectedFlatRows);
        }}
      />

      <DeleteFormModal
        open={isDeleteFormModalOpen}
        onClose={() => setIsDeleteFormModalOpen(false)}
        onDelete={onDeleteFormResponseById}
        isDeleteLoading={isFormResponseDeleteLoading}
      />

      <BulkDeleteFormModal
        open={isBulkDeleteFormModalOpen}
        onClose={() => setIsBulkDeleteFormModalOpen(false)}
        onDelete={onBulkDeleteFormResponseByIds}
        isDeleting={isFormResponseBulkDeleteByIdsLoading}
      />
      <TakeActionResponseFormModal
        open={isTakeActionDrawerOpen}
        onClose={() => setIsTakeActionDrawerOpen(false)}
        getFormData={getFormData}
      />
    </div>
  );
}
