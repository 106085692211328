/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/function-component-definition */
// /* eslint-disable react/function-component-definition */

import React, { forwardRef } from 'react';
import { cn } from '../../utils/utilClassNames';

const Dialog = ({ open, onClose, children }) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 z-[1400] flex items-center justify-center">
      <DialogOverlay onClose={onClose} />
      {children}
    </div>
  );
};

const DialogTrigger = ({ children, onClick }) => {
  return <div onClick={onClick}>{children}</div>;
};

const DialogOverlay = ({ onClose }) => {
  return <div className="fixed inset-0 bg-black/80 z-[1300]" onClick={onClose} />;
};

const DialogContent = forwardRef(({ className, children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'bg-white fixed left-[50%] w-[380px] top-[50%] grid translate-x-[-50%] translate-y-[-50%] gap-4 border p-6 shadow-lg rounded-[8px] max-w-[90%] z-[1400]',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
});
DialogContent.displayName = 'DialogContent';

const DialogHeader = ({ className, ...props }) => (
  <div className={cn('flex flex-col space-y-1.5 text-left sm:text-left', className)} {...props} />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({ className, ...props }) => (
  <div
    className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2', className)}
    {...props}
  />
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = forwardRef(({ className, ...props }, ref) => {
  return (
    <h2
      ref={ref}
      className={cn('text-lg font-semibold leading-none tracking-tight', className)}
      {...props}
    />
  );
});
DialogTitle.displayName = 'DialogTitle';

const DialogDescription = forwardRef(({ className, ...props }, ref) => {
  return (
    <p ref={ref} className={cn('text-sm text-neutral-color-darker mb-2', className)} {...props} />
  );
});
DialogDescription.displayName = 'DialogDescription';

const DialogClose = ({ onClose, children }) => {
  return <div onClick={onClose}>{children}</div>;
};

export {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
};
