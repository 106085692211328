/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeToast } from '../../redux/features/toast/toastSlice';
import Button from '../button/button';
import SuccessIcon from '../../assets/svg-icons/SuccessIcon';
import TimeCircleIcon from '../../assets/svg-icons/TimeCircleIcon';

const Toast = () => {
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.toast);

  if (toasts.length === 0) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-black/70 z-[9999] bg-opacity-50">
      <div className="fixed top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 bg-[#FFFFFF] space-y-3 z-[9999] rounded-[8px] shadow-md">
        {toasts.map((toast) => (
          <div
            key={toast.id}
            className="bg-[#FFFFFF] flex justify-center flex-col items-center max-w-full w-[379px] rounded-[8px] p-8 gap-6"
          >
            {toast.type === 'success' ? (
              <SuccessIcon className="text-[#2ABC97]" />
            ) : (
              <TimeCircleIcon className="text-[#9DA0A6]" />
            )}
            {toast.type === 'success' ? (
              <>
                <p className="mb-0 font-semibold text-neutral-color-darker text-2xl text-center">
                  {toast.message}
                </p>
                <Button
                  className="w-[100px] h-[42px]"
                  variant="outline"
                  onClick={() => dispatch(removeToast(toast.id))}
                >
                  Close
                </Button>
              </>
            ) : (
              <>
                <div>
                  <p className="mb-0 font-semibold text-neutral-color-darker text-2xl text-center">
                    Oops
                  </p>
                  <p className="mb-0 text-neutral-color-dark text-base text-center">
                    {toast.message}
                  </p>
                </div>

                <div className="flex items-center justify-center gap-2">
                  <Button
                    className="w-[100px] h-[42px]"
                    variant="outline"
                    onClick={() => dispatch(removeToast(toast.id))}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="w-[100px] h-[42px]"
                    onClick={() => dispatch(removeToast(toast.id))}
                  >
                    Retry
                  </Button>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Toast;
