import React from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import FormResponseDetailRequesterInformation from './FormResponseDetailRequesterInformation';
import FormResponseDetailFormAnswer from './FormResponseDetailFormAnswer';
import Label from '../../../../components/label/label';
import TextInput from '../../../../components/input/textInput';
import CalendarIcon from '../../../../assets/svg-icons/CalendarIcon';

export default function FormResponseDetailFormDetailTabContent() {
  const { formResponseDetail } = useSelector((state) => state.form);

  return (
    <>
      <div className="flex flex-col gap-4 mb-4 mt-4">
        <h2 className="text-base font-semibold text-neutral-color-darker">Due Date</h2>

        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <Label isRequired>Start Date</Label>
            <TextInput
              disabled
              variant="filled"
              suffix={<CalendarIcon className="text-neutral-color-main" />}
              value={
                formResponseDetail?.start_time
                  ? format(new Date(formResponseDetail?.start_time), 'MMM, d yyyy')
                  : '-'
              }
            />
          </div>

          <div className="space-y-2">
            <Label isRequired>End Date</Label>
            <TextInput
              disabled
              variant="filled"
              suffix={<CalendarIcon className="text-neutral-color-main" />}
              value={
                formResponseDetail?.end_time
                  ? format(new Date(formResponseDetail?.end_time), 'MMM, d yyyy')
                  : '-'
              }
            />
          </div>
        </div>
      </div>

      <FormResponseDetailRequesterInformation />
      <FormResponseDetailFormAnswer />
    </>
  );
}
