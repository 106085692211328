import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../components/dialog/dialog';
import Button from '../../../../components/button/button';

export default function PublishFormModal({ open, onClose, onPublish, isLoading }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className="w-[480px]">
        <DialogHeader>
          <DialogTitle>Publish this form?</DialogTitle>
          <DialogDescription>
            Once published,{' '}
            <span className="font-semibold">you can&apos;t edit the form anymore.</span> To make
            changes, you will need to create a new form.
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="gap-2 sm:*:flex-1">
          <Button size="large" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button size="large" onClick={onPublish} isLoading={isLoading}>
            {isLoading ? 'Publishing...' : 'Publish'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
