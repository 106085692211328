import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getActivityLog } from '../../../../redux/features/forms/formSlice';
import { capitalizeFirstLetter } from '../../../../utils/text';

export default function FormSurveyDetailResponseActivitiesTab() {
  const { formResponseActivityLogs } = useSelector((state) => state.form);
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getActivityLog({
        form_id: id,
      }),
    );
  }, [id]);

  return (
    <div>
      <h2 className="text-neutral-color-darker font-semibold text-base mt-3">Activity Log</h2>

      <div>
        <div className="activity-log">
          <ul className="log-list">
            {formResponseActivityLogs &&
              formResponseActivityLogs?.length > 0 &&
              formResponseActivityLogs.map((act) => (
                <li key={act.id} className="log-item">
                  <div className="log-icon !border-theme-color-alpha-secondary24">
                    <span className="dot !border-theme-color-secondary-main !border-[3px]" />
                  </div>
                  <div className="log-details px-0">
                    <div className="log-header">
                      {capitalizeFirstLetter(act.action)} by {act.name || '-'} on{' '}
                      {moment(act.timestamp).format('DD MMMM YYYY h:mm A')}
                    </div>
                    <div className="log-description">{act.comment || '-'}</div>
                  </div>
                </li>
              ))}
          </ul>
          <div className="history-footer">
            <div className="history-log-icon">
              <span className="history-log-icon-dot" />
            </div>
            All history shown
          </div>
        </div>
      </div>
    </div>
  );
}
