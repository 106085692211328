/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/prefer-default-export */
import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import { classNames as cn } from '../../utils';
import ArrowLeftIcon from '../../assets/svg-icons/ArrowLeftIcon';
import ArrowRightIcon from '../../assets/svg-icons/ArrowRightIcon';
import Button from '../button/button';

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  onSelect,
  onClickSet,
  disabled,
  ...rest
}) {
  const ref = React.useRef();

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn(
        'px-3 pt-3 pb-0 w-full max-w-[376px] bg-neutral-color-theme-color-light',
        className,
      )}
      classNames={{
        month: 'mx-auto',
        caption: 'relative',
        caption_label: 'text-sm text-center font-bold',
        nav: '',
        nav_button_previous: 'absolute left-0 top-1/2 transform -translate-y-1/2',
        nav_button_next: 'absolute right-0 top-1/2 transform -translate-y-1/2',
        table: ' mx-auto border-collapse space-y-1 mt-3',
        head_row: 'flex text-center',
        head_cell: 'text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: cn('relative p-0 text-center text-sm'),
        day: cn(
          //   buttonVariants({ variant: 'ghost' }),
          'h-8 w-8 p-0 font-normal aria-selected:opacity-100',
        ),
        day_range_start: 'day-range-start',
        day_range_end: 'day-range-end',
        day_selected: '!bg-generic-color-orange-main text-white rounded-full',
        day_outside: 'day-outside !text-placeholder',

        ...classNames,
      }}
      components={{
        IconLeft: ({ className, ...props }) => (
          <ArrowLeftIcon className={cn('h-4 w-4', className)} {...props} />
        ),
        IconRight: ({ className, ...props }) => (
          <ArrowRightIcon className={cn('h-4 w-4', className)} {...props} />
        ),

        Footer: ({ className, ...props }) => {
          return (
            <div
              className="border-t border-neutral-color-divider -mx-[74px] py-2 px-3 flex items-center justify-between"
              {...props}
            >
              <div>
                <h2 className="text-sm text-neutral-color-darker mb-0">Date</h2>
                <h2 className="text-2xl font-semibold text-neutral-color-darker mb-0">
                  {rest.selected ? format(rest.selected, 'd MMM yyyy') : 'Select a date'}
                </h2>
              </div>

              <Button
                variant="primary"
                className="h-[42px] w-[52px]"
                onClick={(e) => {
                  e.preventDefault();
                  onClickSet(ref.current);
                  onSelect(ref.current);
                }}
              >
                Set
              </Button>
            </div>
          );
        },
      }}
      onSelect={(value) => {
        ref.current = value;
        onSelect(value);
      }}
      {...rest}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
