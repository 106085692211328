import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button';

export default function DrawerHeader({ title, onClose, children }) {
  return (
    <div className="">
      <div className="navbar sticky-top col-lg-12 m-0 modal-header-background pr-0">
        <div className="col-12 row px-4 py-2">
          <div className="my-auto font-semibold text-2xl">{title}</div>

          <div className="row ml-auto">
            {children}

            <Button
              onClick={onClose}
              className="w-[42px] h-[42px] !bg-[#ffffff] flex-shrink-0"
              variant="outline"
            >
              <div className="fas fa-times" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

DrawerHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

DrawerHeader.defaultProps = {
  children: null,
};
