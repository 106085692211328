import React from 'react';

import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import useDragSorting from '../../../../hooks/useDragSorting';
import FormItem from '../Form/FormItem';
import { FormStatus } from '../../config/forms/constant';

export default function ChildSection({ index }) {
  const form = useFormContext();

  const { sensors, measuringConfig } = useDragSorting();

  const fields = form.watch(`forms.${index}.items`) || [];
  const { form: formDetail } = useSelector((state) => state.form);

  const isPublished = formDetail?.status === FormStatus.Published;

  function handleDragEnd(event) {
    if (isPublished) return;

    const { active, over } = event;
    if (active.id !== over?.id && over !== null) {
      const oldIndex = fields.findIndex((item) => item.id.toString() === active.id);
      const newIndex = fields.findIndex((item) => item.id.toString() === over?.id);
      const newData = arrayMove(fields, oldIndex, newIndex);
      const newOrder = newData.map((item, idx) => ({
        ...item,
        order: idx + 1,
      }));

      form.setValue(`forms.${index}.items`, newOrder);
    }
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      // eslint-disable-next-line react/jsx-no-bind
      onDragEnd={handleDragEnd}
      measuring={measuringConfig}
      modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
    >
      <SortableContext
        items={fields.map((value) => {
          return value.id.toString();
        })}
        strategy={verticalListSortingStrategy}
      >
        {fields?.map((item, fieldIndex) => (
          <FormItem key={item.id} id={item.id} parentIndex={index} index={fieldIndex} />
        ))}
      </SortableContext>
    </DndContext>
  );
}
