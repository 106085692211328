import React, { forwardRef } from 'react';
import { cn } from '../../utils/utilClassNames';

const RadioGroup = forwardRef(({ className, children, ...props }, ref) => {
  return (
    <div className={cn('grid gap-2', className)} {...props} ref={ref} role="radiogroup">
      {children}
    </div>
  );
});
RadioGroup.displayName = 'RadioGroup';

const RadioGroupItem = forwardRef(
  ({ className, value, name, checked, onChange, disabled, ...props }, ref) => {
    return (
      <label className={cn('flex items-center mb-0', className)}>
        <input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          ref={ref}
          className="hidden"
          {...props}
        />
        <div
          className={cn(
            'aspect-square h-4 w-4 rounded-full border border-neutral-color-darker flex items-center justify-center p-1 bg-white',
            disabled && ' border-neutral-color-main',
          )}
        >
          {checked && (
            <div
              className={cn(
                'h-2.5 w-2.5 rounded-full shrink-0 bg-neutral-color-darker',
                disabled && 'bg-neutral-color-main',
              )}
            />
          )}
        </div>
      </label>
    );
  },
);
RadioGroupItem.displayName = 'RadioGroupItem';

export { RadioGroup, RadioGroupItem };
