/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { getActivityLog } from '../../../../redux/features/forms/formSlice';
import { capitalizeFirstLetter } from '../../../../utils/text';

export default function FormResponseDetailActivityTabContent() {
  const dispatch = useDispatch();
  const { responseId } = useParams();

  const { formResponseActivityLogs } = useSelector((state) => state.form);

  useEffect(() => {
    dispatch(
      getActivityLog({
        form_response_id: responseId,
      }),
    );
  }, [responseId]);

  return (
    <div>
      <div className="activity-log">
        <ul className="log-list">
          {formResponseActivityLogs &&
            formResponseActivityLogs?.length > 0 &&
            formResponseActivityLogs.map((act) => (
              <li key={act.id} className="log-item">
                <div className="log-icon !border-theme-color-alpha-secondary24">
                  <span className="dot !border-theme-color-secondary-main !border-[3px]" />
                </div>
                <div className="log-details px-0">
                  <div className="log-header">
                    {capitalizeFirstLetter(act.action)} by {act.fullname || '-'} on{' '}
                    {moment(act.timestamp).format('DD MMMM YYYY h:mm A')}
                  </div>
                  <div className="log-description">{act.comment || '-'}</div>
                </div>
              </li>
            ))}
        </ul>
        <div className="history-footer">
          <div className="history-log-icon">
            <span className="history-log-icon-dot" />
          </div>
          All history shown
        </div>
      </div>
    </div>
  );
}
