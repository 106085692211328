/* eslint-disable no-plusplus */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
import React from 'react';
import { toTitleCase } from '../../../../utils/text';
import { generateHexColorArray } from '../../../../utils/color';

export default function FormSurveyCheckboxesSummary({ field }) {
  const totalItems = field?.responses.length;

  const flatItems = field?.responses.flat();

  const result = flatItems?.reduce((acc, item) => {
    acc[item] = acc[item] || { count: 0, percentage: 0 };
    acc[item].count++;
    acc[item].percentage = ((acc[item].count / flatItems.length) * 100).toFixed(2);
    if (acc[item].percentage % 1 === 0) {
      acc[item].percentage = `${parseInt(acc[item].percentage, 10)}%`;
    } else {
      acc[item].percentage = `${acc[item].percentage}%`;
    }
    return acc;
  }, {});

  const data = Object.entries(result).map(([key, value]) => ({
    name: key,
    value: value.count,
  }));

  const generatedColors = generateHexColorArray(data.length) || [];
  const colors = ['#D9314AA3', '#727478A3', '#6678D1A3', generatedColors];
  return (
    <div className="grid grid-cols-[300px_1fr] gap-6">
      <div>
        <div className="pb-2 mb-2 border-b border-neutral-color-divider">
          <h2 className="text-neutral-color-darker font-semibold text-md">{field?.field_label}</h2>

          <p className="text-right text-neutral-color-main">{toTitleCase(field?.field_type)}</p>
        </div>

        <div>
          <h2 className="text-neutral-color-main text-base mb-2">{totalItems || 0} responses</h2>
          {Object.entries(result).map(([key, value]) => (
            <h2 className="text-neutral-color-dark text-sm">
              {key} : {value.count} ({value.percentage})
            </h2>
          ))}
        </div>
      </div>

      <div className="bg-neutral-variant-color-lighter h-[272px] flex items-center gap-4 w-full p-[44px]">
        <div className="flex items-center gap-4">
          <div className="text-center">
            <h2 className="font-semibold text-[32px] text-neutral-color-dark">{totalItems}</h2>
            <p className="text-neutral-color-main text-sm mb-0">Responses</p>
          </div>
        </div>

        <div className="w-full flex-1">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              marginRight: '4px',
              flex: 1,
            }}
          >
            {data.map((item, index) => (
              <ProgressItem
                percent={result[item.name].percentage}
                title={item.name}
                color={colors[index]}
                showProgress={item.length - 1 === index}
                count={item.value}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function ProgressItem({ color, title, percent, count, showProgress }) {
  return (
    <div style={{ display: 'flex' }}>
      <div className="flex-1">
        <OPBar percentDue={percent ?? 0} fillColor={color} count={count} />
        {/* {showProgress && (
            <div className="grid grid-cols-5 mt-2">
              <h2>0</h2>
              <h2>20</h2>
              <h2>40</h2>
              <h2>60</h2>
              <h2>80</h2>
            </div>
          )} */}
      </div>
      <div style={{ width: '170px', marginLeft: '25px', marginRight: '18px' }}>
        <span
          style={{
            backgroundColor: color,
            width: '9px',
            height: '9px',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '8px',
          }}
        ></span>{' '}
        <span style={{ fontWeight: '600', fontSize: '14px', color: '#4A4C50' }}>{title}</span>
      </div>
    </div>
  );
}

function OPBar({ percentDue, fillColor, count }) {
  const progressBarStyle = {
    height: '12px',
    width: '100%',
    backgroundColor: '#e0e0e0',
    borderRadius: '200px',
    overflow: 'hidden',
    marginTop: '4px',
  };

  const progressFillStyle = {
    height: '100%',
    width: `${percentDue}`,
    backgroundColor: fillColor ?? 'purple',
    transition: 'width 0.3s ease',
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', flex: '1' }}>
      <div style={progressBarStyle}>
        <div style={progressFillStyle} />
      </div>
      <div
        style={{
          minWidth: '70px',
          marginLeft: '8px',
          marginRight: '12px',
          textAlign: 'end',
          fontWeight: '600',
          fontSize: '14px',
          color: '#4A4C50',
          marginTop: '2px',
          // border: '1px solid black'
        }}
      >
        {count} ({percentDue})
      </div>
    </div>
  );
}
