import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { smartTryCatch } from '../../../utils';
import FormService from './formService';

const initState = {
  activeIndex: '0-0',

  forms: [],
  form: {},
  formCategories: [],
  formTotal: {},
  selectedFormId: null,
  selectedFormResponseId: null,
  searchListValue: '',
  approvers: [],
  formResponseList: [],
  formResponseDetail: {},
  formResponseActivityLogs: [],
  surveySummary: [],
  formSurveyActivities: [],

  // Create Form
  isFormCreateLoading: false,
  isFormCreateSuccess: false,
  isFormCreateError: false,

  // Duplicate Form
  isFormDuplicateLoading: false,
  isFormDuplicateSuccess: false,
  isFormDuplicateError: false,

  // Get Form List
  isFormGetLoading: false,
  isFormGetSuccess: false,
  isFormGetError: false,

  // Get Form Total
  isFormTotalGetLoading: false,
  isFormTotalGetSuccess: false,
  isFormTotalGetError: false,

  // Get Form Category List
  isFormCategoryGetLoading: false,
  isFormCategoryGetSuccess: false,
  isFormCategoryGetError: false,

  // Get Form Detail
  isFormDetailGetLoading: false,
  isFormDetailGetSuccess: false,
  isFormDetailGetError: false,

  // Update Form
  isFormUpdateLoading: false,
  isFormUpdateSuccess: false,
  isFormUpdateError: false,

  // Delete Form
  isFormDeleteLoading: false,
  isFormDeleteSuccess: false,
  isFormDeleteError: false,

  // Publish Form
  isFormPublishLoading: false,
  isFormPublishSuccess: false,
  isFormPublishError: false,

  // Bulk Delete Form
  isFormBulkDeleteLoading: false,
  isFormBulkDeleteSuccess: false,
  isFormBulkDeleteError: false,

  // validate form
  isFormValidateLoading: false,
  isFormValidateSuccess: false,
  isFormValidateError: false,

  // Get Form Response List
  isFormResponseListLoading: false,
  isFormResponseListSuccess: false,
  isFormResponseListError: false,

  // Create Form Response
  isFormResponseCreateLoading: false,
  isFormResponseCreateSuccess: false,
  isFormResponseCreateError: false,

  // Update Form Response
  isFormResponseUpdateLoading: false,
  isFormResponseUpdateSuccess: false,
  isFormResponseUpdateError: false,

  // Delete Form Response
  isFormResponseDeleteLoading: false,
  isFormResponseDeleteSuccess: false,
  isFormResponseDeleteError: false,

  // Get Form Response Detail
  isFormResponseDetailGetLoading: false,
  isFormResponseDetailGetSuccess: false,
  isFormResponseDetailGetError: false,

  // Create Form Response By Id
  isFormResponseByIdCreateLoading: false,
  isFormResponseByIdCreateSuccess: false,
  isFormResponseByIdCreateError: false,

  // Add Approvers
  isApproversAddLoading: false,
  isApproversAddSuccess: false,
  isApproversAddError: false,

  // Get Approvers
  isApproversGetLoading: false,
  isApproversGetSuccess: false,
  isApproversGetError: false,

  // Get Activity Log By Form Id
  isActivityLogByFormResponseIdGetLoading: false,
  isActivityLogByFormResponseIdGetSuccess: false,
  isActivityLogByFormResponseIdGetError: false,

  // Approve/Reject Form
  isFormApproveRejectLoading: false,
  isFormApproveRejectSuccess: false,
  isFormApproveRejectError: false,

  // Get Form Survey Activities
  isFormSurveyActivitiesGetLoading: false,
  isFormSurveyActivitiesGetSuccess: false,
  isFormSurveyActivitiesGetError: false,

  // Get Survey Summary
  isSurveySummaryGetLoading: false,
  isSurveySummaryGetSuccess: false,
  isSurveySummaryGetError: false,

  // Delete Form Response by id
  isFormResponseDeleteByIdLoading: false,
  isFormResponseDeleteByIdSuccess: false,
  isFormResponseDeleteByIdError: false,

  // Bulk Delete Form Response by ids
  isFormResponseBulkDeleteByIdsLoading: false,
  isFormResponseBulkDeleteByIdsSuccess: false,
  isFormResponseBulkDeleteByIdsError: false,

  // toggle response form
  isFormToggleResponseLoading: false,
  isFormToggleResponseSuccess: false,
  isFormToggleResponseError: false,

  // Filter Form List
  filterCategory: '',
  filterFormType: '',
  filterStatus: '',

  filterStartCreatedOnDate: '',
  filterEndCreatedOnDate: '',
  filterCreatedOnDateState: [
    {
      startDate: '',
      endDate: '',
      key: 'selection',
    },
  ],

  filterStartPublishedOnDate: '',
  filterEndPublishedOnDate: '',
  filterPublishedOnDateState: [
    {
      startDate: '',
      endDate: '',
      key: 'selection',
    },
  ],

  filterPublishedBy: [],

  // filter form responses
  filterApproverResponse: '',
  filterStatusResponse: '',
  filterRequesterResponse: [],

  filterStartSentOnDate: '',
  filterEndSentOnDate: '',
  filterSentOnDateState: [
    {
      startDate: '',
      endDate: '',
      key: 'selection',
    },
  ],

  // Pagination Form List
  // pagination
  currentPage: 1,
  rowPerPage: 10,

  // Pagination Form Response List
  currentPageResponse: 1,
  rowPerPageResponse: 10,

  // respondent total row per page
  responsedentCurrentPage: 1,
  responsedentTotalRowPerPage: 1,
};

export const createForm = createAsyncThunk('createForm', async (data, thunkAPI) => {
  const createFormRes = await smartTryCatch(FormService.createForm, data, thunkAPI);
  return createFormRes;
});

export const getFormList = createAsyncThunk('getFormList', async (data, thunkAPI) => {
  const formList = await smartTryCatch(FormService.getFormList, data, thunkAPI);
  return formList;
});

export const getFormCategoryList = createAsyncThunk(
  'getFormCategoryList',
  async (data, thunkAPI) => {
    const formList = await smartTryCatch(FormService.getFormCategories, data, thunkAPI);
    return formList;
  },
);

export const getFormTotal = createAsyncThunk('getFormTotal', async (data, thunkAPI) => {
  const formList = await smartTryCatch(FormService.getFormTotal, data, thunkAPI);
  return formList;
});

export const getFormDetailById = createAsyncThunk('getFormDetailById', async (data, thunkAPI) => {
  const formList = await smartTryCatch(FormService.getFormDetailById, data, thunkAPI);
  return formList;
});

export const getFormDetailByIdWithoutAuth = createAsyncThunk(
  'getFormDetailByIdWithoutAuth',
  async (data, thunkAPI) => {
    const formList = await smartTryCatch(FormService.getFormDetailByIdWithoutAuth, data, thunkAPI);
    return formList;
  },
);

export const duplicateFormById = createAsyncThunk('duplicateFormById', async (data, thunkAPI) => {
  const duplicateFormRes = await smartTryCatch(FormService.duplicateFormById, data, thunkAPI);
  return duplicateFormRes;
});

export const deleteFormById = createAsyncThunk('deleteFormById', async (data, thunkAPI) => {
  const deleteFormRes = await smartTryCatch(FormService.deleteFormById, data, thunkAPI);
  return deleteFormRes;
});

export const bulkDeleteFormByIds = createAsyncThunk(
  'bulkDeleteFormByIds',
  async (data, thunkAPI) => {
    const deleteFormRes = await smartTryCatch(FormService.bulkDeleteFormByIds, data, thunkAPI);
    return deleteFormRes;
  },
);

export const updateFormById = createAsyncThunk('updateFormById', async (data, thunkAPI) => {
  const updateFormRes = await smartTryCatch(FormService.updateFormById, data, thunkAPI);
  return updateFormRes;
});

export const publishFormById = createAsyncThunk('publishFormById', async (data, thunkAPI) => {
  const publishFormRes = await smartTryCatch(FormService.publishFormById, data, thunkAPI);
  return publishFormRes;
});

export const validateForm = createAsyncThunk('validateForm', async (data, thunkAPI) => {
  const validateFormRes = await smartTryCatch(FormService.validateForm, data, thunkAPI);
  return validateFormRes;
});

export const getFormResponseList = createAsyncThunk(
  'getFormResponseList',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(FormService.getFormResponseList, data, thunkAPI);
    return res;
  },
);

export const createFormResponse = createAsyncThunk('createFormResponse', async (data, thunkAPI) => {
  const res = await smartTryCatch(FormService.createFormResponse, data, thunkAPI);
  return res;
});

export const updateFormResponse = createAsyncThunk('updateFormResponse', async (data, thunkAPI) => {
  const res = await smartTryCatch(FormService.updateFormResponse, data, thunkAPI);
  return res;
});

export const deleteFormResponse = createAsyncThunk('deleteFormResponse', async (data, thunkAPI) => {
  const res = await smartTryCatch(FormService.deleteFormResponse, data, thunkAPI);
  return res;
});

export const getFormResponseDetailById = createAsyncThunk(
  'getFormResponseDetailById',
  async (id, thunkAPI) => {
    const res = await smartTryCatch(FormService.getFormResponseDetailById, id, thunkAPI);
    return res;
  },
);

export const getFormResponseListById = createAsyncThunk(
  'getFormResponseListById',
  async (id, thunkAPI) => {
    const res = await smartTryCatch(FormService.getFormResponseListById, id, thunkAPI);
    return res;
  },
);

export const createFormResponseById = createAsyncThunk(
  'createFormResponseById',
  async (id, thunkAPI) => {
    const res = await smartTryCatch(FormService.createFormResponseById, id, thunkAPI);
    return res;
  },
);

export const addApprovers = createAsyncThunk('addApprovers', async (formId, thunkAPI) => {
  const res = await smartTryCatch(FormService.addApprovers, formId, thunkAPI);
  return res;
});

export const getApprovers = createAsyncThunk('getApprovers', async (formId, thunkAPI) => {
  const res = await smartTryCatch(FormService.getApprovers, formId, thunkAPI);
  return res;
});

export const getActivityLog = createAsyncThunk('getActivityLog', async (form, thunkAPI) => {
  const res = await smartTryCatch(FormService.getActivityLog, form, thunkAPI);
  return res;
});

export const approveRejectForm = createAsyncThunk('approveRejectForm', async (form, thunkAPI) => {
  const res = await smartTryCatch(FormService.approveRejectForm, form, thunkAPI);
  return res;
});

export const getFormSurveyActivities = createAsyncThunk(
  'getFormSurveyActivities',
  async (formId, thunkAPI) => {
    const res = await smartTryCatch(FormService.getFormSurveyActivities, formId, thunkAPI);
    return res;
  },
);

export const getSurveySummary = createAsyncThunk('getSurveySummary', async (formId, thunkAPI) => {
  const res = await smartTryCatch(FormService.getSurveySummary, formId, thunkAPI);
  return res;
});

export const deleteFormResponseById = createAsyncThunk(
  'deleteFormResponseById',
  async (formId, thunkAPI) => {
    const res = await smartTryCatch(FormService.deleteFormResponseById, formId, thunkAPI);
    return res;
  },
);

export const bulkDeleteFormResponseByIds = createAsyncThunk(
  'bulkDeleteFormResponseByIds',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(FormService.bulkDeleteFormResponseByIds, data, thunkAPI);
    return res;
  },
);

export const toggleAcceptingResponse = createAsyncThunk(
  'toggleAcceptingResponse',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(FormService.toggleAcceptingResponse, data, thunkAPI);
    return res;
  },
);

export const extendFormResponse = createAsyncThunk('extendFormResponse', async (data, thunkAPI) => {
  const res = await smartTryCatch(FormService.extendFormResponse, data, thunkAPI);
  return res;
});

export const closeFormResponse = createAsyncThunk('closeFormResponse', async (data, thunkAPI) => {
  const res = await smartTryCatch(FormService.closeFormResponse, data, thunkAPI);
  return res;
});

export const cancelFormResponse = createAsyncThunk('cancelFormResponse', async (data, thunkAPI) => {
  const res = await smartTryCatch(FormService.cancelFormResponse, data, thunkAPI);
  return res;
});

export const formSlice = createSlice({
  name: 'forms',
  initialState: initState,
  reducers: {
    setSearchListValue: (state, action) => {
      state.searchListValue = action.payload;
    },
    setFormCategories: (state, action) => {
      state.formCategories = action.payload;
    },
    setSelectedFormId: (state, action) => {
      state.selectedFormId = action.payload;
    },
    setSelectedFormResponseId: (state, action) => {
      state.selectedFormResponseId = action.payload;
    },
    setActiveIndex: (state, action) => {
      state.activeIndex = action.payload;
    },

    // list form pagination
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    setRowPerPage: (state, action) => {
      state.rowPerPage = action.payload;
    },

    // list form response pagination
    setCurrentPageResponse: (state, action) => {
      state.currentPageResponse = action.payload;
    },

    setRowPerPageResponse: (state, action) => {
      state.rowPerPageResponse = action.payload;
    },

    // list form response respondent pagination
    setRespondentCurrentPage: (state, action) => {
      state.responsedentCurrentPage = action.payload;
    },

    setRespondentTotalRowPerPage: (state, action) => {
      state.responsedentTotalRowPerPage = action.payload;
    },

    // filter
    setFilterCategory: (state, action) => {
      state.filterCategory = action.payload;
    },
    setFilterFormType: (state, action) => {
      state.filterFormType = action.payload;
    },
    setFilterStatus: (state, action) => {
      state.filterStatus = action.payload;
    },
    setFilterStartCreatedOnDate: (state, action) => {
      state.filterStartCreatedOnDate = action.payload;
    },
    setFilterEndCreatedOnDate: (state, action) => {
      state.filterEndCreatedOnDate = action.payload;
    },
    setFilterCreatedOnDateState: (state, action) => {
      state.filterCreatedOnDateState = action.payload;
    },

    setFilterStartPublishedOnDate: (state, action) => {
      state.filterStartPublishedOnDate = action.payload;
    },
    setFilterEndPublishedOnDate: (state, action) => {
      state.filterEndPublishedOnDate = action.payload;
    },
    setFilterPublishedOnDateState: (state, action) => {
      state.filterPublishedOnDateState = action.payload;
    },

    setFilterPublishedBy: (state, action) => {
      state.filterPublishedBy = action.payload;
    },

    resetBasicFilter: (state) => {
      state.filterCategory = '';
      state.filterFormType = '';
      state.filterStatus = '';
    },
    resetAdvancedFilter: (state) => {
      state.filterStartCreatedOnDate = '';
      state.filterEndCreatedOnDate = '';
      state.filterCreatedOnDateState = [
        {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
      ];
      state.filterStartPublishedOnDate = '';
      state.filterEndPublishedOnDate = '';
      state.filterPublishedOnDateState = [
        {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
      ];
      state.filterPublishedBy = [];
    },

    setFilterRequesters: (state, action) => {
      state.filterRequesterResponse = action.payload;
    },

    setFormRequestFilterStartSentOnDate: (state, action) => {
      state.filterStartSentOnDate = action.payload;
    },
    setFormRequestFilterEndSentOnDate: (state, action) => {
      state.filterEndSentOnDate = action.payload;
    },
    setFormRequestFilterSentOnDateState: (state, action) => {
      state.filterSentOnDateState = action.payload;
    },

    resetFormRequestAdvancedFilter: (state) => {
      state.filterStartSentOnDate = '';
      state.filterEndSentOnDate = '';
      state.filterSentOnDateState = [
        {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
      ];

      state.filterRequesterResponse = [];
    },

    // filter form responses
    setFilterApproverResponse: (state, action) => {
      state.filterApproverResponse = action.payload;
    },

    setFilterStatusResponse: (state, action) => {
      state.filterStatusResponse = action.payload;
    },

    resetBasicResponsesFilter: (state) => {
      state.filterApproverResponse = [];
      state.filterStatusResponse = '';
    },

    // reset
    reset: (state) => {
      state.activeIndex = initState.activeIndex;
      // create form
      state.isFormCreateLoading = initState.isFormCreateLoading;
      state.isFormCreateError = initState.isFormCreateError;
      state.isFormCreateSuccess = initState.isFormCreateSuccess;

      // get form
      state.isFormGetLoading = initState.isFormGetLoading;
      state.isFormGetError = initState.isFormGetError;
      state.isFormGetSuccess = initState.isFormGetSuccess;

      // update form
      state.isFormUpdateLoading = initState.isFormUpdateLoading;
      state.isFormUpdateError = initState.isFormUpdateError;
      state.isFormUpdateSuccess = initState.isFormUpdateSuccess;

      // delete form
      state.isFormDeleteLoading = initState.isFormDeleteLoading;
      state.isFormDeleteError = initState.isFormDeleteError;
      state.isFormDeleteSuccess = initState.isFormDeleteSuccess;

      // forms data
      state.forms = initState.forms;
      state.form = initState.form;
    },
  },
  extraReducers: (builder) => {
    builder
      // create form
      .addCase(createForm.pending, (state) => {
        state.isFormCreateLoading = true;
        state.isFormCreateSuccess = false;
        state.isFormCreateError = false;
      })
      .addCase(createForm.fulfilled, (state) => {
        state.isFormCreateLoading = false;
        state.isFormCreateSuccess = true;
        state.isFormCreateError = false;
      })
      .addCase(createForm.rejected, (state) => {
        state.isFormCreateLoading = false;
        state.isFormCreateSuccess = false;
        state.isFormCreateError = true;
      })

      // duplicate form
      .addCase(duplicateFormById.pending, (state) => {
        state.isFormDuplicateLoading = true;
        state.isFormDuplicateSuccess = false;
        state.isFormDuplicateError = false;
      })
      .addCase(duplicateFormById.fulfilled, (state) => {
        state.isFormDuplicateLoading = false;
        state.isFormDuplicateSuccess = true;
        state.isFormDuplicateError = false;
      })
      .addCase(duplicateFormById.rejected, (state) => {
        state.isFormDuplicateLoading = false;
        state.isFormDuplicateSuccess = false;
        state.isFormDuplicateError = true;
      })

      // get form category list
      .addCase(getFormCategoryList.pending, (state) => {
        state.isFormCategoryGetLoading = true;
        state.isFormCategoryGetSuccess = false;
        state.isFormCategoryGetError = false;
      })
      .addCase(getFormCategoryList.fulfilled, (state, action) => {
        state.isFormCategoryGetLoading = false;
        state.isFormCategoryGetSuccess = true;
        state.isFormCategoryGetError = false;
        state.formCategories = action.payload;
      })
      .addCase(getFormCategoryList.rejected, (state) => {
        state.isFormCategoryGetLoading = false;
        state.isFormCategoryGetSuccess = false;
        state.isFormCategoryGetError = true;
      })

      // get form total
      .addCase(getFormTotal.pending, (state) => {
        state.isFormTotalGetLoading = true;
        state.isFormTotalGetSuccess = false;
        state.isFormTotalGetError = false;
      })
      .addCase(getFormTotal.fulfilled, (state, action) => {
        state.isFormTotalGetLoading = false;
        state.isFormTotalGetSuccess = true;
        state.isFormTotalGetError = false;
        state.formTotal = action.payload;
      })
      .addCase(getFormTotal.rejected, (state) => {
        state.isFormTotalGetLoading = false;
        state.isFormTotalGetSuccess = false;
        state.isFormTotalGetError = true;
      })

      // get form list service
      .addCase(getFormList.pending, (state) => {
        state.isFormGetLoading = true;
        state.isFormGetSuccess = false;
        state.isFormGetError = false;
      })
      .addCase(getFormList.fulfilled, (state, action) => {
        state.isFormGetLoading = false;
        state.isFormGetSuccess = true;
        state.isFormGetError = false;
        state.forms = action.payload;
      })
      .addCase(getFormList.rejected, (state) => {
        state.isFormGetLoading = false;
        state.isFormGetSuccess = false;
        state.isFormGetError = true;
      })

      // get form detail
      .addCase(getFormDetailById.pending, (state) => {
        state.isFormDetailGetLoading = true;
        state.isFormDetailGetSuccess = false;
        state.isFormDetailGetError = false;
      })
      .addCase(getFormDetailById.fulfilled, (state, action) => {
        state.isFormDetailGetLoading = false;
        state.isFormDetailGetSuccess = true;
        state.isFormDetailGetError = false;
        state.form = action.payload;
      })
      .addCase(getFormDetailById.rejected, (state) => {
        state.isFormDetailGetLoading = false;
        state.isFormDetailGetSuccess = false;
        state.isFormDetailGetError = true;
      })

      // get form detail
      .addCase(getFormDetailByIdWithoutAuth.pending, (state) => {
        state.isFormDetailGetLoading = true;
        state.isFormDetailGetSuccess = false;
        state.isFormDetailGetError = false;
      })
      .addCase(getFormDetailByIdWithoutAuth.fulfilled, (state, action) => {
        state.isFormDetailGetLoading = false;
        state.isFormDetailGetSuccess = true;
        state.isFormDetailGetError = false;
        state.form = action.payload;
      })
      .addCase(getFormDetailByIdWithoutAuth.rejected, (state) => {
        state.isFormDetailGetLoading = false;
        state.isFormDetailGetSuccess = false;
        state.isFormDetailGetError = true;
      })

      // delete form
      .addCase(deleteFormById.pending, (state) => {
        state.isFormDeleteLoading = true;
        state.isFormDeleteSuccess = false;
        state.isFormDeleteError = false;
      })
      .addCase(deleteFormById.fulfilled, (state) => {
        state.isFormDeleteLoading = false;
        state.isFormDeleteSuccess = true;
        state.isFormDeleteError = false;
      })
      .addCase(deleteFormById.rejected, (state) => {
        state.isFormDeleteLoading = false;
        state.isFormDeleteSuccess = false;
        state.isFormDeleteError = true;
      })

      // publish form
      .addCase(publishFormById.pending, (state) => {
        state.isFormPublishLoading = true;
        state.isFormPublishSuccess = false;
        state.isFormPublishError = false;
      })
      .addCase(publishFormById.fulfilled, (state) => {
        state.isFormPublishLoading = false;
        state.isFormPublishSuccess = true;
        state.isFormPublishError = false;
      })
      .addCase(publishFormById.rejected, (state) => {
        state.isFormPublishLoading = false;
        state.isFormPublishSuccess = false;
        state.isFormPublishError = true;
      })

      // bulk delete form
      .addCase(bulkDeleteFormByIds.pending, (state) => {
        state.isFormBulkDeleteLoading = true;
        state.isFormBulkDeleteSuccess = false;
        state.isFormBulkDeleteError = false;
      })
      .addCase(bulkDeleteFormByIds.fulfilled, (state) => {
        state.isFormBulkDeleteLoading = false;
        state.isFormBulkDeleteSuccess = true;
        state.isFormBulkDeleteError = false;
      })
      .addCase(bulkDeleteFormByIds.rejected, (state) => {
        state.isFormBulkDeleteLoading = false;
        state.isFormBulkDeleteSuccess = false;
        state.isFormBulkDeleteError = true;
      })

      // validate form
      .addCase(validateForm.pending, (state) => {
        state.isFormValidateLoading = true;
        state.isFormValidateSuccess = false;
        state.isFormValidateError = false;
      })
      .addCase(validateForm.fulfilled, (state) => {
        state.isFormValidateLoading = false;
        state.isFormValidateSuccess = true;
        state.isFormValidateError = false;
      })
      .addCase(validateForm.rejected, (state) => {
        state.isFormValidateLoading = false;
        state.isFormValidateSuccess = false;
        state.isFormValidateError = true;
      })

      // get form response list
      .addCase(getFormResponseListById.pending, (state) => {
        state.isFormResponseListLoading = true;
        state.isFormResponseListSuccess = false;
        state.isFormResponseListError = false;
      })
      .addCase(getFormResponseListById.fulfilled, (state, action) => {
        state.isFormResponseListLoading = false;
        state.isFormResponseListSuccess = true;
        state.isFormResponseListError = false;
        state.formResponseList = action.payload;
      })
      .addCase(getFormResponseListById.rejected, (state) => {
        state.isFormResponseListLoading = false;
        state.isFormResponseListSuccess = false;
        state.isFormResponseListError = true;
      })

      // create form response
      .addCase(createFormResponse.pending, (state) => {
        state.isFormResponseCreateLoading = true;
        state.isFormResponseCreateSuccess = false;
        state.isFormResponseCreateError = false;
      })
      .addCase(createFormResponse.fulfilled, (state) => {
        state.isFormResponseCreateLoading = false;
        state.isFormResponseCreateSuccess = true;
        state.isFormResponseCreateError = false;
      })
      .addCase(createFormResponse.rejected, (state) => {
        state.isFormResponseCreateLoading = false;
        state.isFormResponseCreateSuccess = false;
        state.isFormResponseCreateError = true;
      })

      // update form response
      .addCase(updateFormResponse.pending, (state) => {
        state.isFormResponseUpdateLoading = true;
        state.isFormResponseUpdateSuccess = false;
        state.isFormResponseUpdateError = false;
      })
      .addCase(updateFormResponse.fulfilled, (state) => {
        state.isFormResponseUpdateLoading = false;
        state.isFormResponseUpdateSuccess = true;
        state.isFormResponseUpdateError = false;
      })
      .addCase(updateFormResponse.rejected, (state) => {
        state.isFormResponseUpdateLoading = false;
        state.isFormResponseUpdateSuccess = false;
        state.isFormResponseUpdateError = true;
      })

      // delete form response
      .addCase(deleteFormResponse.pending, (state) => {
        state.isFormResponseDeleteLoading = true;
        state.isFormResponseDeleteSuccess = false;
        state.isFormResponseDeleteError = false;
      })
      .addCase(deleteFormResponse.fulfilled, (state) => {
        state.isFormResponseDeleteLoading = false;
        state.isFormResponseDeleteSuccess = true;
        state.isFormResponseDeleteError = false;
      })
      .addCase(deleteFormResponse.rejected, (state) => {
        state.isFormResponseDeleteLoading = false;
        state.isFormResponseDeleteSuccess = false;
        state.isFormResponseDeleteError = true;
      })

      // get form response detail
      .addCase(getFormResponseDetailById.pending, (state) => {
        state.isFormResponseDetailGetLoading = true;
        state.isFormResponseDetailGetSuccess = false;
        state.isFormResponseDetailGetError = false;
      })
      .addCase(getFormResponseDetailById.fulfilled, (state, action) => {
        state.isFormResponseDetailGetLoading = false;
        state.isFormResponseDetailGetSuccess = true;
        state.isFormResponseDetailGetError = false;
        state.formResponseDetail = action.payload;
      })
      .addCase(getFormResponseDetailById.rejected, (state) => {
        state.isFormResponseDetailGetLoading = false;
        state.isFormResponseDetailGetSuccess = false;
        state.isFormResponseDetailGetError = true;
      })

      // create form response by id
      .addCase(createFormResponseById.pending, (state) => {
        state.isFormResponseByIdCreateLoading = true;
        state.isFormResponseByIdCreateSuccess = false;
        state.isFormResponseByIdCreateError = false;
      })
      .addCase(createFormResponseById.fulfilled, (state) => {
        state.isFormResponseByIdCreateLoading = false;
        state.isFormResponseByIdCreateSuccess = true;
        state.isFormResponseByIdCreateError = false;
      })
      .addCase(createFormResponseById.rejected, (state) => {
        state.isFormResponseByIdCreateLoading = false;
        state.isFormResponseByIdCreateSuccess = false;
        state.isFormResponseByIdCreateError = true;
      })

      // add approvers
      .addCase(addApprovers.pending, (state) => {
        state.isApproversAddLoading = true;
        state.isApproversAddSuccess = false;
        state.isApproversAddError = false;
      })
      .addCase(addApprovers.fulfilled, (state) => {
        state.isApproversAddLoading = false;
        state.isApproversAddSuccess = true;
        state.isApproversAddError = false;
      })
      .addCase(addApprovers.rejected, (state) => {
        state.isApproversAddLoading = false;
        state.isApproversAddSuccess = false;
        state.isApproversAddError = true;
      })

      // get approvers
      .addCase(getApprovers.pending, (state) => {
        state.isApproversGetLoading = true;
        state.isApproversGetSuccess = false;
        state.isApproversGetError = false;
      })
      .addCase(getApprovers.fulfilled, (state, action) => {
        state.isApproversGetLoading = false;
        state.isApproversGetSuccess = true;
        state.isApproversGetError = false;
        state.approvers = action.payload;
      })
      .addCase(getApprovers.rejected, (state) => {
        state.isApproversGetLoading = false;
        state.isApproversGetSuccess = false;
        state.isApproversGetError = true;
      })

      // get activity log by form id
      .addCase(getActivityLog.pending, (state) => {
        state.isActivityLogByFormResponseIdGetLoading = true;
        state.isActivityLogByFormResponseIdGetSuccess = false;
        state.isActivityLogByFormResponseIdGetError = false;
      })
      .addCase(getActivityLog.fulfilled, (state, action) => {
        state.isActivityLogByFormResponseIdGetLoading = false;
        state.isActivityLogByFormResponseIdGetSuccess = true;
        state.isActivityLogByFormResponseIdGetError = false;
        state.formResponseActivityLogs = action.payload;
      })
      .addCase(getActivityLog.rejected, (state) => {
        state.isActivityLogByFormResponseIdGetLoading = false;
        state.isActivityLogByFormResponseIdGetSuccess = false;
        state.isActivityLogByFormResponseIdGetError = true;
      })

      // approve/reject form
      .addCase(approveRejectForm.pending, (state) => {
        state.isFormApproveRejectLoading = true;
        state.isFormApproveRejectSuccess = false;
        state.isFormApproveRejectError = false;
      })
      .addCase(approveRejectForm.fulfilled, (state) => {
        state.isFormApproveRejectLoading = false;
        state.isFormApproveRejectSuccess = true;
        state.isFormApproveRejectError = false;
      })
      .addCase(approveRejectForm.rejected, (state) => {
        state.isFormApproveRejectLoading = false;
        state.isFormApproveRejectSuccess = false;
        state.isFormApproveRejectError = true;
      })

      // get form survey activities
      .addCase(getFormSurveyActivities.pending, (state) => {
        state.isFormSurveyActivitiesGetLoading = true;
        state.isFormSurveyActivitiesGetSuccess = false;
        state.isFormSurveyActivitiesGetError = false;
      })
      .addCase(getFormSurveyActivities.fulfilled, (state, action) => {
        state.isFormSurveyActivitiesGetLoading = false;
        state.isFormSurveyActivitiesGetSuccess = true;
        state.isFormSurveyActivitiesGetError = false;
        state.formSurveyActivities = action.payload;
      })
      .addCase(getFormSurveyActivities.rejected, (state) => {
        state.isFormSurveyActivitiesGetLoading = false;
        state.isFormSurveyActivitiesGetSuccess = false;
        state.isFormSurveyActivitiesGetError = true;
      })

      // get survey summary
      .addCase(getSurveySummary.pending, (state) => {
        state.isSurveySummaryGetLoading = true;
        state.isSurveySummaryGetSuccess = false;
        state.isSurveySummaryGetError = false;
      })
      .addCase(getSurveySummary.fulfilled, (state, action) => {
        state.isSurveySummaryGetLoading = false;
        state.isSurveySummaryGetSuccess = true;
        state.isSurveySummaryGetError = false;
        state.surveySummary = action.payload;
      })
      .addCase(getSurveySummary.rejected, (state) => {
        state.isSurveySummaryGetLoading = false;
        state.isSurveySummaryGetSuccess = false;
        state.isSurveySummaryGetError = true;
      })

      // delete form response by id
      .addCase(deleteFormResponseById.pending, (state) => {
        state.isFormResponseDeleteByIdLoading = true;
        state.isFormResponseDeleteByIdSuccess = false;
        state.isFormResponseDeleteByIdError = false;
      })
      .addCase(deleteFormResponseById.fulfilled, (state) => {
        state.isFormResponseDeleteByIdLoading = false;
        state.isFormResponseDeleteByIdSuccess = true;
        state.isFormResponseDeleteByIdError = false;
      })
      .addCase(deleteFormResponseById.rejected, (state) => {
        state.isFormResponseDeleteByIdLoading = false;
        state.isFormResponseDeleteByIdSuccess = false;
        state.isFormResponseDeleteByIdError = true;
      })

      // bulk delete form response by ids
      .addCase(bulkDeleteFormResponseByIds.pending, (state) => {
        state.isFormResponseBulkDeleteByIdsLoading = true;
        state.isFormResponseBulkDeleteByIdsSuccess = false;
        state.isFormResponseBulkDeleteByIdsError = false;
      })
      .addCase(bulkDeleteFormResponseByIds.fulfilled, (state) => {
        state.isFormResponseBulkDeleteByIdsLoading = false;
        state.isFormResponseBulkDeleteByIdsSuccess = true;
        state.isFormResponseBulkDeleteByIdsError = false;
      })
      .addCase(bulkDeleteFormResponseByIds.rejected, (state) => {
        state.isFormResponseBulkDeleteByIdsLoading = false;
        state.isFormResponseBulkDeleteByIdsSuccess = false;
        state.isFormResponseBulkDeleteByIdsError = true;
      })

      // toggle accepting response
      .addCase(toggleAcceptingResponse.pending, (state) => {
        state.isFormToggleResponseLoading = true;
        state.isFormToggleResponseSuccess = false;
        state.isFormToggleResponseError = false;
      })
      .addCase(toggleAcceptingResponse.fulfilled, (state) => {
        state.isFormToggleResponseLoading = false;
        state.isFormToggleResponseSuccess = true;
        state.isFormToggleResponseError = false;
      })
      .addCase(toggleAcceptingResponse.rejected, (state) => {
        state.isFormToggleResponseLoading = false;
        state.isFormToggleResponseSuccess = false;
        state.isFormToggleResponseError = true;
      });
  },
});

export const {
  resetAdvancedFilter,
  resetBasicFilter,
  resetFormRequestAdvancedFilter,
  setFilterCreatedOnDateState,
  setFilterStartCreatedOnDate,
  setFilterEndCreatedOnDate,
  setFilterPublishedBy,
  setFilterStartPublishedOnDate,
  setFilterEndPublishedOnDate,
  setFilterPublishedOnDateState,
  setActiveIndex,
  reset,
  setSelectedFormId,
  setFilterCategory,
  setFilterFormType,
  setFilterStatus,
  setCurrentPage,
  setRowPerPage,
  setFormCategories,
  setSearchListValue,
  resetBasicResponsesFilter,
  setFilterApproverResponse,
  setFilterStatusResponse,
  setSelectedFormResponseId,
  setCurrentPageResponse,
  setRowPerPageResponse,
  setFilterRequesters,
  setFormRequestFilterEndSentOnDate,
  setFormRequestFilterSentOnDateState,
  setFormRequestFilterStartSentOnDate,
  setRespondentCurrentPage,
  setRespondentTotalRowPerPage,
} = formSlice.actions;

export default formSlice.reducer;
