/* eslint-disable no-else-return */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Pagination from '../../../../components/pagination/pagination';
import { listSurveySummaryColumns } from '../../config/tables/columns';

import '../../../../components/WordOdersAnalytics/AnalyticDashboard.scss';
import Table from '../../../../components/crudTable/crudTable';

import {
  getSurveySummary,
  setRespondentCurrentPage,
  setRespondentTotalRowPerPage,
} from '../../../../redux/features/forms/formSlice';
import FormSurveyCheckboxesSummary from '../FormSurvey/FormSurveyCheckboxesSummary';
import FormSurveyDateSummary from '../FormSurvey/FormSurveyDateSummary';
import FormSurveyLongTextSummary from '../FormSurvey/FormSurveyLongTextSummary';
import FormSurveyMultipleSummary from '../FormSurvey/FormSurveyMultipleSummary';
import FormSurveyRatingSummary from '../FormSurvey/FormSurveyRatingSummary';
import FormSurveyTimeSummary from '../FormSurvey/FormSurveyTimeSummary';
import { FieldType } from '../../config/forms/constant';

export default function FormSurveyDetailResponseSummaryTab() {
  const { surveySummary, form, responsedentCurrentPage, responsedentTotalRowPerPage } = useSelector(
    (state) => state.form,
  );
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSurveySummary(id));
  }, [id]);

  const respondentData = useMemo(() => {
    return (
      surveySummary?.response_creators?.map((item, index) => {
        return {
          no: index + 1,
          id: item.form_response_id,
          email: item.email,
        };
      }) || []
    );
  }, [surveySummary]);

  const paginatedRespondentData = useMemo(() => {
    const startIndex = (responsedentCurrentPage - 1) * responsedentTotalRowPerPage;
    const endIndex = startIndex + responsedentTotalRowPerPage;
    return respondentData?.slice(startIndex, endIndex);
  }, [respondentData, responsedentCurrentPage, responsedentTotalRowPerPage]);

  return (
    <div>
      <div className="border border-neutral-color-divider p-6 rounded-lg">
        <h2 className="text-neutral-color-darker font-semibold text-lg">Respondent</h2>
        <p className="text-neutral-color-main text-sm">
          {surveySummary?.response_creators?.length || 0} responses
        </p>

        <Table columns={listSurveySummaryColumns()} data={paginatedRespondentData || []} />

        <div className="h-[1px] w-full bg-neutral-color-divider mb-4" />
        <Pagination
          className="mb-0 pb-0"
          totalRows={surveySummary?.response_creators?.length || 0}
          rowPerPage={responsedentTotalRowPerPage}
          pageChangeHandler={setRespondentCurrentPage}
          changeRowsPerPage={setRespondentTotalRowPerPage}
          currentPage={responsedentCurrentPage}
        />
      </div>

      <div className="my-4 bg-neutral-color-divider h-[1px]" />

      <div className="border border-neutral-color-divider p-6 rounded-lg">
        <div className="border-b border-neutral-color-divider pb-2">
          <h2 className="text-neutral-color-darker font-semibold text-2xl">{form.title || ''}</h2>
          <p className="text-neutral-color-main text-sm">{form?.description || ''}</p>
        </div>

        <div className="mt-3">
          {surveySummary?.responses?.map((response) => {
            return (
              <>
                <h2 className="text-lg text-neutral-color-darker font-semibold mb-3">
                  {response.section_title}
                </h2>

                <div>
                  {response.fields.map((field) => {
                    if (
                      field.field_type === FieldType.ShortText ||
                      field.field_type === FieldType.LongText
                    ) {
                      return (
                        <>
                          <FormSurveyLongTextSummary field={field} />

                          <div className="h-[1px] my-5 w-full bg-neutral-color-divider" />
                        </>
                      );
                    } else if (
                      field.field_type === FieldType.MultipleChoices ||
                      field.field_type === FieldType.Dropdown
                    ) {
                      return (
                        <>
                          <FormSurveyMultipleSummary field={field} />

                          <div className="h-[1px] my-5 w-full bg-neutral-color-divider" />
                        </>
                      );
                    } else if (field.field_type === FieldType.Checkboxes) {
                      return (
                        <>
                          <FormSurveyCheckboxesSummary field={field} />

                          <div className="h-[1px] my-5 w-full bg-neutral-color-divider" />
                        </>
                      );
                    } else if (field.field_type === FieldType.Rating) {
                      return (
                        <>
                          <FormSurveyRatingSummary field={field} />

                          <div className="h-[1px] my-5 w-full bg-neutral-color-divider" />
                        </>
                      );
                    } else if (field.field_type === FieldType.Date) {
                      return (
                        <>
                          <FormSurveyDateSummary field={field} />

                          <div className="h-[1px] my-5 w-full bg-neutral-color-divider" />
                        </>
                      );
                    } else if (field.field_type === FieldType.Time) {
                      return (
                        <>
                          <FormSurveyTimeSummary field={field} />

                          <div className="h-[1px] my-5 w-full bg-neutral-color-divider" />
                        </>
                      );
                    } else {
                      return (
                        <>
                          <FormSurveyLongTextSummary field={field} />

                          <div className="h-[1px] my-5 w-full bg-neutral-color-divider" />
                        </>
                      );
                    }
                  })}
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}
