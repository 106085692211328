import React from 'react';
import FoxLogo from '../../assets/img/fox-logo-grey.png';

export default function NotFound() {
  return (
    <div className="flex justify-center items-center flex-col h-svh">
      <img src={FoxLogo} alt="logo" />
      <p className="mb-0 text-[21px] text-[#727478] text-center">
        Sorry, that page cannot be found.
      </p>
    </div>
  );
}
