/* eslint-disable react/no-unescaped-entities */

// "node-sass": "^9.0.0",

import './styles/style.css';
import './styles/globals.css';
import './styles/tailwind.css';
import './index.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AssetHistory from './components/assetsTable/assetsHistory';
import InventoryHistory from './components/inventory/inventoryHistory';
import Layout from './components/layout/layout';
import LocationHistory from './components/location/LocationHistory';
import ScrollToTop from './components/scrollToTop';
import {
  AnalyticsReport,
  AssetsPage,
  Checklists,
  CompanyProfile,
  Dashboard,
  EditCategory,
  ForgetPassword,
  Form,
  FormDetail,
  Inventories,
  Locations,
  Login,
  Meter,
  Profile,
  ResetPasswodByEmail,
  ResetPassword,
  Teams,
  UserResetNewPassword,
  ViewMaintenance,
  WorkOrders,
} from './pages';
import AssetMapping from './pages/assetMapping/assetMapping';
import AddAsset from './pages/assetsPage/addAsset/addAsset';
import AssetQRCode from './pages/assetsPage/assetQrCode';
import FetchAsset from './pages/assetsPage/fetchAsset/fetchAsset';
import LocationQRCode from './pages/locations/locationQrCode';
import TicketView from './pages/workOrder/ticketView/ticketView';
import { logout } from './redux/features/auth/authSlice';
// import { changeFavicon } from './utils/changeFavicon';
import QrCodeResult from './pages/inventories/QrCodeResult';
import Settings from './pages/settings/settings';
import { FRONT_URL } from './utils/axios';
import viewEvent from './pages/viewEvent/viewEvent';
import epq from './pages/epq/epq';
import { getUserProfile } from './redux/features/user/userSlice';
import bill from './pages/epq/Pages/bill';
import { getCompanyProfile } from './redux/features/compay/companySlice';
import billSummary from './pages/epq/Pages/billSummary';
// import EpqHistory from './pages/epq/Pages/epqHistory';
import FormResponseDetail from './pages/form/formResponseDetail';
import FormPreview from './pages/form/formPreview';
import FormSubmitQR from './pages/form/formSubmitQR';
import Loader from './components/loader/loader';
import NotFound from './components/NotFound/NotFound';
import FormSubmitFormDetail from './pages/form/formSubmitFormDetail';

/* eslint-disable no-nested-ternary */
import Toast from './components/toast/toast';

// eslint-disable-next-line react/prop-types
function PrivateRoute({ component: Component, path, exact }) {
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        localStorage.getItem('userToken') ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const getToken = () => {
      window.addEventListener('message', (e) => {
        if (e.origin !== FRONT_URL) {
          return;
        }
        const data = JSON.parse(e.data);
        localStorage.setItem('userToken', JSON.stringify(data));
        // localStorage.setItem("refreshToken" , JSON.stringify(data.refreshToken))
      });
    };
    getToken();
  }, []);

  const checkTokenExpiration = () => {
    const storedToken = localStorage.getItem('userToken');
    if (storedToken) {
      const { expiration } = JSON.parse(storedToken);
      const currentTime = new Date().getTime();
      if (currentTime > expiration) {
        // Token has expired, log out the user
        localStorage.removeItem('userToken');
        // Redirect to the login page or show a logged-out state
        dispatch(logout());
        window.location.href = FRONT_URL;
        // history.push('/login');
      }
    }
  };

  useEffect(() => {
    // Call checkTokenExpiration every sec (1 * 1000 milliseconds)
    const tokenExpirationInterval = setInterval(checkTokenExpiration, 1 * 1000);
    // Clean up the interval on component unmount
    return () => clearInterval(tokenExpirationInterval);
  }, []);

  const { profile, isLoading } = useSelector((state) => state.company);
  const { isLoadingUser = isLoading } = useSelector((state) => state.user);

  const token = localStorage.getItem('userToken');
  const PSD = profile?.name === 'TNB PROPERTY SERVICES DEPARTMENT';

  // Fetch user and company profile once on mount
  useEffect(() => {
    if (token && !profile && !isLoading) {
      dispatch(getUserProfile());
      dispatch(getCompanyProfile());
    }
  }, [dispatch, token, profile, isLoading]);

  // Show loader while loading
  if (isLoading || isLoadingUser || (!profile && token)) {
    return <Loader />;
  }

  return (
    <div className="App">
      <main>
        <ScrollToTop />
        <Switch>
          {/* Public Routes */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgetPassword} />
          <Route exact path="/confirm-password" component={ResetPasswodByEmail} />
          <Route exact path="/user-new-password/:uidb/:token" component={UserResetNewPassword} />

          {/* Private Routes */}
          <PrivateRoute exact path="/change-password" component={ResetPassword} />
          <PrivateRoute exact path="/company-profile/" component={CompanyProfile} />
          <PrivateRoute exact path="/profile" component={Profile} />

          {/* Conditional Routes Based on PSD */}
          {!PSD && (
            <PrivateRoute
              exact
              path="/forms/detail/:id/responses/:responseId"
              component={FormResponseDetail}
            />
          )}
          {!PSD && <PrivateRoute exact path="/forms/detail/:id" component={FormDetail} />}
          {!PSD && <Route exact path="/forms/preview/:id" component={FormPreview} />}
          {!PSD && <PrivateRoute exact path="/forms" component={Form} />}
          {!PSD && (
            <Route exact path="/forms/submit-form/:id/detail" component={FormSubmitFormDetail} />
          )}

          {!PSD && <Route exact path="/forms/submit-form/:id/qr-code" component={FormSubmitQR} />}

          {!PSD && <PrivateRoute exact path="/asset-service" component={AssetQRCode} />}
          {!PSD && <PrivateRoute exact path="/site-service" component={LocationQRCode} />}
          {!PSD && <PrivateRoute exact path="/ticket-view" component={TicketView} />}
          {!PSD && <PrivateRoute exact path="/inventory-service" component={QrCodeResult} />}
          {!PSD && <PrivateRoute exact path="/event" component={viewEvent} />}
          {!PSD && <PrivateRoute exact path="/" component={AnalyticsReport} />}
          {!PSD && <PrivateRoute exact path="/planner" component={Dashboard} />}
          {!PSD && <PrivateRoute exact path="/maintenance" component={ViewMaintenance} />}
          {!PSD && <PrivateRoute exact path="/workOrders" component={WorkOrders} />}
          {!PSD && <PrivateRoute exact path="/meter" component={Meter} />}
          {!PSD && <PrivateRoute exact path="/inventories" component={Inventories} />}
          {!PSD && <PrivateRoute exact path="/locations" component={Locations} />}
          {!PSD && <PrivateRoute exact path="/assets" component={AssetsPage} />}
          {!PSD && <PrivateRoute exact path="/addAsset" component={AddAsset} />}
          {!PSD && <PrivateRoute exact path="/assets/:id" component={FetchAsset} />}
          {!PSD && (
            <PrivateRoute exact path="/sub-location-history/:id" component={LocationHistory} />
          )}
          {!PSD && <PrivateRoute exact path="/asset-history/:id" component={AssetHistory} />}
          {!PSD && (
            <PrivateRoute exact path="/inventory-history/:id" component={InventoryHistory} />
          )}
          {!PSD && <PrivateRoute exact path="/assetMapping" component={AssetMapping} />}
          {!PSD && <PrivateRoute exact path="/teams" component={Teams} />}
          {!PSD && <PrivateRoute exact path="/checklists" component={Checklists} />}
          {!PSD && <PrivateRoute exact path="/editCategory" component={EditCategory} />}
          {!PSD && <PrivateRoute exact path="/settings" component={Settings} />}

          {/* PSD Conditional Routes */}
          {PSD && <PrivateRoute exact path="/ebq" component={epq} />}
          {PSD && <PrivateRoute exact path="/ebq/bill" component={bill} />}
          {PSD && <PrivateRoute exact path="/ebq/bill/:id" component={bill} />}
          {PSD && <PrivateRoute exact path="/ebq/bill/preview/:id" component={bill} />}
          {PSD && <PrivateRoute exact path="/ebq/bill-summary" component={billSummary} />}
          {PSD && <PrivateRoute exact path="/ebq/bill-summary/:id" component={billSummary} />}
          {PSD && (
            <PrivateRoute exact path="/ebq/bill-summary/preview/:id" component={billSummary} />
          )}

          {/* Catch-All Route */}
          <Route exact path="*" component={NotFound} />

          {/* <Route exact path="*">
            <Redirect to={PSD ? '/ebq' : '/'} />
          </Route> */}
        </Switch>
      </main>
      <ToastContainer draggable={false} autoClose={2000} limit={1} />
      <Toast />
    </div>
  );
}

export default App;
