import React from 'react';
import { useSelector } from 'react-redux';
import foxLogo from '../../../../assets/img/FOX-Logo3.png';

export default function FormPreviewHeader() {
  const { form: formDetail } = useSelector((state) => state.form);

  return (
    <div className="bg-white rounded-2xl p-6 mx-auto">
      <div className="border-b border-neutral-color-divider flex justify-between flex-wrap items-center gap-2 pb-2 md:pb-0">
        <img src={foxLogo} className="h-[50px]" alt="fox logo" />

        <p className="text-neutral-color-darker font-semibold text-sm mb-0">
          Document Number : {formDetail?.document_reference || formDetail?.form_number || ''}
        </p>
      </div>

      <div className="mt-2">
        <h2 className="text-[32px] mb-0 font-semibold text-neutral-color-darker">
          {formDetail?.title}
        </h2>
        <p className="text-sm text-neutral-color-main">{formDetail?.description}</p>
      </div>
    </div>
  );
}
