/* eslint-disable no-else-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { format } from 'date-fns';

import foxLogo from '../../../assets/img/FOX-Logo3.png';
import FilledStarIcon from '../../../assets/img/Icon.png';
import { FieldType } from '../config/forms/constant';
import OutlineStarIcon from '../../../assets/img/OutlineStarIcon.png';

const styles = StyleSheet.create({
  page: {
    padding: 40,
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#E1E1E1',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#E1E1E1',
    paddingVertical: 16,
    paddingHorizontal: 8,
  },
  tableData: {
    width: '75%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#E1E1E1',
    paddingVertical: 16,
    paddingHorizontal: 8,
  },
  tableColInfo: {
    width: '50%',
  },
  tableDataInfo: {
    width: '50%',
  },
  tableCell: {
    fontSize: 12,
    color: '#36383C',
    textAlign: 'left',
  },
});

export default function SurveyResponsePdf({
  permitId,
  companyName,
  formName,
  sections,
  email,
  qrCode,
}) {
  const renderValue = (value, type) => {
    if (
      type === FieldType.ShortText ||
      type === FieldType.LongText ||
      type === FieldType.Checkboxes ||
      type === FieldType.Dropdown ||
      type === FieldType.MultipleChoices
    ) {
      return Array.isArray(value) ? value.join(', ') : value;
    } else if (type === FieldType.Date) {
      return value ? format(new Date(value), 'MMM d, yyyy') : '-';
    } else if (type === FieldType.Time) {
      const date = new Date();
      const time = value.split(':');
      date.setHours(time[0], time[1], 0);

      // Format the date to include AM/PM
      const formattedTime = format(date, 'hh:mm a');

      return formattedTime;
    }

    return value;
  };

  return (
    <Document>
      <Page size={{ width: 1093, height: 982 }} style={styles.page}>
        <View style={styles.section}>
          <Image src={foxLogo} style={{ width: 120 }} />
          <View style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <Text
              style={{
                fontWeight: 600,
                fontSize: 32,
                color: '#36383C',
                textAlign: 'center',
              }}
            >
              {companyName}
            </Text>

            <Text
              style={{
                fontWeight: 400,
                fontSize: 14,
                color: '#727478',
                textAlign: 'center',
                marginTop: 8,
              }}
            >
              Permit ID : {permitId}
            </Text>
          </View>
          <Image src={qrCode} style={{ width: 100, height: 100 }} />
        </View>

        <View style={{ ...styles.table, marginTop: 30 }}>
          {/* Header Row */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Form Name</Text>
            </View>
            <View style={styles.tableData}>
              <Text
                style={{
                  ...styles.tableCell,
                  fontWeight: 600,
                }}
              >
                {formName}
              </Text>
            </View>
          </View>

          {/* Data Row */}
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Email</Text>
            </View>
            <View style={styles.tableData}>
              <Text style={styles.tableCell}>{email}</Text>
            </View>
          </View>

          {/* More rows can be added similarly */}
        </View>

        <View style={{ ...styles.table, marginTop: 20 }}>
          {sections?.map((sectionItem) => {
            if (sectionItem.field_type === FieldType.Table) {
              const columns = Object.keys(sectionItem.value[0]);
              return (
                <>
                  <View style={styles.tableRow}>
                    <View
                      style={{
                        backgroundColor: '#FFFFFF',
                        width: '100%',
                        padding: '12px 8px',
                      }}
                    >
                      <Text
                        style={{
                          fontSize: '14px',
                          fontWeight: 400,
                        }}
                      >
                        {sectionItem.field_label}
                      </Text>
                    </View>
                  </View>

                  <View>
                    <View
                      style={[
                        styles.tableRow,
                        {
                          backgroundColor: '#72747814',
                        },
                      ]}
                    >
                      {columns?.map((column, columnIdx) => {
                        return (
                          <View key={columnIdx} style={[styles.tableCol, styles.tableColInfo]}>
                            <Text style={styles.tableCell}>{column}</Text>
                          </View>
                        );
                      })}
                    </View>

                    {sectionItem.value.map((row) => {
                      return (
                        <View style={styles.tableRow}>
                          {columns.map((column, columnIdx) => {
                            return (
                              <View key={columnIdx} style={[styles.tableCol, styles.tableColInfo]}>
                                <Text style={styles.tableCell}>{row[column]}</Text>
                              </View>
                            );
                          })}
                        </View>
                      );
                    })}
                  </View>
                </>
              );
            }

            return sectionItem.field_responses?.map((field, idx) => {
              return (
                <View style={styles.tableRow} key={idx}>
                  <View style={[styles.tableCol, styles.tableColInfo]}>
                    <Text style={styles.tableCell}>{field.field_label}</Text>
                  </View>
                  <View style={[styles.tableData, styles.tableDataInfo]}>
                    {(() => {
                      if (field.field_type === FieldType.Rating) {
                        return (
                          <View
                            style={{
                              flexDirection: 'row',
                              gap: 14,
                            }}
                          >
                            {' '}
                            {Array.from({ length: 5 }, (_, i) => i + 1).map((value, ratingIdx) => {
                              return (
                                <View
                                  key={ratingIdx}
                                  style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                                >
                                  <Image
                                    src={ratingIdx < 3 ? FilledStarIcon : OutlineStarIcon}
                                    style={{ width: 12, height: 12 }}
                                  />
                                  <Text style={{ fontSize: 10, color: '#36383C' }}>
                                    {ratingIdx + 1}
                                  </Text>
                                </View>
                              );
                            })}
                          </View>
                        );
                      } else if (field.FieldType === FieldType.Table) {
                        return (
                          <View>
                            <View
                              style={[
                                styles.tableRow,
                                {
                                  backgroundColor: '#72747814',
                                },
                              ]}
                            >
                              <View
                                style={[
                                  styles.tableCol,
                                  styles.tableColInfo,
                                  {
                                    width: '15%',
                                  },
                                ]}
                              >
                                <Text style={styles.tableCell}>ID</Text>
                              </View>

                              <View
                                style={[
                                  styles.tableCol,
                                  styles.tableColInfo,
                                  {
                                    width: '65%',
                                  },
                                ]}
                              >
                                <Text style={styles.tableCell}>Name</Text>
                              </View>

                              <View
                                style={[
                                  styles.tableCol,
                                  styles.tableColInfo,
                                  {
                                    width: '15%',
                                  },
                                ]}
                              >
                                <Text style={styles.tableCell}>IC/Passport</Text>
                              </View>

                              <View
                                style={[
                                  styles.tableCol,
                                  styles.tableColInfo,
                                  {
                                    width: '15%',
                                  },
                                ]}
                              >
                                <Text style={styles.tableCell}>Phone Number</Text>
                              </View>
                            </View>

                            <View style={[styles.tableRow]}>
                              <View
                                style={[
                                  styles.tableCol,
                                  styles.tableColInfo,
                                  {
                                    width: '15%',
                                  },
                                ]}
                              >
                                <Text style={styles.tableCell}>A48913</Text>
                              </View>

                              <View
                                style={[
                                  styles.tableCol,
                                  styles.tableColInfo,
                                  {
                                    width: '65%',
                                  },
                                ]}
                              >
                                <Text style={styles.tableCell}>ALI BIN ABU</Text>
                              </View>

                              <View
                                style={[
                                  styles.tableCol,
                                  styles.tableColInfo,
                                  {
                                    width: '15%',
                                  },
                                ]}
                              >
                                <Text style={styles.tableCell}>87362671819</Text>
                              </View>

                              <View
                                style={[
                                  styles.tableCol,
                                  styles.tableColInfo,
                                  {
                                    width: '15%',
                                  },
                                ]}
                              >
                                <Text style={styles.tableCell}>01938261642</Text>
                              </View>
                            </View>
                          </View>
                        );
                      } else {
                        return (
                          <View>
                            <Text
                              style={{
                                ...styles.tableCell,
                                fontWeight: 600,
                              }}
                            >
                              {renderValue(field.value, field.field_type)}
                            </Text>
                          </View>
                        );
                      }
                    })()}
                  </View>
                </View>
              );
            });
          })}
        </View>
      </Page>
    </Document>
  );
}
