import React from 'react';

export default function StarIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeWidth={1.7}
        d="M7.68 1.45a.358.358 0 0 1 .642 0l1.91 3.87a.358.358 0 0 0 .27.196l4.27.62c.294.043.412.404.2.611L11.88 9.76a.358.358 0 0 0-.104.317l.73 4.254a.358.358 0 0 1-.52.377L8.168 12.7a.358.358 0 0 0-.333 0l-3.82 2.008a.358.358 0 0 1-.52-.377l.73-4.254a.358.358 0 0 0-.103-.317l-3.09-3.013a.358.358 0 0 1 .198-.61L5.5 5.515a.358.358 0 0 0 .27-.196l1.91-3.87Z"
      />
    </svg>
  );
}
