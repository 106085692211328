import React from 'react';
import { cn } from '../../utils/utilClassNames';

export default function DrawerFooter({ children, className }) {
  return (
    <div className={cn('m-0 p-2 page-profile-footer gap-2 w-full flex items-center', className)}>
      {children}
    </div>
  );
}
