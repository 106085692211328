/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import * as yup from 'yup';

import { FieldType } from '../config/forms/constant';
import { submitFormSchema } from '../config/forms/validation';

/* eslint-disable import/prefer-default-export */
export const generateValidationSchema = (formFields) => {
  const schemaFields = formFields.reduce((acc, field) => {
    let validation = null;

    switch (field.answer) {
      case FieldType.ShortText:
      case FieldType.LongText:
      case FieldType.MultipleChoices:
        validation = yup.string();
        break;
      case FieldType.Checkboxes:
        validation = yup.array();
        break;
      case FieldType.Image:
      case FieldType.FileUpload:
        validation = yup.mixed();
        break;
      default:
        validation = yup.string();

        break;
    }

    if (field.isRequired) {
      switch (field.answer) {
        case FieldType.Checkboxes:
          validation = validation.min(1, `${field.question} is required`);
          break;

        default:
          validation = validation.required(`${field.question} is required`);
          break;
      }
    }

    if (validation) {
      acc[`${field.question}`] = validation;
    }

    return acc;
  }, {});

  const schema = yup.object().shape({
    forms: yup.array().of(yup.object().shape(schemaFields)),
  });

  return schema;
};

export const generateSubmitFormValidationSchema = (formSections) => {
  const schemaFields = formSections.reduce((acc, section) => {
    section.items.forEach((field) => {
      let validation = null;

      switch (field.answer) {
        case FieldType.ShortText:
        case FieldType.LongText:
        case FieldType.MultipleChoices:
          validation = yup.string();
          break;
        case FieldType.Checkboxes:
          validation = yup.array();
          break;
        case FieldType.Image:
        case FieldType.FileUpload:
          validation = yup.mixed();
          break;
        case FieldType.Table:
          const tableSchema = field?.content?.reduce((tableAcc, tableField) => {
            let tableValidation = null;
            switch (tableField.type) {
              case FieldType.ShortText:
              case FieldType.LongText:
                tableValidation = yup.string().required(`${tableField.text} is required`);
                break;
              default:
                tableValidation = yup.string().required();
                break;
            }

            tableAcc[tableField.text] = tableValidation;

            return tableAcc;
          }, {});

          validation = yup.array().of(yup.object().shape(tableSchema));
          break;
        default:
          validation = yup.string();

          break;
      }

      if (field.isRequired) {
        switch (field.answer) {
          case FieldType.Checkboxes:
          case FieldType.Table:
            validation = validation.min(1, `${field.question} is required`);
            break;

          default:
            validation = validation.required(`${field.question} is required`);
            break;
        }
      }

      acc[field.question] = validation;
    });
    return acc;
  }, {});

  const schema = yup
    .object()
    .shape({
      forms: yup.array().of(yup.object().shape(schemaFields)),
    })
    .concat(submitFormSchema);

  return schema;
};
