/* eslint-disable no-plusplus */
/* eslint-disable prefer-template */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { toTitleCase } from '../../../../utils/text';
import { generateHexColorArray } from '../../../../utils/color';

export default function FormSurveyMultipleSummary({ field }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  // Calculate percentages
  const totalItems = field?.responses.length;
  const result = field?.responses.reduce((acc, item) => {
    acc[item] = acc[item] || { count: 0, percentage: 0 };
    acc[item].count++;
    acc[item].percentage = ((acc[item].count / totalItems) * 100).toFixed(2);
    if (acc[item].percentage % 1 === 0) {
      acc[item].percentage = `${parseInt(acc[item].percentage, 10)}%`;
    } else {
      acc[item].percentage = `${acc[item].percentage}%`;
    }
    return acc;
  }, {});

  const data = Object.entries(result).map(([key, value]) => ({
    name: key,
    value: value.count,
  }));

  const generatedColor = generateHexColorArray(data.length) || [];
  const colors = ['#20BB7DA3', '#6678D1A3', '#DC9E57', '#575D5E', ...generatedColor];

  return (
    <div className="grid grid-cols-[300px_1fr] gap-6">
      <div>
        <div className="pb-2 mb-2 border-b border-neutral-color-divider">
          <h2 className="text-neutral-color-darker font-semibold text-md">{field?.field_label}</h2>

          <p className="text-right text-neutral-color-main">{toTitleCase(field?.field_type)}</p>
        </div>

        <div>
          <h2 className="text-neutral-color-main text-base mb-2">{totalItems || 0} responses</h2>
          {Object.entries(result).map(([key, value]) => (
            <h2 className="text-neutral-color-dark text-sm">
              {key} : {value.count} ({value.percentage})
            </h2>
          ))}
        </div>
      </div>

      <div className="bg-neutral-variant-color-lighter h-[272px]">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              label={renderActiveShape}
              data={data}
              cx="50%"
              cy="45%"
              innerRadius={29}
              outerRadius={40}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={onPieEnter}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
              <Label
                content={<CustomLabel labelText="Responses" value={totalItems} />}
                position="center"
              />
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    // innerRadius,
    outerRadius,
    // startAngle,
    // endAngle,
    fill,
    // payload,
    percent,
  } = props;

  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 10;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
        fontSize={14}
        fontWeight={600}
      >
        {`${props.payload.name} (${(percent * 100).toFixed(1)}%)`}
      </text>
    </g>
  );
};

const CustomLabel = ({ viewBox, labelText, value }) => {
  const { cx, cy } = viewBox;
  return (
    <g>
      <text
        x={cx}
        y={cy}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fontSize="20"
        fill="#4A4C50"
        fontWeight="600"
      >
        {value}
      </text>
      <text
        x={cx}
        y={cy + 15}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill="#727478"
        fontSize="9"
        fontWeight="400"
      >
        {labelText}
      </text>
    </g>
  );
};
