import React from 'react';

export default function ShortTextIcon(props) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.33203 5.75H15.332C15.882 5.75 16.332 6.2 16.332 6.75C16.332 7.3 15.882 7.75 15.332 7.75H1.33203C0.782031 7.75 0.332031 7.3 0.332031 6.75C0.332031 6.2 0.782031 5.75 1.33203 5.75ZM1.33203 9.75H9.33203C9.88203 9.75 10.332 10.2 10.332 10.75C10.332 11.3 9.88203 11.75 9.33203 11.75H1.33203C0.782031 11.75 0.332031 11.3 0.332031 10.75C0.332031 10.2 0.782031 9.75 1.33203 9.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
