import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../components/dialog/dialog';
import Button from '../../../../components/button/button';

export default function BulkDeleteFormModal({ open, onClose, onDelete, isDeleting }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete selected forms?</DialogTitle>
          <DialogDescription>Selected forms will be deleted.</DialogDescription>
        </DialogHeader>

        <DialogFooter className="gap-2">
          <Button className="w-[100px]" size="large" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button className="w-[100px]" size="large" onClick={onDelete} disabled={isDeleting}>
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
