/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { faFilter, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import TextInput from '../input/textInput';
import Button from '../button/button';
import PlusIcon from '../../assets/svg-icons/PlusIcon';
import SearchIcon from '../../assets/svg-icons/SearchIcon';

function HeaderPage({ handleAdd, title, search, filter }) {
  const {
    value: searchValue,
    onChange: onSearchValueChange,
    placeholder: searchPlaceholder,
  } = search || {};

  const { filterList, onChange: onFilterChange, selectedFilter } = filter || {};

  const [isShowCheckboxes, setIsShowCheckboxes] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  return (
    <>
      <div className="flex justify-between items-center mb-5">
        <h4 className="text-2xl text-neutral-color-darker mb-0">{title}</h4>
        <div className="flex items-center gap-2">
          <TextInput
            value={searchValue}
            onChange={onSearchValueChange}
            placeholder={searchPlaceholder}
            containerClassName="w-[249px] h-[42px]"
            suffix={<SearchIcon className="text-neutral-color-main" />}
          />
          <Button onClick={handleAdd} className="size-[42px]">
            <PlusIcon color="#ffffff" />
          </Button>

          {filter && (
            <button
              data-toggle="collapse"
              data-target="#tablefilters"
              className="text-red-600 bg-white border border-gray-300 rounded-md p-2 hover:bg-gray-200"
              type="button"
              onClick={() => {
                setIsFilterVisible(!isFilterVisible);
              }}
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>
          )}
        </div>
      </div>

      {isFilterVisible && (
        <div className="w-96 shadow-lg rounded-lg my-5 bg-white">
          <div className="flex justify-between items-center p-2">
            <h4 className="text-lg ml-4">Table columns</h4>
            <button type="button" onClick={() => setIsShowCheckboxes(!isShowCheckboxes)}>
              <FontAwesomeIcon icon={faAngleDown} />
            </button>
          </div>

          {isShowCheckboxes && (
            <div className="flex flex-wrap bg-gray-100 p-4 rounded-b-lg">
              {filterList?.map((column) => {
                return (
                  <div key={column.accessor} className="ml-4">
                    <input
                      type="checkbox"
                      checked={selectedFilter.includes(column.accessor)}
                      name={column.accessor}
                      onChange={onFilterChange}
                      className="mr-2"
                    />
                    <span>{column.Header}</span>
                  </div>
                );
              })}
              <br />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default HeaderPage;

HeaderPage.propTypes = {
  handleAdd: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  search: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
  }).isRequired,
  filter: PropTypes.shape({
    filterList: PropTypes.arrayOf(
      PropTypes.shape({
        accessor: PropTypes.string.isRequired,
        Header: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    selectedFilter: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};
