
/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import './newCalendar.css';
import {
  getWorkOrderById,
  getPagination as getPaginationWO,
  openWorkOrderModalUpdate,
  setWorkOrder,
  resetWorkOrder,
} from "../../redux/features/workOrder/workOrderSlice";
import { getMeterById, getMetersByPagination, openMeterModalUpdate, setTab as meterTab, } from "../../redux/features/meter/meterSlice";
import { setAsset } from "../../redux/features/asset/assetSlice";
import { selectActions } from "../../redux/features/selectOptions/selectSlice";
import TaskPlannerBoard from './components/TaskPlannerBoard';
import TaskPlannerCalendar from './components/TaskPlannerCalendar';


export default function TaskPlanner({ selectedMenu, selectedViewMode }) {
  const dispatch = useDispatch()
  const handleSelectedEvent = async (event) => {
    const filterData = {
      site: '',
      sublocation: '',
      filterCategory: '',
      type: '',
      staff: '',
      status: '',
      asset: '',
    };
    if (event?.ticket_type === 'workorder') {
      dispatch(setWorkOrder({}))
      dispatch(resetWorkOrder())
      dispatch(setAsset(null))
      dispatch(dispatch(selectActions.reset()))
      await dispatch(getWorkOrderById(event?.id));
      dispatch(
        getPaginationWO({
          rowPerPage: 10,
          currentPage: 1,
          searchValue: event?.title,
          ...filterData,
        }),
      );
      dispatch(openWorkOrderModalUpdate());
    } else if (event?.ticket_type === 'meter') {
      await dispatch(getMeterById(event?.id));
      dispatch(getMetersByPagination({ rowPerPage: 10, currentPage: 1, SearchVal: event?.title }));
      dispatch(openMeterModalUpdate());
      dispatch(meterTab('service-tab'));
    }
  };

  return (
    <>
      {selectedViewMode === 'Board mode' && (
        <TaskPlannerBoard selectedMenu={selectedMenu} handleSelectedEvent={handleSelectedEvent} />
      )}
      {selectedViewMode === 'Calendar mode' && (
        <TaskPlannerCalendar handleSelectedEvent={handleSelectedEvent} />
      )}
    </>
  );
}
