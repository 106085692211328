/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import CopyIcon from '../../../../assets/svg-icons/CopyIcon';
import TrashIcon from '../../../../assets/svg-icons/TrashIcon';
import TextInput from '../../../../components/input/textInput';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../../components/accordion/accordion';
import ChildSection from './ChildSection';
import { FormControl, FormField, FormItem, FormMessage } from '../../../../components/form/form';
import { setActiveIndex } from '../../../../redux/features/forms/formSlice';
import { cn } from '../../../../utils/utilClassNames';
import { FormStatus } from '../../config/forms/constant';

const animateLayoutChanges = (args) =>
  args.isSorting || args.wasDragging ? defaultAnimateLayoutChanges(args) : true;

export default function ParentSection({
  id,
  index,
  totalSection,
  dropdownOpenList,
  setOpenDropdownList,
}) {
  const form = useFormContext();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    animateLayoutChanges,
    id: id?.toString(),
  });

  const styleParent = {
    transform: CSS.Transform?.toString(transform),
    transition,
  };

  const sectionNumber = index + 1;

  const dispatch = useDispatch();
  const { activeIndex, form: formDetail } = useSelector((state) => state.form);

  const isParentFocus = activeIndex === `${index}-0`;

  const isPublished = formDetail.status === FormStatus.Published;

  const onCopy = () => {
    if (isPublished) return;
    const data = form.getValues();
    const newForm = [...data.forms];
    const newSection = {
      ...newForm[index],
      id: Math.random(),
    };
    newForm.splice(index + 1, 0, newSection);
    form.setValue('forms', newForm);
  };

  const onDelete = () => {
    const data = form.getValues();
    const newForm = [...data.forms];
    newForm.splice(index, 1);
    form.setValue('forms', newForm);
  };

  // const [showChild, setShowChild] = React.useState(false);

  const onDropdownChange = (open) => {
    setOpenDropdownList((prev) => {
      if (prev.includes(id?.toString())) {
        return prev.filter((item) => item !== id?.toString());
      }
      return [...prev, id?.toString()];
    });
  };

  const valueItem =
    dropdownOpenList?.find((dropdown) => dropdown.toString() === id?.toString()) || '';

  return (
    <>
      <div
        className="rounded-lg overflow-hidden bg-white shadow-sm border border-neutral-color-divider max-w-screen-2xl mx-auto"
        ref={setNodeRef}
        style={styleParent}
        id={id?.toString()}
        {...attributes}
      >
        <div className="bg-tnb-psd-color-blue-main px-4 py-1">
          <h2 className="text-white text-sm mb-0">
            Section {sectionNumber} of {totalSection}
          </h2>
        </div>

        <Accordion
          type="single"
          value={valueItem}
          collapsible
          onValueChange={(value) => {
            // setAccordionId(value);
            onDropdownChange(value);
            // setShowChild((prev) => !prev);
            console.log('VALUE', value);
          }}
        >
          <AccordionItem
            value={id?.toString()}
            className={cn(
              'border-l-8',
              isParentFocus ? ' border-l-neutral-color-light' : 'border-transparent',
            )}
          >
            <AccordionPrimitive.Header className="flex mb-0 h-[56px] group items-center px-4 group">
              <div className="flex flex-1 items-center">
                <FontAwesomeIcon
                  icon={faGripVertical}
                  className="fill-neutral-color-light text-neutral-color-light pr-4 size-4"
                  {...(!isPublished && listeners)}
                />

                <FormField
                  control={form.control}
                  name={`forms.${index}.title`}
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormControl>
                        <TextInput
                          size="small"
                          variant="ghost"
                          className="placeholder:font-bold text-bold flex-1 w-full"
                          placeholder="Type the title here..."
                          containerClassName="h-auto"
                          onFocus={() => {
                            dispatch(setActiveIndex(`${index}-0`));
                          }}
                          disabled={isPublished}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex gap-2 mr-2 items-center group-data-[state=closed]:hidden">
                <CopyIcon
                  className={cn(isPublished ? 'text-neutral-color-main' : '')}
                  onClick={onCopy}
                />
                <TrashIcon
                  className={cn(isPublished ? 'text-neutral-color-main' : '')}
                  onClick={onDelete}
                />

                <span className="text-neutral-color-divider">|</span>
              </div>

              <AccordionTrigger className="self-end" />
            </AccordionPrimitive.Header>

            <AccordionContent className="pl-[65px] pr-[42px]">
              <FormField
                control={form.control}
                name={`forms.${index}.description`}
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <TextInput
                        variant="filled"
                        className="w-full flex-1"
                        placeholder="Description (Optional)"
                        onFocus={() => {
                          dispatch(setActiveIndex(`${index}-0`));
                        }}
                        disabled={isPublished}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

      {valueItem && <ChildSection index={index} />}
    </>
  );
}
