import React from 'react';

export default function CalendarIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth={1.7}
          d="M1.5 6.778h13m-9.389-2.89V1m5.778 2.889V1M6.122 15.444h3.756c1.618 0 2.427 0 3.045-.314a2.889 2.889 0 0 0 1.262-1.263c.315-.618.315-1.427.315-3.045V7.067c0-1.618 0-2.427-.315-3.045a2.888 2.888 0 0 0-1.262-1.263c-.618-.315-1.427-.315-3.045-.315H6.122c-1.618 0-2.427 0-3.045.315a2.889 2.889 0 0 0-1.262 1.263C1.5 4.64 1.5 5.449 1.5 7.067v3.755c0 1.618 0 2.427.315 3.045.277.544.719.986 1.262 1.263.618.314 1.427.314 3.045.314Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
