import React from 'react';
import PropTypes from 'prop-types';
import TooltipIcon from '../../assets/svg-icons/TooltipIcon';

import classes from './tabs.module.css';
import { cn } from '../../utils/utilClassNames';

function Tabs({ tabList, selectedTab, showTooltip }) {
  return (
    <div className={`${classes.tabs} mb-3 mt-3`}>
      {tabList?.map((item) => {
        return (
          <button
            key={item.value}
            type="button"
            className={cn(
              item.value !== selectedTab && !item.disabled ? classes.inactive : '',
              item.disabled
                ? '!bg-neutral-variant-color-lighter !text-neutral-variant-color-light border-0 !cursor-not-allowed'
                : '',
            )}
            onClick={() => {
              if (item.disabled) {
                return;
              }
              item.onClick(item.value);
            }}
          >
            {item.label}
            {showTooltip && (
              <span>
                <TooltipIcon />
              </span>
            )}
          </button>
        );
      })}
    </div>
  );
}

export default Tabs;

Tabs.propTypes = {
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
  selectedTab: PropTypes.string.isRequired,
  showTooltip: PropTypes.bool,
};

Tabs.defaultProps = {
  showTooltip: true,
};
