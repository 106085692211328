import { addToast, removeToast } from '../../redux/features/toast/toastSlice';
import store from '../../redux/store'; // Import the Redux store

const toast = {
  success: (message) => {
    const id = Math.random().toString(36).substring(7);
    store.dispatch(
      addToast({
        id,
        type: 'success',
        message,
      }),
    );

    // Auto-remove after 3 seconds
    setTimeout(() => store.dispatch(removeToast(id)), 3000);
  },
  error: (message) => {
    const id = Math.random().toString(36).substring(7);
    store.dispatch(
      addToast({
        id,
        type: 'error',
        message,
      }),
    );
    // Auto-remove after 3 seconds
    setTimeout(() => store.dispatch(removeToast(id)), 3000);
  },
};

export default toast;
