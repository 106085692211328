import React from 'react';

export default function TableIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.7}
          d="M5.667 1H2.556A1.556 1.556 0 0 0 1 2.556v3.11M5.667 1h7.777A1.556 1.556 0 0 1 15 2.556v3.11M5.667 1v14M1 5.667v7.777A1.556 1.556 0 0 0 2.556 15h3.11M1 5.667h14m0 0v7.777A1.556 1.556 0 0 1 13.444 15H5.667"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
