import React from 'react';
import FormPreviewHeader from '../FormPreview/FormPreviewHeader';

export default function FormSurveyEnding() {
  return (
    <div className="space-y-6">
      <FormPreviewHeader />

      <div className="bg-white rounded-2xl py-6 px-10 mx-auto space-y-4">
        <div className="bg-neutral-color-aplha-neutral-8 rounded-lg px-2 py-3">
          <p className="font-semibold text-sm text-neutral-color-darker mb-0">
            Thank you. Your response has been recorded.
          </p>
        </div>
      </div>
    </div>
  );
}
