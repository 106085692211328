import * as React from 'react';

function CheckboxIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <g stroke="currentColor" strokeLinejoin="round" strokeWidth={1.7} clipPath="url(#a)">
        <path strokeLinecap="round" d="m11.5 5.083-4.9 5.833-2.1-2.333" />
        <path d="M13.25 1H2.75A1.75 1.75 0 0 0 1 2.75v10.5c0 .966.784 1.75 1.75 1.75h10.5A1.75 1.75 0 0 0 15 13.25V2.75A1.75 1.75 0 0 0 13.25 1Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default CheckboxIcon;
