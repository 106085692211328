import React from 'react';

import { defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import CopyIcon from '../../../../assets/svg-icons/CopyIcon';
import TrashIcon from '../../../../assets/svg-icons/TrashIcon';
import TextInput from '../../../../components/input/textInput';
import Label from '../../../../components/label/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../../components/select/selectV2';
import Switch from '../../../../components/switch/switch';
import {
  answerRequestSelectOption,
  answerSurveySelectOption,
} from '../../config/forms/selectOption';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../../components/accordion/accordion';
import {
  FormControl,
  FormField,
  FormLabel,
  FormMessage,
  FormItem as RhfFormItem,
} from '../../../../components/form/form';
import { setActiveIndex } from '../../../../redux/features/forms/formSlice';
import { cn } from '../../../../utils/utilClassNames';
import SelectAnswer from './SelectAnswer';
import { FieldType, FormStatus, FormType } from '../../config/forms/constant';

const animateLayoutChanges = (args) =>
  args.isSorting || args.wasDragging ? defaultAnimateLayoutChanges(args) : true;

export default function FormItem({ id, parentIndex, index }) {
  const form = useFormContext();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    animateLayoutChanges,
    id: id?.toString(),
  });

  const styleParent = {
    transform: CSS.Transform?.toString(transform),
    transition,
  };

  const dispatch = useDispatch();
  const { activeIndex, form: formDetail } = useSelector((state) => state.form);

  const isActive = activeIndex === `${parentIndex}-${index + 1}`;

  const question = form.watch(`forms.${parentIndex}.items.${index}.question`);
  const answer = form.watch(`forms.${parentIndex}.items.${index}.answer`);

  const isPublished = formDetail.status === FormStatus.Published;

  const onCopy = () => {
    if (isPublished) return;
    const items = form.watch(`forms.${parentIndex}.items`);
    const newItem = { ...items[index], id: Math.random() };
    items.splice(index + 1, 0, newItem);
    form.setValue(`forms.${parentIndex}.items`, items);
  };

  const onDelete = () => {
    if (isPublished) return;

    const items = form.watch(`forms.${parentIndex}.items`);
    form.setValue(
      `forms.${parentIndex}.items`,
      items.filter((item, i) => i !== index),
    );
  };

  const answerSelectOption =
    formDetail.form_type === FormType.Survey ? answerSurveySelectOption : answerRequestSelectOption;

  const selectedAnswerOption = answerSelectOption.find((item) => item.value === answer);

  return (
    <div
      className="flex max-w-screen-2xl mx-auto pt-4"
      ref={setNodeRef}
      style={styleParent}
      id={id?.toString()}
      {...attributes}
    >
      <div
        className={cn(
          'rounded-md ml-11 px-[2px] bg-white flex items-start gap-4 w-full border-r border-t border-b',
          isActive
            ? 'border-l-8 border-l-neutral-color-light pl-2'
            : 'pl-[13px] border-l border-neutral-color-divider',
        )}
      >
        <Accordion className="w-full" type="single" collapsible>
          <AccordionItem value="item-1">
            <AccordionPrimitive.Header className="flex mb-0 h-[56px] group items-center pr-3 group">
              <div className={cn('flex flex-1 items-center pl-[2px]', isActive ? 'pl-0' : '')}>
                <FontAwesomeIcon
                  icon={faGripVertical}
                  className="fill-neutral-color-light text-neutral-color-light pr-4 size-4"
                  {...(!isPublished && listeners)}
                />

                <h2 className="mb-0 font-semibold text-sm text-neutral-color-darker group-data-[state=open]:hidden">
                  {question}
                </h2>
              </div>

              <div className="flex items-center">
                <div className="flex gap-2 items-center group-data-[state=open]:hidden">
                  <p className="mb-0 text-sm text-neutral-color-dark">
                    {selectedAnswerOption?.label}
                  </p>
                  {selectedAnswerOption && (
                    <selectedAnswerOption.icon className="text-theme-color-secondary-main" />
                  )}

                  <span className="text-neutral-color-divider mx-2">|</span>
                </div>
                <AccordionTrigger />
              </div>
            </AccordionPrimitive.Header>

            <AccordionContent className="pl-[40px] pr-[42px] -mt-9">
              <div className="-my-1 w-full">
                <div className="grid md:grid-cols-2 gap-4 w-full border-b border-neutral-color-divider pb-4">
                  <div>
                    <FormField
                      control={form.control}
                      name={`forms.${parentIndex}.items.${index}.question`}
                      render={({ field }) => (
                        <RhfFormItem>
                          <FormLabel className="mb-2">Question</FormLabel>
                          <FormControl>
                            <TextInput
                              variant="filled"
                              className="w-full flex-1"
                              placeholder="Type your question here"
                              onFocus={() => {
                                dispatch(setActiveIndex(`${parentIndex}-${index + 1}`));
                              }}
                              disabled={isPublished}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </RhfFormItem>
                      )}
                    />
                  </div>

                  <div>
                    <FormField
                      control={form.control}
                      name={`forms.${parentIndex}.items.${index}.answer`}
                      render={({ field }) => (
                        <RhfFormItem>
                          <Label className="mb-2">Answer</Label>
                          <FormControl>
                            <Select
                              onValueChange={(value) => {
                                field.onChange(value);

                                if (
                                  value === FieldType.Checkboxes ||
                                  value === FieldType.MultipleChoices ||
                                  value === FieldType.Dropdown ||
                                  value === FieldType.Table
                                ) {
                                  form.setValue(`forms.${parentIndex}.items.${index}.content`, [
                                    {
                                      id: Math.random(),
                                    },
                                  ]);
                                } else {
                                  form.setValue(`forms.${parentIndex}.items.${index}.content`, '');
                                }
                              }}
                              disabled={isPublished}
                              defaultValue={field.value}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select an answer" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectGroup className="grid grid-cols-2">
                                  {answerSelectOption?.map((option) => {
                                    return (
                                      <SelectItem
                                        value={option.value}
                                        key={option.value}
                                        className="px-3"
                                      >
                                        {option.icon && (
                                          <option.icon
                                            className={cn(
                                              'inline-block mr-2',
                                              isPublished
                                                ? 'text-neutral-color-main'
                                                : 'text-theme-color-secondary-main',
                                            )}
                                          />
                                        )}
                                        {option.label}
                                      </SelectItem>
                                    );
                                  })}
                                </SelectGroup>
                              </SelectContent>
                            </Select>
                          </FormControl>
                          <FormMessage />
                        </RhfFormItem>
                      )}
                    />
                  </div>

                  <div className={cn(answer === 'table' ? 'col-span-2' : '')}>
                    <SelectAnswer
                      type={answer}
                      name={`forms.${parentIndex}.items.${index}.content`}
                      disabled={isPublished}
                    />
                  </div>
                </div>

                <div>
                  <div className="flex gap-3 items-center px-3 justify-end py-3">
                    <CopyIcon
                      className={cn(isPublished ? 'text-neutral-color-main' : '')}
                      onClick={onCopy}
                    />
                    <TrashIcon
                      className={cn(isPublished ? 'text-neutral-color-main' : '')}
                      onClick={onDelete}
                    />

                    <div className="h-5 w-0.5 bg-neutral-color-divider" />
                    <FormField
                      control={form.control}
                      name={`forms.${parentIndex}.items.${index}.isRequired`}
                      render={({ field }) => {
                        return (
                          <RhfFormItem>
                            <FormControl>
                              <div className="flex items-center gap-2">
                                <Switch
                                  disabled={isPublished}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                  }}
                                  checked={field.value}
                                />
                                <Label>Required</Label>
                              </div>
                            </FormControl>
                            <FormMessage />
                          </RhfFormItem>
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}
