import React from 'react';
import { classNames } from '../../utils';

export default function TextArea({ name, value, placeholder, id, onChange, className, ...props }) {
  return (
    <textarea
      name={name}
      className={classNames(
        'form-control the-fixed-input !w-full text-sm disabled:!bg-neutral-color-theme-color-light',
        className,
      )}
      placeholder={placeholder}
      id="description"
      onChange={onChange}
      value={value}
      {...props}
    />
  );
}
