/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import DownIcon from '../../../../assets/svg-icons/DownIcon';
import EyeIcon from '../../../../assets/svg-icons/EyeIcon';
import EyeOffIcon from '../../../../assets/svg-icons/EyeOffIcon';
import TooltipIcon from '../../../../assets/svg-icons/TooltipIcon';
import Button from '../../../../components/button/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../../../../components/command/command';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../components/form/form';
import { RadioGroup, RadioGroupItem } from '../../../../components/input/radioInput';
import TextArea from '../../../../components/input/textArea';
import TextInput from '../../../../components/input/textInput';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../components/popover/popover';
import Switch from '../../../../components/switch/switch';
import { Tooltip, TooltipTrigger } from '../../../../components/tooltip/tooltipV2';
import { getFormCategoryList, setFormCategories } from '../../../../redux/features/forms/formSlice';
import { cn } from '../../../../utils/utilClassNames';
import { FormStatus, FormType } from '../../config/forms/constant';

export default function FormConfigurationForm() {
  const form = useFormContext();
  const { form: formDetail, formCategories } = useSelector((state) => state.form);
  const [showPassword, setShowPassword] = React.useState(false);
  const dispatch = useDispatch();

  const isPublished = formDetail.status === FormStatus.Published;

  const [inputValue, setInputValue] = useState('');
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  const categories = formCategories.map((category) => ({
    label: category.name,
    value: category.name,
  }));

  useEffect(() => {
    dispatch(getFormCategoryList());
  }, []);

  return (
    <>
      <div>
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <TextArea placeholder="description" disabled={isPublished} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className="grid md:grid-cols-3 gap-3">
        <div>
          <FormField
            control={form.control}
            name="document_reference"
            render={({ field }) => (
              <FormItem>
                <FormLabel isRequired>Document reference format</FormLabel>
                <FormControl>
                  <TextInput placeholder="Input..." disabled={isPublished} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div>
          <FormField
            control={form.control}
            name="category"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel isRequired>Category</FormLabel>
                <Popover open={isCategoryOpen} onOpenChange={setIsCategoryOpen}>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        disabled={isPublished}
                        variant="outline"
                        role="combobox"
                        className={cn(
                          'w-full justify-between text-sm font-normal h-[42px]',
                          !field.value && 'text-neutral-color-main',
                          isPublished ? 'disabled:!bg-neutral-color-theme-color-light' : '',
                        )}
                      >
                        {field.value
                          ? categories.find((category) => category.value === field.value)?.label
                          : 'Search category or add'}
                        <DownIcon className="opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent
                    className="PopoverContent p-0 bg-neutral-color-theme-color-light"
                    isPortal={false}
                  >
                    <Command>
                      <CommandInput
                        placeholder="Search category"
                        className="h-9"
                        onValueChange={(value) => {
                          setInputValue(value);
                        }}
                      />
                      <CommandList>
                        <CommandEmpty>
                          <div>
                            <div className="border-b border-neutral-color-divider pb-1 mb-1">
                              No results found.
                            </div>
                            <div
                              className="text-neutral-color-darker"
                              onClick={() => {
                                dispatch(
                                  setFormCategories([
                                    ...formCategories,
                                    { id: Math.random().toString(), name: inputValue },
                                  ]),
                                );
                                field.onChange(inputValue);
                              }}
                            >
                              Add <span className="font-bold">{inputValue}</span>
                            </div>
                          </div>
                        </CommandEmpty>
                        <CommandGroup>
                          {categories.map((category) => (
                            <CommandItem
                              className="cursor-pointer"
                              value={category.label}
                              key={category.value}
                              onSelect={() => {
                                field.onChange(category.value);
                                setIsCategoryOpen(false);
                              }}
                            >
                              {category.label}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>

                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div>
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="flex items-center">
                  Password
                  <span className="font-danger mr-1">*</span>
                  <Tooltip
                    className="w-[167px]"
                    content="Password is only applicable for survey form."
                    side="top"
                  >
                    <TooltipTrigger>
                      <TooltipIcon />
                    </TooltipTrigger>
                  </Tooltip>
                </FormLabel>
                <FormControl>
                  <TextInput
                    placeholder="Not set..."
                    type={showPassword ? 'text' : 'password'}
                    suffix={
                      showPassword ? (
                        <EyeIcon
                          onClick={() => setShowPassword(!showPassword)}
                          className="text-neutral-color-neutral-variant-color-main cursor-pointer"
                        />
                      ) : (
                        <EyeOffIcon
                          onClick={() => setShowPassword(!showPassword)}
                          className="text-neutral-color-neutral-variant-color-main cursor-pointer"
                        />
                      )
                    }
                    disabled={formDetail.form_type !== FormType.Survey}
                    className="!border-neutral-color-divider border"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      <FormField
        control={form.control}
        name="form_type"
        render={({ field }) => (
          <FormItem className="space-y-3">
            <FormLabel isRequired>Form Type</FormLabel>
            <FormControl>
              <RadioGroup>
                <FormItem className="flex items-start space-x-3 space-y-0 border border-neutral-color-divider rounded-[8px] p-3">
                  <FormControl>
                    <RadioGroupItem
                      disabled
                      className="mt-1"
                      value={FormType.Request}
                      checked={field.value === FormType.Request}
                      onChange={(value) => {
                        field.onChange(value);
                        form.setValue('is_due_date_on', true);
                      }}
                    />
                  </FormControl>
                  <div>
                    <FormLabel className="font-normal leading-none">
                      Request Form (need approval)
                    </FormLabel>
                    <p className="text-neutral-color-main text-sm mb-0">
                      Requires an approver to review and approve/reject the request before it can be
                      processed.
                    </p>
                    {form.watch('form_type') === FormType.Request && (
                      <FormField
                        control={form.control}
                        name="is_due_date"
                        render={({ field: fieldSwitch }) => (
                          <FormItem className="space-y-3">
                            <FormControl>
                              <div className="flex items-center space-x-2 mt-2">
                                <Switch
                                  disabled
                                  {...fieldSwitch}
                                  onChange={(e) => {
                                    fieldSwitch.onChange(e.target.checked);
                                  }}
                                  checked={fieldSwitch.value}
                                />
                                <p className="mb-0 text-sm text-neutral-color-darker">Due Date</p>

                                <Tooltip
                                  className="w-[275px]"
                                  content="Enable to allow users to set work duration. Forms can be extended, cancelled, or closed as needed."
                                  side="top"
                                >
                                  <TooltipTrigger>
                                    <TooltipIcon />
                                  </TooltipTrigger>
                                </Tooltip>
                              </div>
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    )}
                  </div>
                </FormItem>
                <FormItem className="flex items-start space-x-3 space-y-0 border border-neutral-color-divider rounded-[8px] p-3">
                  <FormControl>
                    <RadioGroupItem
                      className="mt-1"
                      value={FormType.Survey}
                      checked={field.value === FormType.Survey}
                      onChange={field.onChange}
                    />
                  </FormControl>
                  <div className="flex-1">
                    <FormLabel className="font-normal leading-none">
                      Survey Form (collecting feedback)
                    </FormLabel>
                    <p className="text-neutral-color-main text-sm mb-0">
                      Allows you to collect feedback from respondents without requiring any approval
                      process.
                    </p>
                  </div>
                </FormItem>
              </RadioGroup>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}
