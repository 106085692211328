/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PlusIcon from '../../assets/svg-icons/PlusIcon';
import TrashIcon from '../../assets/svg-icons/TrashIcon';
import { cn } from '../../utils/utilClassNames';

export default function UploadInput({ onChange, className, title, type, ...rest }) {
  const [file, setFile] = useState(null);

  const handleDrop = (event) => {
    event.preventDefault();
    const acceptedFile = event.dataTransfer.files[0];
    if (acceptedFile) {
      setFile({
        preview: URL.createObjectURL(acceptedFile),
        name: acceptedFile.name,
      });
      onChange([acceptedFile]);
    }
  };

  const handleFileChange = (event) => {
    const acceptedFile = event.target.files[0];
    if (acceptedFile) {
      setFile({
        preview: URL.createObjectURL(acceptedFile),
        name: acceptedFile.name,
      });
      onChange([acceptedFile]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div>
        <p className="mb-2 text-neutral-color-neutral-variant-color-dark text-sm">{title}</p>
      </div>
      <div
        className={cn(
          'rounded-[10px] border-[0.83px] border-dashed border-gray flex items-center justify-center size-[130px]',
          rest.disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          className,
        )}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={() => document.getElementById('file-input').click()}
      >
        {file ? (
          <div className="relative w-full h-full">
            {
              // eslint-disable-next-line no-nested-ternary
              type === 'file' ? (
                <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50 flex flex-col items-center justify-center">
                  <span role="img" aria-label="document">
                    📄
                  </span>
                  <p className="text-xs text-neutral-color-neutral-variant-color-dark">
                    {file.name}
                  </p>
                </div>
              ) : (
                <img
                  src={file.preview}
                  alt={file.name}
                  className="h-full w-full object-cover rounded"
                />
              )
            }

            <div
              className="cursor-pointer absolute top-2 left-2 bg-theme-color-secondary-main rounded-full p-1"
              onClick={(e) => {
                e.stopPropagation();
                setFile(null);
                onChange(null);
              }}
            >
              <TrashIcon className="text-white" />
            </div>
          </div>
        ) : (
          <>
            <input
              id="file-input"
              type="file"
              className="hidden"
              onChange={handleFileChange}
              {...rest}
            />
            <PlusIcon color="#9DA0A6" />
          </>
        )}
      </div>
    </div>
  );
}
