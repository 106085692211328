/* eslint-disable no-unused-vars */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../components/form/form';
import TextArea from '../../../../components/input/textArea';
import { requestRejectedFormSchema } from '../../config/forms/validation';
import Button from '../../../../components/button/button';
import SaveRejectedReasonModal from '../Modal/SaveRejectedReasonModal';
import {
  approveRejectForm,
  getFormResponseDetailById,
} from '../../../../redux/features/forms/formSlice';

export default function FormDetailResponsesRejected() {
  const [isRejectReasonModalOpen, setIsRejectReasonModalOpen] = useState(false);

  const { responseId } = useParams();

  const form = useForm({
    mode: 'onChange',
    resolver: yupResolver(requestRejectedFormSchema),
  });

  const dispatch = useDispatch();

  const { isFormApproveRejectLoading, formResponseDetail } = useSelector((state) => state.form);

  const onReject = async () => {
    try {
      const result = await dispatch(
        approveRejectForm({
          form_response_id: responseId,
          decision: 'reject',
          reason: form.watch('reason'),
        }),
      );
      unwrapResult(result);
      dispatch(getFormResponseDetailById(responseId));
      setIsRejectReasonModalOpen(false);
      toast.success('Form response rejected successfully');
    } catch (error) {
      toast.error('Error rejecting form response');
    }
  };

  useEffect(() => {
    form.reset({
      reason: formResponseDetail?.reason,
    });
  }, [formResponseDetail]);

  return (
    <Form {...form}>
      <form className="space-y-4">
        <div className="border border-neutral-color-divider py-3 px-4 rounded-lg bg-neutral-variant-color-lighter">
          <h2 className="mb-0 font-semibold text-neutral-color-darker text-sm">Request rejected</h2>
        </div>

        <div>
          <FormField
            control={form.control}
            name="reason"
            render={({ field }) => (
              <FormItem>
                <FormLabel isRequired>Reason</FormLabel>
                <FormControl>
                  <TextArea
                    placeholder="Type your reason here"
                    disabled={!!formResponseDetail?.reason}
                    className="!h-[100px]"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div>
          <p className="text-neutral-color-darker italic text-sm">
            <span className="not-italic font-semibold">NOTE:</span> Please provide a reason for
            rejecting this request. Your input will help the requester address any issues and
            resubmit if necessary.
          </p>
        </div>

        <div className="flex justify-end">
          <Button
            disabled={!form.formState.isValid || !!formResponseDetail?.reason}
            className="w-[83px] h-[42px] rounded-[8px]"
            onClick={() => setIsRejectReasonModalOpen(true)}
          >
            Save
          </Button>
        </div>
      </form>

      <SaveRejectedReasonModal
        open={isRejectReasonModalOpen}
        onClose={() => setIsRejectReasonModalOpen(false)}
        onReject={onReject}
        isLoading={isFormApproveRejectLoading}
      />
    </Form>
  );
}
