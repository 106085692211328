import React from 'react';

export default function LockIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={14} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={2}
        d="M3.332 5V3.667a2.667 2.667 0 0 1 5.333 0V5m-5.2 8h5.067c.747 0 1.12 0 1.405-.145.251-.128.455-.332.583-.583.145-.285.145-.659.145-1.405V7.133c0-.746 0-1.12-.145-1.405a1.333 1.333 0 0 0-.583-.583C9.652 5 9.28 5 8.532 5H3.465c-.746 0-1.12 0-1.405.145-.25.128-.455.332-.583.583-.145.285-.145.659-.145 1.405v3.734c0 .746 0 1.12.145 1.405.128.25.332.455.583.583.285.145.659.145 1.405.145Z"
      />
    </svg>
  );
}
