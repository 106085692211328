/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import { yupResolver } from '@hookform/resolvers/yup';
import { unwrapResult } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import SearchIcon from '../../../../assets/svg-icons/SearchIcon';
import Button from '../../../../components/button/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../../../../components/command/command';
import Drawer from '../../../../components/drawer/drawer';
import DrawerContent from '../../../../components/drawer/drawerContent';
import DrawerFooter from '../../../../components/drawer/drawerFooter';
import DrawerHeader from '../../../../components/drawer/drawerHeader';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../components/form/form';
import { RadioGroup, RadioGroupItem } from '../../../../components/input/radioInput';
import TextArea from '../../../../components/input/textArea';
import TextInput from '../../../../components/input/textInput';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../components/popover/popover';
import { createForm, setFormCategories } from '../../../../redux/features/forms/formSlice';
import { cn } from '../../../../utils/utilClassNames';
import { FormType } from '../../config/forms/constant';
import { createNewFormSchema } from '../../config/forms/validation';
import Switch from '../../../../components/switch/switch';
import { Tooltip, TooltipTrigger } from '../../../../components/tooltip/tooltipV2';
import TooltipIcon from '../../../../assets/svg-icons/TooltipIcon';

const defaultValues = {
  title: '',
  description: '',
  category: '',
  form_type: '',
  sections: [],
};

function AddNewFormModal({ open, onClose, getFormData }) {
  const history = useHistory();

  const form = useForm({
    resolver: yupResolver(createNewFormSchema),
    mode: 'onChange',
    defaultValues,
  });

  const dispatch = useDispatch();
  const { isFormCreateLoading, formCategories } = useSelector((state) => state.form);

  async function onSubmit(values) {
    const selectedCategories = formCategories.find(
      (category) => category.id.toString() === values.category,
    );
    try {
      const resultAction = await dispatch(
        createForm({
          ...values,
          category: {
            name: selectedCategories.name,
            description: selectedCategories.description,
          },
          status: 'draft',
          is_due_date: values.is_due_date,
          sections: [
            {
              title: 'initial',
              description: 'initial',
              order: 1,
              fields: [
                {
                  label: 'initial',
                  field_type: 'short_text',
                  order: 1,
                },
              ],
            },
          ],
        }),
      );
      unwrapResult(resultAction);

      toast.success('Form created successfully');
      history.push(`/forms/detail/${resultAction.payload.id}`);
      onClose();
      form.reset(defaultValues);
      getFormData();
    } catch (error) {
      toast.error('Failed to create form');
    }
  }

  const categories = formCategories.map((category) => ({
    label: category.name,
    value: category.id.toString(),
  }));

  const [inputValue, setInputValue] = useState('');
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  const handleClose = () => {
    onClose();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        className: 'w-full max-w-[600px] h-full',
      }}
    >
      <DrawerHeader title="Create new form " onClose={handleClose} />

      <DrawerContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel isRequired>Form title</FormLabel>
                  <FormControl>
                    <TextInput placeholder="Add form title" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <TextArea placeholder="Add some description" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="category"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <FormLabel isRequired>Category</FormLabel>
                  <Popover open={isCategoryOpen} onOpenChange={setIsCategoryOpen}>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            'w-full justify-between text-sm font-normal h-[42px]',
                            !field.value && 'text-neutral-color-main',
                          )}
                        >
                          {field.value
                            ? categories.find((category) => category.value === field.value)?.label
                            : 'Search category or add'}
                          <SearchIcon className="opacity-50 ml-auto" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent
                      className="PopoverContent p-0 w-[552px] bg-neutral-color-theme-color-light"
                      isPortal={false}
                    >
                      <Command>
                        <CommandInput
                          placeholder="Search category"
                          className="h-9"
                          onValueChange={(value) => {
                            setInputValue(value);
                          }}
                        />
                        <CommandList>
                          <CommandEmpty>
                            <div>
                              <div className="border-b border-neutral-color-divider pb-1 mb-1">
                                No results found.
                              </div>
                              <div
                                className="text-neutral-color-darker"
                                onClick={() => {
                                  dispatch(
                                    setFormCategories([
                                      ...formCategories,
                                      { id: Math.random().toString(), name: inputValue },
                                    ]),
                                  );
                                  field.onChange(inputValue);
                                }}
                              >
                                Add <span className="font-bold">{inputValue}</span>
                              </div>
                            </div>
                          </CommandEmpty>
                          <CommandGroup>
                            {categories.map((category) => (
                              <CommandItem
                                className="cursor-pointer"
                                value={category.label}
                                key={category.value}
                                onSelect={() => {
                                  field.onChange(category.value);
                                  setIsCategoryOpen(false);
                                }}
                              >
                                {category.label}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>

                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="form_type"
              render={({ field }) => (
                <FormItem className="space-y-3">
                  <FormLabel isRequired>Form Type</FormLabel>
                  <FormControl>
                    <RadioGroup>
                      <FormItem className="flex items-start space-x-3 space-y-0 border border-neutral-color-divider rounded-[8px] p-3">
                        <FormControl>
                          <RadioGroupItem
                            className="mt-1"
                            value={FormType.Request}
                            checked={field.value === FormType.Request}
                            onChange={(value) => {
                              field.onChange(value);
                              form.setValue('is_due_date', true);
                            }}
                          />
                        </FormControl>
                        <div>
                          <FormLabel className="font-normal leading-none">
                            Request Form (need approval)
                          </FormLabel>
                          <p className="text-neutral-color-main text-sm mb-0">
                            Requires an approver to review and approve/reject the request before it
                            can be processed.
                          </p>
                          {form.watch('form_type') === FormType.Request && (
                            <FormField
                              control={form.control}
                              name="is_due_date"
                              render={({ field: fieldSwitch }) => (
                                <FormItem className="space-y-3">
                                  <FormControl>
                                    <div className="flex items-center space-x-2 mt-2">
                                      <Switch
                                        onChange={(e) => {
                                          fieldSwitch.onChange(e.target.checked);
                                        }}
                                        checked={fieldSwitch.value}
                                      />
                                      <p className="mb-0 text-sm text-neutral-color-darker">
                                        Due Date
                                      </p>

                                      <Tooltip
                                        className="w-[275px]"
                                        content="Enable to allow users to set work duration. Forms can be extended, cancelled, or closed as needed."
                                        side="top"
                                      >
                                        <TooltipTrigger>
                                          <TooltipIcon />
                                        </TooltipTrigger>
                                      </Tooltip>
                                    </div>
                                  </FormControl>
                                </FormItem>
                              )}
                            />
                          )}
                        </div>
                      </FormItem>
                      <FormItem className="flex items-start space-x-3 space-y-0 border border-neutral-color-divider rounded-[8px] p-3">
                        <FormControl>
                          <RadioGroupItem
                            className="mt-1"
                            value={FormType.Survey}
                            checked={field.value === FormType.Survey}
                            onChange={(value) => {
                              field.onChange(value);
                              form.setValue('is_due_date', false);
                            }}
                          />
                        </FormControl>
                        <div className="flex-1">
                          <FormLabel className="font-normal leading-none">
                            Survey Form (collecting feedback)
                          </FormLabel>
                          <p className="text-neutral-color-main text-sm mb-0">
                            Allows you to collect feedback from respondents without requiring any
                            approval process.
                          </p>
                        </div>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </form>
        </Form>
      </DrawerContent>

      <DrawerFooter>
        <Button
          disabled={isFormCreateLoading}
          type="submit"
          className="h-[42px] w-[106px]"
          onClick={form.handleSubmit(onSubmit)}
        >
          {isFormCreateLoading ? 'Creating...' : 'Create Form'}
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}

export default AddNewFormModal;

AddNewFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
