import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import Button from '../../../../components/button/button';
import PrintIcon from '../../../../assets/svg-icons/PrintIcon';

export default function FormResponseDetailHeader({ onClickPrint, showPrint, disabledPrint }) {
  const history = useHistory();
  const { id } = useParams();

  const { form: formDetail } = useSelector((state) => state.form);

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-6">
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={faArrowLeft}
          onClick={() => history.push(`/forms/detail/${id}`)}
        />
        <div>
          <h2 className="text-neutral-color-darker text-2xl font-semibold mb-0">
            {formDetail?.title || ''}
          </h2>
        </div>
      </div>

      {showPrint && (
        <Button className="w-[42px] h-[42px]" onClick={onClickPrint} disabled={disabledPrint}>
          <PrintIcon />
        </Button>
      )}
    </div>
  );
}
