import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../components/dialog/dialog';
import Button from '../../../../components/button/button';

export default function DuplicateFormModal({ open, onClose, onDuplicate, isDuplicateLoading }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Duplicate this form?</DialogTitle>
          <DialogDescription>This form will be duplicated.</DialogDescription>
        </DialogHeader>

        <DialogFooter className="gap-2">
          <Button className="w-[100px]" size="large" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="w-[100px]"
            size="large"
            onClick={onDuplicate}
            disabled={isDuplicateLoading}
          >
            {isDuplicateLoading ? 'Duplicating...' : 'Duplicate'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
