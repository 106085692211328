/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CalendarIcon from '../../../../assets/svg-icons/CalendarIcon';
import DownIcon from '../../../../assets/svg-icons/DownIcon';
import { Badge } from '../../../../components/badge/badge';
import Button from '../../../../components/button/button';
import { Checkbox } from '../../../../components/checkbox/checkbox';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../../../../components/command/command';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../components/dialog/dialog';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../components/popover/popover';
import { Role } from '../../../../constants/role';
import {
  resetFormRequestAdvancedFilter,
  setFilterRequesters,
  setFormRequestFilterEndSentOnDate,
  setFormRequestFilterSentOnDateState,
  setFormRequestFilterStartSentOnDate,
} from '../../../../redux/features/forms/formSlice';
import { getTeamsByAuthentication } from '../../../../redux/features/teams/teamSlice';
import { cn } from '../../../../utils/utilClassNames';
import DateRangeFilter from '../../../viewMaintenance/Componets/DateRangeFilter';

function FormRequestResponseAdvancedFilterModal({ open, handleClose, getFormData }) {
  const [showSentOnDate, setShowSentOnDate] = useState(false);

  const {
    filterStartSentOnDate,
    filterEndSentOnDate,
    filterSentOnDateState,
    filterRequesterResponse,
  } = useSelector((state) => state.form);

  const { teams } = useSelector((state) => state.teams);

  const dispatch = useDispatch();

  const handleEraserFilterClick = () => {
    dispatch(resetFormRequestAdvancedFilter());
  };

  const handleActiveAdvFilter = async () => {
    getFormData();
    handleClose();
  };

  const handleUpdateSentOn = async () => {
    setShowSentOnDate(false);
    dispatch(
      setFormRequestFilterStartSentOnDate(
        filterSentOnDateState?.find((item) => item.startDate)?.startDate,
      ),
    );
    dispatch(
      setFormRequestFilterEndSentOnDate(
        filterSentOnDateState?.find((item) => item.endDate)?.endDate,
      ),
    );
  };

  useEffect(() => {
    dispatch(getTeamsByAuthentication());
  }, []);

  const authorOptions = teams?.results?.map((team) => ({
    label: `${team.first_name} ${team.last_name}`,
    description: `${team?.role === Role.Admin ? 'Admin' : team?.role}`,
    value: team.id,
  }));

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent className="w-[480px]">
        <DialogHeader>
          <DialogTitle>Advanced Filter</DialogTitle>
          <DialogDescription>
            <div
              className="wo-filter-wrapper"
              style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            >
              <div className="filter-wrapper">
                <div className="filters-left-wrapper-period">
                  <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>
                    Sent On{' '}
                  </label>
                  <div
                    className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md relative"
                    style={{ fontSize: '14px', cursor: 'pointer' }}
                    onClick={() => setShowSentOnDate(!showSentOnDate)}
                  >
                    {filterStartSentOnDate ? (
                      <>
                        <span>{moment(filterStartSentOnDate).format('DD MMM YYYY')}</span> -{' '}
                        <span>{moment(filterEndSentOnDate).format('DD MMM YYYY')}</span>{' '}
                      </>
                    ) : (
                      'All time'
                    )}

                    <CalendarIcon className="text-neutral-color-darker absolute right-2 top-1/2 transform -translate-y-1/2" />
                  </div>
                  {showSentOnDate && (
                    <>
                      <div
                        className="tck-overlay"
                        onClick={() => setShowSentOnDate(!showSentOnDate)}
                      />
                      <DateRangeFilter
                        show={showSentOnDate}
                        handleUpdate={handleUpdateSentOn}
                        handleClose={handleClose}
                        state={filterSentOnDateState}
                        setState={setFormRequestFilterSentOnDateState}
                        advance
                      />
                    </>
                  )}
                </div>
              </div>

              <div className="filter-wrapper">
                <label style={{ color: '#36383C', fontWeight: 400, fontSize: '14px' }}>
                  Requester
                </label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      role="combobox"
                      className={cn(
                        'w-full justify-between text-sm font-normal min-h-[42px] h-auto flex gap-2 py-2',
                        !filterRequesterResponse.length > 0 && 'text-neutral-color-main',
                      )}
                    >
                      <div className="flex gap-2 flex-wrap">
                        {filterRequesterResponse.length > 0
                          ? filterRequesterResponse?.map((item) => {
                              return (
                                <Badge>
                                  {item.label}
                                  <FontAwesomeIcon
                                    icon={faClose}
                                    className="ml-1"
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      dispatch(
                                        setFilterRequesters(
                                          filterRequesterResponse.filter(
                                            (ele) => ele.value !== item.value,
                                          ),
                                        ),
                                      );
                                    }}
                                  />
                                </Badge>
                              );
                            })
                          : 'Search requester'}
                      </div>
                      <DownIcon className="opacity-50 shrink-0" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    className="PopoverContent w-[430px] p-0 bg-neutral-color-theme-color-light"
                    isPortal={false}
                  >
                    <Command>
                      <CommandInput placeholder="Search staff name" className="h-9" />
                      <CommandList>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <CommandGroup>
                          {authorOptions?.map((author) => (
                            <CommandItem
                              value={author.label}
                              key={author.value}
                              onSelect={() => {
                                // unselect if exist, select if not exist
                                if (
                                  filterRequesterResponse.find((ele) => ele.value === author.value)
                                ) {
                                  dispatch(
                                    setFilterRequesters(
                                      filterRequesterResponse.filter(
                                        (ele) => ele.value !== author.value,
                                      ),
                                    ),
                                  );
                                } else {
                                  dispatch(
                                    setFilterRequesters([...filterRequesterResponse, author]),
                                  );
                                }
                              }}
                            >
                              <div className="flex items-center gap-2">
                                <Checkbox
                                  className="flex-shrink-0"
                                  checked={
                                    !!filterRequesterResponse.find(
                                      (item) => item.value === author.value,
                                    )
                                      ? true
                                      : false
                                  }
                                />

                                <div>
                                  <p className="mb-0">{author.label}</p>
                                  <p className="mb-0 text-neutral-color-neutral-variant-color-dark">
                                    {author.description}
                                  </p>
                                </div>
                              </div>
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
            </div>
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="gap-2 justify-between">
          <Button variant="ghost" className="w-[80px] h-[42px]" onClick={handleEraserFilterClick}>
            Reset
          </Button>
          <div className="flex gap-3">
            <Button variant="outline" className="w-[80px] h-[42px]" onClick={handleClose}>
              Close
            </Button>
            <Button className="w-[80px] h-[42px]" onClick={handleActiveAdvFilter}>
              Activate
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default FormRequestResponseAdvancedFilterModal;
