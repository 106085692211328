import React from 'react';
import { useFormContext } from 'react-hook-form';

import FormPreviewHeader from '../FormPreview/FormPreviewHeader';
import TextInput from '../../../../components/input/textInput';
import MailIcon from '../../../../assets/svg-icons/MailIcon';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../components/form/form';

export default function FormSurveySecureAccessPart2() {
  const form = useFormContext();

  return (
    <div className="space-y-6">
      <FormPreviewHeader />

      <div className="bg-white rounded-2xl px-10 mx-auto">
        <div className="space-y-[10px] py-4">
          <div>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="mb-2" isRequired>
                    Email
                  </FormLabel>
                  <FormControl>
                    <TextInput
                      variant="filled"
                      prefix={<MailIcon />}
                      placeholder="Enter the registered email"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
