/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import { useDetailFormSurveyResponsesFilter } from '../../hooks/useDetailFormSurveyResponsesFilter';
import FormSurveyDetailResponseActivitiesTab from './FormSurveyDetailResponseActivitiesTab';
import FormSurveyDetailResponsesHeader from './FormSurveyDetailResponsesHeader';
import FormSurveyDetailResponsesTabResponse from './FormSurveyDetailResponsesTabResponse';
import FormSurveyDetailResponseSummaryTab from './FormSurveyDetailResponseSummaryTab';
import DeleteFormModal from '../Modal/DeleteFormModal';

import {
  bulkDeleteFormResponseByIds,
  deleteFormResponseById,
  getFormResponseListById,
} from '../../../../redux/features/forms/formSlice';
import BulkDeleteFormModal from '../Modal/BulkDeleteFormModal';

export default function FormSurveyDetailResponses() {
  const [selectedTab, setSelectedTab] = useState('Responses');
  const [isDeleteFormModalOpen, setIsDeleteFormModalOpen] = useState(false);
  const [isBulkDeleteFormModalOpen, setIsBulkDeleteFormModalOpen] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const { allColumns, filteredColumns, selectedFilter, setSelectedFilter } =
    useDetailFormSurveyResponsesFilter({
      setIsDeleteFormModalOpen,
    });

  const { id } = useParams();

  const dispatch = useDispatch();

  const {
    formResponseList,
    isFormResponseDeleteLoading,
    isFormResponseBulkDeleteByIdsLoading,
    currentPageResponse,
    rowPerPageResponse,
    selectedFormResponseId,
  } = useSelector((state) => state.form);

  const getFormData = () => {
    // TODO
    dispatch(
      getFormResponseListById({
        form_id: id,
        page: currentPageResponse,
        page_size: rowPerPageResponse,
      }),
    );
  };

  const onDeleteFormResponseById = async () => {
    try {
      const result = await dispatch(deleteFormResponseById(selectedFormResponseId));
      unwrapResult(result);
      toast.success('Form response deleted successfully');
      getFormData();
      setIsDeleteFormModalOpen(false);
    } catch (error) {
      toast.error('Error deleting form response');
    }
  };

  const onBulkDeleteFormResponseByIds = async () => {
    const formResponseIds = selectedRows.map((row) => row.form_response_id);

    try {
      const result = await dispatch(bulkDeleteFormResponseByIds({ formResponseIds }));
      unwrapResult(result);
      toast.success('Selected form responses deleted successfully');
      getFormData();
      setIsBulkDeleteFormModalOpen(false);
    } catch (error) {
      toast.error('Error deleting selected form responses');
    }
  };

  useEffect(() => {
    dispatch(getFormResponseListById({ form_id: id }));
  }, [id]);

  return (
    <div>
      <FormSurveyDetailResponsesHeader
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        allColumns={allColumns}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        selectedForms={selectedRows}
        onOpenBulkDelete={() => setIsBulkDeleteFormModalOpen(true)}
      />

      <div className="mt-2 pb-40">
        {selectedTab === 'Responses' && (
          <FormSurveyDetailResponsesTabResponse
            data={formResponseList.results || []}
            filteredColumns={filteredColumns}
            setSelectedRows={setSelectedRows}
          />
        )}

        {selectedTab === 'Summary' && <FormSurveyDetailResponseSummaryTab />}

        {selectedTab === 'Activities' && <FormSurveyDetailResponseActivitiesTab />}

        <DeleteFormModal
          open={isDeleteFormModalOpen}
          onClose={() => setIsDeleteFormModalOpen(false)}
          onDelete={onDeleteFormResponseById}
          isDeleteLoading={isFormResponseDeleteLoading}
        />

        <BulkDeleteFormModal
          open={isBulkDeleteFormModalOpen}
          onClose={() => setIsBulkDeleteFormModalOpen(false)}
          onDelete={onBulkDeleteFormResponseByIds}
          isDeleting={isFormResponseBulkDeleteByIdsLoading}
        />
      </div>
    </div>
  );
}
