import React from 'react';

export default function TimeCircleIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={92} height={93} fill="none" {...props}>
      <g fill="currentColor" clipPath="url(#a)">
        <path d="m50.114 46.5 12.42-12.42a2.556 2.556 0 0 0-3.603-3.603l-12.42 12.292-12.497-12.497a2.556 2.556 0 0 0-3.603 3.604L42.882 46.5 30.667 58.562a2.556 2.556 0 1 0 3.603 3.604l12.19-12.19 12.113 12.113a2.556 2.556 0 0 0 3.604-3.603L50.114 46.5Z" />
        <path d="M46 87.39A40.889 40.889 0 1 1 46 5.61a40.889 40.889 0 0 1 0 81.778Zm0-76.668a35.778 35.778 0 1 0 0 71.556 35.778 35.778 0 0 0 0-71.556Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 .5h92v92H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
