/* eslint-disable import/prefer-default-export */
import * as React from 'react';

import { cn } from '../../utils/utilClassNames';

const Checkbox = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <input
      ref={ref}
      type="checkbox"
      className={cn('accent-neutral-color-darker w-[14px] h-[14px] rounded-[4px]', className)}
      {...props}
    />
  );
});

export { Checkbox };
