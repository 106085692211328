import CheckboxIcon from '../../../../assets/svg-icons/CheckBoxIcon';
import LongTextIcon from '../../../../assets/svg-icons/LongTextIcon';
import RadioIcon from '../../../../assets/svg-icons/RadioIcon';
import ShortTextIcon from '../../../../assets/svg-icons/ShortTextIcon';
import DownIcon from '../../../../assets/svg-icons/DownIcon';
import PhotoCameraIcon from '../../../../assets/svg-icons/PhotoCameraIcon';
import CalendarIcon from '../../../../assets/svg-icons/CalendarIcon';
import UploadPlusIcon from '../../../../assets/svg-icons/UploadPlusIcon';
import TimeIcon from '../../../../assets/svg-icons/TimeIcon';
import TableIcon from '../../../../assets/svg-icons/TableIcon';
import StarIcon from '../../../../assets/svg-icons/StarIcon';
import { FieldType } from './constant';

export const answerRequestSelectOption = [
  {
    label: 'Short Text',
    value: FieldType.ShortText,
    icon: ShortTextIcon,
  },
  {
    label: 'Long Text',
    value: FieldType.LongText,
    icon: LongTextIcon,
  },
  {
    label: 'Checkboxes',
    value: FieldType.Checkboxes,
    icon: CheckboxIcon,
  },
  {
    label: 'Multiple Choice',
    value: FieldType.MultipleChoices,
    icon: RadioIcon,
  },
  {
    label: 'Dropdown',
    value: FieldType.Dropdown,
    icon: DownIcon,
  },
  {
    label: 'Image',
    value: FieldType.Image,
    icon: PhotoCameraIcon,
  },
  {
    label: 'Date',
    value: FieldType.Date,
    icon: CalendarIcon,
  },
  {
    label: 'File Upload',
    value: FieldType.FileUpload,
    icon: UploadPlusIcon,
  },
  {
    label: 'Time',
    value: FieldType.Time,
    icon: TimeIcon,
  },
  {
    label: 'Table',
    value: FieldType.Table,
    icon: TableIcon,
  },
  {
    label: 'Rating',
    value: FieldType.Rating,
    icon: StarIcon,
  },
];

export const answerSurveySelectOption = [
  {
    label: 'Short Text',
    value: FieldType.ShortText,
    icon: ShortTextIcon,
  },
  {
    label: 'Long Text',
    value: FieldType.LongText,
    icon: LongTextIcon,
  },
  {
    label: 'Checkboxes',
    value: FieldType.Checkboxes,
    icon: CheckboxIcon,
  },
  {
    label: 'Multiple Choice',
    value: FieldType.MultipleChoices,
    icon: RadioIcon,
  },
  {
    label: 'Dropdown',
    value: FieldType.Dropdown,
    icon: DownIcon,
  },
  {
    label: 'Date',
    value: FieldType.Date,
    icon: CalendarIcon,
  },
  {
    label: 'Time',
    value: FieldType.Time,
    icon: TimeIcon,
  },
  {
    label: 'Rating',
    value: FieldType.Rating,
    icon: StarIcon,
  },
];

export const tableSelectOption = [
  {
    label: 'Short Text',
    value: FieldType.ShortText,
    icon: ShortTextIcon,
  },
  {
    label: 'Long Text',
    value: FieldType.LongText,
    icon: LongTextIcon,
  },
];
