export const FormStatus = {
  Draft: 'draft',
  Published: 'published',
};

export const FormType = {
  Request: 'request',
  Survey: 'survey',
};

export const FieldType = {
  ShortText: 'short_text',
  LongText: 'long_text',
  Checkboxes: 'checkboxes',
  MultipleChoices: 'multiple_choice',
  Dropdown: 'dropdown',
  Image: 'image',
  Date: 'date',
  FileUpload: 'file_upload',
  Time: 'time',
  Table: 'table',
  Rating: 'rating',
};

export const FormResponseStatus = {
  NewRequest: 'New Request',
  AwaitingApproval: 'Awaiting Approval',
  Approved: 'Approved',
  Cancelled: 'Cancelled',
  Closed: 'Closed',
  Draft: 'Draft',
};

export const FormResponseAction = {
  Extension: 'Extension',
  Cancellation: 'Cancellation',
  Closing: 'Closing',
};
