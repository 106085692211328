import React from 'react';

function TrashIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill="currentColor"
        d="m12.596 12.024 1 .008-1-.008ZM2.668 3a1 1 0 1 0 0 2V3Zm10.667 2a1 1 0 1 0 0-2v2ZM7.668 6.666a1 1 0 0 0-2 0h2Zm-2 5.334a1 1 0 1 0 2 0h-2Zm4.667-5.334a1 1 0 1 0-2 0h2Zm-2 5.334a1 1 0 1 0 2 0h-2Zm3.333-8.01-.072 8.025 2 .017.072-8.023-2-.018ZM9.93 13.667H6v2H9.93v-2ZM2.335 4v8h2V4h-2Zm.333 1h.667V3h-.667v2Zm.667 0h2V3h-2v2Zm2 0h5.333V3H5.335v2Zm5.333 0h2V3h-2v2Zm2 0h.667V3h-.667v2ZM6.335 3.703c0-.649.632-1.37 1.666-1.37v-2c-1.91 0-3.666 1.401-3.666 3.37h2ZM8 2.333c1.035 0 1.667.721 1.667 1.37h2c0-1.968-1.756-3.37-3.667-3.37v2Zm-3.666 1.37V4h2v-.297h-2Zm5.333 0V4h2v-.297h-2Zm-3.667 9.963c-.92 0-1.666-.746-1.666-1.666h-2A3.667 3.667 0 0 0 6 15.666v-2Zm5.595-1.651a1.667 1.667 0 0 1-1.666 1.651v2a3.667 3.667 0 0 0 3.666-3.634l-2-.017ZM5.668 6.666V12h2V6.666h-2Zm2.667 0V12h2V6.666h-2Z"
      />
    </svg>
  );
}

export default TrashIcon;
