import React from 'react';

import FormRequestDetailResponses from './FormRequestDetailResponses';
import FormSurveyDetailResponses from './FormSurveyDetailResponses';
import { FormType } from '../../config/forms/constant';

export default function FormDetailResponses({ type }) {
  return (
    <div>
      {type === FormType.Request ? <FormRequestDetailResponses /> : <FormSurveyDetailResponses />}
    </div>
  );
}
