/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import { yupResolver } from '@hookform/resolvers/yup';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../../components/button/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../components/dialog/dialog';
import Drawer from '../../../../components/drawer/drawer';
import DrawerContent from '../../../../components/drawer/drawerContent';
import DrawerFooter from '../../../../components/drawer/drawerFooter';
import DrawerHeader from '../../../../components/drawer/drawerHeader';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../components/form/form';
import DatepickerInput from '../../../../components/input/datepickerInput';
import { RadioGroup, RadioGroupItem } from '../../../../components/input/radioInput';
import SignatureInput from '../../../../components/input/signatureInput';
import TextArea from '../../../../components/input/textArea';
import toast from '../../../../components/toast/toastUtils';
import {
  cancelFormResponse,
  closeFormResponse,
  extendFormResponse,
  getFormResponseDetailById,
  setSelectedFormResponseId,
} from '../../../../redux/features/forms/formSlice';
import { FormResponseAction } from '../../config/forms/constant';
import { takeActionFormSchema } from '../../config/forms/validation';

function TakeActionResponseFormModal({ open, onClose, getFormData }) {
  const [actionModalOpen, setActionModalOpen] = React.useState(false);

  const form = useForm({
    resolver: yupResolver(takeActionFormSchema),
    mode: 'onChange',
  });

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { selectedFormResponseId, formResponseDetail } = useSelector((state) => state.form);

  async function handleSaveAction() {
    const isValid = await form.trigger('signature');

    if (!isValid) return;

    try {
      if (form.watch('action') === FormResponseAction.Extension) {
        const resultAction = await dispatch(
          extendFormResponse({
            form_response_id: selectedFormResponseId,
            decision: 'extend',
            approver_signatures: [
              {
                id: user.id,
                signature: form.watch('signature'),
              },
            ],
            comment: 'Form response due date extended by manager',
            extension_date: form.watch('extension_date'),
          }),
        );
        unwrapResult(resultAction);
      } else if (form.watch('action') === FormResponseAction.Closing) {
        const resultAction = await dispatch(
          closeFormResponse({
            form_response_id: selectedFormResponseId,
            decision: 'close',
            approver_signatures: [
              {
                id: user.id,
                signature: form.watch('signature'),
              },
            ],
            comment: 'close by manager',
          }),
        );
        unwrapResult(resultAction);
      } else if (form.watch('action') === FormResponseAction.Cancellation) {
        const resultAction = await dispatch(
          cancelFormResponse({
            form_response_id: selectedFormResponseId,
            decision: 'cancel',
            approver_signatures: [
              {
                id: user.id,
                signature: form.watch('signature'),
              },
            ],
            comment: 'Cancel by admin',
          }),
        );
        unwrapResult(resultAction);
      }

      onClose();
      getFormData?.();
      form.reset({
        reason: '',
        action: null,
        signature: '',
        extension_date: null,
      });
      setActionModalOpen(false);
      toast.success('Action saved!');
    } catch (error) {
      toast.error('Failed to save action');
    }
  }

  const handleClose = () => {
    onClose();
    dispatch(setSelectedFormResponseId(null));
  };

  useEffect(() => {
    if (selectedFormResponseId) {
      dispatch(getFormResponseDetailById(selectedFormResponseId));
    }
  }, [selectedFormResponseId]);

  useEffect(() => {
    form.reset({
      start_date: formResponseDetail?.start_time,
      end_date: formResponseDetail?.end_time,
    });
  }, [formResponseDetail, open]);
  return (
    <Form {...form}>
      <form className="space-y-4">
        <Drawer
          anchor="right"
          open={open}
          onClose={onClose}
          PaperProps={{
            className: 'w-full max-w-[600px] h-full',
          }}
        >
          <DrawerHeader title="Actions" onClose={handleClose} />

          <DrawerContent>
            <div className="grid grid-cols-2 gap-2">
              <FormField
                control={form.control}
                name="start_date"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel isRequired>Start date</FormLabel>

                    <FormControl>
                      <DatepickerInput
                        value={field.value ? new Date(field.value) : undefined}
                        showSuffix
                        suffixClassName="text-neutral-color-main"
                        className="flex-1 w-full"
                        onChange={field.onChange}
                        disabled
                        placeholder="Not set"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="end_date"
                render={({ field }) => (
                  <FormItem className="flex-1">
                    <FormLabel isRequired>End date</FormLabel>

                    <FormControl>
                      <DatepickerInput
                        value={field.value ? new Date(field.value) : undefined}
                        showSuffix
                        disabled
                        suffixClassName="text-neutral-color-main"
                        className="flex-1 w-full"
                        onChange={field.onChange}
                        placeholder="Not set"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="action"
              render={({ field }) => (
                <FormItem className="space-y-3 mt-4">
                  <FormLabel isRequired>Actions</FormLabel>
                  <FormControl>
                    <RadioGroup>
                      <FormItem className="flex items-start space-x-3 space-y-0 border border-neutral-color-divider rounded-[8px] p-3">
                        <FormControl>
                          <RadioGroupItem
                            className="mt-1"
                            value={FormResponseAction.Extension}
                            checked={field.value === FormResponseAction.Extension}
                            onChange={(value) => {
                              field.onChange(value);
                            }}
                          />
                        </FormControl>
                        <div className="w-full">
                          <FormLabel className="font-normal leading-none">Extension</FormLabel>
                          <p className="text-neutral-color-main text-sm mb-2">
                            due date for extend, reason, signature optional
                          </p>
                          {form.watch('action') === FormResponseAction.Extension && (
                            <>
                              <FormField
                                control={form.control}
                                name="extension_date"
                                render={({ field: fieldExtension }) => (
                                  <FormItem className="flex-1">
                                    <FormLabel isRequired>Extension date</FormLabel>

                                    <FormControl>
                                      <DatepickerInput
                                        isPortal={false}
                                        showSuffix
                                        suffixClassName="text-neutral-color-main"
                                        className="flex-1 w-full !bg-[#FFFFFF]"
                                        onChange={(value) => {
                                          fieldExtension.onChange(value);
                                          form.setValue('extenstion_date', undefined);
                                          form.setValue('reason', '');
                                        }}
                                        placeholder="Not set"
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />

                              <FormField
                                control={form.control}
                                name="reason"
                                render={({ field: fieldReason }) => (
                                  <FormItem className="mt-2">
                                    <FormLabel isRequired>Reason</FormLabel>
                                    <FormControl>
                                      <TextArea
                                        className="!h-[100px]"
                                        placeholder="input reason"
                                        {...fieldReason}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </>
                          )}
                        </div>
                      </FormItem>

                      <FormItem className="flex items-start space-x-3 space-y-0 border border-neutral-color-divider rounded-[8px] p-3">
                        <FormControl>
                          <RadioGroupItem
                            className="mt-1"
                            value={FormResponseAction.Cancellation}
                            checked={field.value === FormResponseAction.Cancellation}
                            onChange={(value) => {
                              field.onChange(value);
                              form.setValue('extenstion_date', undefined);
                              form.setValue('reason', '');
                            }}
                          />
                        </FormControl>
                        <div className="w-full">
                          <FormLabel className="font-normal leading-none">Cancellation</FormLabel>
                          <p className="text-neutral-color-main text-sm mb-2">
                            (confirm to cancel) , reason, signature optional
                          </p>
                          {form.watch('action') === FormResponseAction.Cancellation && (
                            <FormField
                              control={form.control}
                              name="reason"
                              render={({ field: fieldReason }) => (
                                <FormItem className="mt-2">
                                  <FormLabel isRequired>Reason</FormLabel>
                                  <FormControl>
                                    <TextArea
                                      className="!h-[100px]"
                                      placeholder="input reason"
                                      {...fieldReason}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          )}
                        </div>
                      </FormItem>

                      <FormItem className="flex items-start space-x-3 space-y-0 border border-neutral-color-divider rounded-[8px] p-3">
                        <FormControl>
                          <RadioGroupItem
                            className="mt-1"
                            value={FormResponseAction.Closing}
                            checked={field.value === FormResponseAction.Closing}
                            onChange={(value) => {
                              field.onChange(value);
                              form.setValue('extenstion_date', undefined);
                              form.setValue('reason', '');
                            }}
                          />
                        </FormControl>
                        <div className="w-full">
                          <FormLabel className="font-normal leading-none">Closing</FormLabel>
                          <p className="text-neutral-color-main text-sm mb-2">
                            (confirm to close), signature optional
                          </p>
                        </div>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </DrawerContent>

          <DrawerFooter className="bg-[#FFFFFF]">
            <Button
              className="h-[42px] min-w-[61px]"
              onClick={async () => {
                const isValid = await form.trigger([
                  'action',
                  'start_date',
                  'end_date',
                  'extension_date',
                  'reason',
                ]);
                if (isValid) {
                  setActionModalOpen(true);
                }
              }}
            >
              Save
            </Button>
          </DrawerFooter>
        </Drawer>

        <Dialog
          open={actionModalOpen}
          onClose={() => {
            form.setValue('signature', '');
            setActionModalOpen(false);
          }}
        >
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Save action?</DialogTitle>
              <DialogDescription>
                <p className="text-[#757980] text-sm font-normal">
                  This change will affect the form's status.
                </p>
                <FormField
                  control={form.control}
                  name="signature"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <SignatureInput onChange={field.onChange} height={150} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </DialogDescription>
            </DialogHeader>

            <DialogFooter className="gap-2">
              <Button
                className="w-[100px]"
                size="large"
                variant="outline"
                onClick={() => {
                  setActionModalOpen(false);
                  form.setValue('signature', '');
                }}
              >
                Cancel
              </Button>
              <Button className="w-[100px]" size="large" onClick={handleSaveAction}>
                Save
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </form>
    </Form>
  );
}

export default TakeActionResponseFormModal;
