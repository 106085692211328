/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useParams } from 'react-router-dom';
import { FRONT_URL } from '../../../../utils/axios';

export default function FormQR({ qrCodeUrl }) {
  const { id } = useParams();

  const handleCopy = () => {
    const url = `${FRONT_URL}/forms/submit-form/${id}/detail`;

    navigator.clipboard.writeText(url);
  };

  return (
    <div className="bg-white rounded-2xl py-[42px] px-4 mx-auto flex flex-col items-center text-center justify-center">
      <img src={qrCodeUrl} alt="qrcode" />
      <p className="text-red-600 mb-0 cursor-pointer" onClick={handleCopy}>
        Copy the link
      </p>
      <div>
        <p className="mb-0 font-semibold text-neutral-color-darker text-sm mt-4">
          Scan to fill the form
        </p>
        <p className="mb-0 text-neutral-color-main text-sm">by Infinit Wave IO</p>
      </div>
    </div>
  );
}
