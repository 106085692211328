import React from 'react';

export default function EyeOffIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill="currentColor"
        d="m1.94 7.218.909.418-.909-.418Zm12.122 1.563-.91-.418.91.418Zm-12.122 0-.908.417.908-.417Zm12.122-1.563-.91.418.91-.418Zm-1.902-3.66a1 1 0 1 0-1.086 1.68l1.086-1.68Zm-7.205 7.22a1 1 0 1 0-1.077 1.686l1.077-1.686Zm-3.087-.177a1 1 0 0 0 1.6-1.202l-1.6 1.202Zm7.267-6.155a1 1 0 0 0 .398-1.96l-.398 1.96Zm4.906-1.073a1 1 0 0 0-1.414-1.414l1.414 1.414Zm-12.08 9.253a1 1 0 1 0 1.414 1.414L1.96 12.626ZM8 2.333A7.668 7.668 0 0 0 1.03 6.8l1.818.835A5.668 5.668 0 0 1 8 4.333v-2Zm0 11.333a7.668 7.668 0 0 0 6.97-4.468l-1.818-.835A5.668 5.668 0 0 1 8 11.666v2ZM1.03 6.801c-.349.76-.349 1.637 0 2.397l1.818-.835a.872.872 0 0 1 0-.727L1.032 6.8Zm12.122.835a.872.872 0 0 1 0 .727l1.817.835c.35-.76.35-1.637 0-2.397l-1.817.835ZM14.97 6.8a7.695 7.695 0 0 0-2.81-3.243l-1.086 1.68a5.694 5.694 0 0 1 2.079 2.398L14.97 6.8ZM3.878 12.464A7.635 7.635 0 0 0 8 13.666v-2a5.635 5.635 0 0 1-3.046-.888l-1.077 1.686ZM1.032 9.198c.229.5.51.969.836 1.403l1.6-1.202a5.664 5.664 0 0 1-.62-1.036l-1.816.835Zm8.501-6.712a7.697 7.697 0 0 0-1.532-.153v2c.39 0 .769.039 1.134.113l.398-1.96ZM5.88 10.121a3 3 0 0 0 4.242 0L8.708 8.706a1 1 0 0 1-1.414 0L5.88 10.121Zm4.242 0a3 3 0 0 0 0-4.243L8.708 7.292a1 1 0 0 1 0 1.414l1.414 1.415Zm2.505-8.162L1.96 12.626l1.415 1.414L14.04 3.373 12.627 1.96Z"
      />
    </svg>
  );
}
