/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-else-return */
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import Label from '../../../../components/label/label';
import { FieldType } from '../../config/forms/constant';
import { FormControl, FormField, FormItem, FormMessage } from '../../../../components/form/form';
import TextInput from '../../../../components/input/textInput';
import TextArea from '../../../../components/input/textArea';
import { Checkbox } from '../../../../components/checkbox/checkbox';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../../components/select/selectV2';
import UploadInput from '../../../../components/input/uploadInput';
import DatepickerInput from '../../../../components/input/datepickerInput';
import TimeInput from '../../../../components/input/timeInput';
import StarIcon from '../../../../assets/svg-icons/StarIcon';
import { cn } from '../../../../utils/utilClassNames';
import Table from '../../../../components/crudTable/crudTable';
import Button from '../../../../components/button/button';
import PlusIcon from '../../../../assets/svg-icons/PlusIcon';
import { RadioGroup, RadioGroupItem } from '../../../../components/input/radioInput';

export default function FormPreviewSection({ currentIndex, data }) {
  const form = useFormContext();
  const currentSection = data?.forms?.[currentIndex];

  return (
    <div className="rounded-2xl bg-white mx-auto overflow-hidden">
      <div className="bg-tnb-psd-color-blue-main px-4">
        <p className="text-white font-semibold text-sm py-[2px]">
          Section {currentIndex + 1} of {data.forms?.length}
        </p>
      </div>

      <div className="bg-white p-6">
        <div className="bg-neutral-color-aplha-neutral py-3 px-2">
          <p className="mb-0 text-neutral-color-darker font-semibold text-lg">
            {currentSection?.title}
          </p>
          <p className="text-neutral-color-dark text-sm mb-0">{currentSection?.description}</p>
        </div>

        <div className="mt-3">
          {currentSection?.items?.map((item) => {
            const name = `forms.${currentIndex}.${item.question}`;

            return (
              <div key={item.order} className="mt-4">
                <Label isRequired={item.isRequired} className="mb-2">
                  {item.question}
                </Label>

                {(() => {
                  if (item.answer === FieldType.ShortText) {
                    return (
                      <FormField
                        control={form.control}
                        name={name}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <TextInput placeholder="Your answer" {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    );
                  } else if (item.answer === FieldType.LongText) {
                    return (
                      <FormField
                        control={form.control}
                        name={name}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <TextArea
                                placeholder="Your answer"
                                className="!h-[126px]"
                                onChange={field.onChange}
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    );
                  } else if (item.answer === FieldType.Checkboxes) {
                    const checkboxOptions = item.content || [];
                    return (
                      <FormField
                        control={form.control}
                        name={name}
                        render={() => (
                          <FormItem>
                            {checkboxOptions.map((option, idx) => (
                              <FormField
                                key={idx}
                                control={form.control}
                                name={name}
                                render={({ field }) => {
                                  return (
                                    <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                                      <FormControl>
                                        <Checkbox
                                          checked={field.value?.includes(option.text)}
                                          onChange={(e) => {
                                            const checked = e.target.checked;
                                            return checked
                                              ? field.onChange([
                                                  ...(field.value || []),
                                                  option.text,
                                                ])
                                              : field.onChange(
                                                  field.value?.filter(
                                                    (value) => value !== option.text,
                                                  ),
                                                );
                                          }}
                                        />
                                      </FormControl>
                                      <Label className="text-sm font-normal">{option.text}</Label>
                                    </FormItem>
                                  );
                                }}
                              />
                            ))}
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    );
                  } else if (item.answer === FieldType.MultipleChoices) {
                    const radioOptions = item.content || [];
                    return (
                      <FormField
                        control={form.control}
                        name={name}
                        render={() => (
                          <FormItem>
                            {radioOptions.map((option, idx) => (
                              <FormField
                                key={idx}
                                control={form.control}
                                name={name}
                                render={({ field }) => {
                                  return (
                                    <FormItem className="space-y-3">
                                      <FormControl>
                                        <RadioGroup className="flex justify-between">
                                          <FormItem className="flex items-start space-x-3 space-y-0">
                                            <FormControl>
                                              <RadioGroupItem
                                                value={option.text}
                                                checked={field.value === option.text}
                                                onChange={(e) => {
                                                  console.log(e.target.value);
                                                  field.onChange(e.target.value);
                                                }}
                                              />
                                            </FormControl>
                                            <div>
                                              <Label className="font-normal leading-none">
                                                {option.text}
                                              </Label>
                                            </div>
                                          </FormItem>
                                        </RadioGroup>
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  );
                                }}
                              />
                            ))}
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    );
                  } else if (item.answer === FieldType.Dropdown) {
                    const dropdownOptions = item.content || [];
                    return (
                      <FormField
                        control={form.control}
                        name={name}
                        render={({ field }) => (
                          <FormItem>
                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                              <FormControl>
                                <SelectTrigger className="max-w-[376px] !bg-[#ffffff]">
                                  <SelectValue placeholder="Select" />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {dropdownOptions.map((option, idx) => (
                                  <SelectItem value={option.text} key={idx}>
                                    {option.text}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    );
                  } else if (item.answer === FieldType.Image) {
                    return (
                      <FormField
                        control={form.control}
                        name={name}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <UploadInput
                                title="Choose/drop file max 5MB
                      jpg/png format"
                                onChange={(acceptedFiles) => {
                                  field.onChange(acceptedFiles);
                                }}
                                // only accept jpg and png
                                accept=".jpg,.jpeg,.png"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    );
                  } else if (item.answer === FieldType.Date) {
                    return (
                      <FormField
                        control={form.control}
                        name={name}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <DatepickerInput
                                showSuffix
                                value={field.value}
                                onChange={field.onChange}
                                className="bg-white max-w-[376px]"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    );
                  } else if (item.answer === FieldType.Time) {
                    return (
                      <FormField
                        control={form.control}
                        name={name}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <TimeInput
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                                className="bg-white max-w-[90px]"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    );
                  } else if (item.answer === FieldType.Rating) {
                    const rating = item.content || [];
                    const ratingOptions = Array.from({ length: rating[0].text }, (_, i) => i + 1);

                    return (
                      <FormField
                        control={form.control}
                        name={name}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <RatingInput field={field} ratingOptions={ratingOptions} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    );
                  } else if (item.answer === FieldType.FileUpload) {
                    return (
                      <FormField
                        control={form.control}
                        name={name}
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <UploadInput
                                title="Choose/drop file max 5MB .pdf format"
                                onChange={(acceptedFiles) => {
                                  field.onChange(acceptedFiles);
                                }}
                                // only accept pdf
                                accept=".pdf"
                                type="file"
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    );
                  } else if (item.answer === FieldType.Table) {
                    return (
                      <TableInput
                        currentIndex={currentIndex}
                        columns={item.content || []}
                        name={item.question}
                      />
                    );
                  } else {
                    return null;
                  }
                })()}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function RatingInput({ ratingOptions, field }) {
  const [hoveredStar, setHoveredStar] = useState(0);
  const [selectedStar, setSelectedStar] = useState(0);

  const handleMouseOver = (index) => {
    setHoveredStar(index);
  };

  const handleMouseOut = () => {
    setHoveredStar(0);
  };

  const handleClick = (index) => {
    setSelectedStar(index);
  };

  return (
    <div className="flex items-start gap-6 text-center text-sm h-fit">
      {ratingOptions.map((option, idx) => {
        return (
          <div
            key={option}
            onClick={() => {
              handleClick(idx + 1);
              field.onChange(idx + 1);
            }}
            onMouseOver={() => handleMouseOver(idx + 1)}
            onMouseOut={handleMouseOut}
          >
            <StarIcon
              className={cn(
                'cursor-pointer',
                idx < (hoveredStar || selectedStar)
                  ? 'text-generic-color-alpha-orange-92 fill-generic-color-alpha-orange-92'
                  : '',
              )}
            />
            <p className="mb-0">{option}</p>
          </div>
        );
      })}
    </div>
  );
}

function TableInput({ currentIndex, columns, name }) {
  const form = useFormContext();

  const { fields, append } = useFieldArray({
    control: form.control,
    name: `forms.${currentIndex}.${name}`,
  });

  const initial = columns.reduce((acc, item) => {
    acc[item.text] = ''; // Use the name field as the key, and assign an empty string as the value
    return acc;
  }, {});

  const onAddRow = () => {
    append(initial);
  };

  const mappedColumns = React.useMemo(
    () =>
      columns.map((column) => {
        return {
          Header: column.text,
          accessor: column.text,
          Cell: ({ row }) => {
            return (
              <FormField
                control={form.control}
                name={`forms.${currentIndex}.${name}.${row.index}.${column.text}`}
                render={({ field }) => {
                  if (column.type === FieldType.ShortText) {
                    return (
                      <FormItem>
                        <FormControl>
                          <TextInput
                            variant="ghost"
                            placeholder="Your answer"
                            {...field}
                            onChange={field.onChange}
                            value={field.value || ''}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  } else if (column.type === FieldType.LongText) {
                    return (
                      <FormItem>
                        <FormControl>
                          <TextInput
                            variant="ghost"
                            {...field}
                            placeholder="Your answer"
                            onChange={field.onChange}
                            value={field.value || ''}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  } else {
                    return null;
                  }
                }}
              />
            );
          },
        };
      }),
    [currentIndex],
  );

  useEffect(() => {
    onAddRow();
  }, []);

  return (
    <>
      <Table
        columns={mappedColumns}
        data={fields.map((field) => {
          return {
            name: field.name || 'Your Answer',
            phoneNumber: field.phoneNumber || 'Your Answer',
          };
        })}
        onRowClick={() => {}}
      />
      <Button variant="outline" className="w-[139px] h-[42px]" onClick={onAddRow}>
        <PlusIcon /> Add new row
      </Button>
    </>
  );
}
