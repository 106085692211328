import React, { useState } from 'react';
import Tabs from '../../../../components/tabs/tabs';

function FormListTab() {
  const [selectedTab, setSelectedTab] = useState('listOfForm');

  return (
    <Tabs
      tabList={[
        {
          label: 'List of form',
          value: 'listOfForm',
          onClick: (tab) => {
            setSelectedTab(tab);
          },
        },
        {
          label: 'Templates',
          value: 'templates',
          disabled: true,
          onClick: (tab) => {
            setSelectedTab(tab);
          },
        },
      ]}
      selectedTab={selectedTab}
      showTooltip={false}
    />
  );
}

export default FormListTab;
