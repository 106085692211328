import React from 'react';
import classes from './summaryCards.module.css';

function SummaryCards({ title, count, color }) {
  return (
    <div className={classes.summaryCard}>
      <h3>{count}</h3>
      <div className='d-flex align-items-center gap-2'>
        {color && <span className={classes.cardStatus} style={{ backgroundColor: color }} />}
        <h4>{title}</h4>
      </div>
    </div>
  );
}

export default SummaryCards;
