import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SurveyFormDetailResponseModal from '../Modal/SurveyFormDetailResponseModal';
import Table from '../../../../components/crudTable/crudTable';
import Pagination from '../../../../components/pagination/pagination';
import {
  setCurrentPageResponse,
  setRowPerPageResponse,
  setSelectedFormResponseId,
} from '../../../../redux/features/forms/formSlice';

export default function FormSurveyDetailResponsesTabResponse({
  filteredColumns,
  data,
  setSelectedRows,
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useDispatch();

  const onRowClick = (formResponseId) => {
    dispatch(setSelectedFormResponseId(formResponseId));
    setIsOpen(true);
  };

  const { formResponseList, rowPerPageResponse, currentPageResponse } = useSelector(
    (state) => state.form,
  );

  return (
    <>
      <Table
        columns={filteredColumns}
        data={data}
        onRowClick={(row) => {
          onRowClick(row.form_response_id);
        }}
        onSelectionChange={(selectedFlatRows) => {
          setSelectedRows(selectedFlatRows);
        }}
      />
      <SurveyFormDetailResponseModal open={isOpen} onClose={() => setIsOpen(false)} />

      <div className="h-[1px] w-full bg-neutral-color-divider mb-4" />
      <Pagination
        totalRows={formResponseList.count}
        rowPerPage={rowPerPageResponse}
        pageChangeHandler={setCurrentPageResponse}
        changeRowsPerPage={setRowPerPageResponse}
        currentPage={currentPageResponse}
      />
    </>
  );
}
