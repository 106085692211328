import React from 'react';

export default function SectionAddIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        stroke="currentColor"
        strokeWidth={0.5}
        d="M0 2a2 2 0 0 1 2-2h3.333v1.333H2A.667.667 0 0 0 1.333 2v3.333H0V2Zm12-.667H8V0h4v1.333Zm6 0h-3.333V0H18a2 2 0 0 1 2 2v3.333h-1.333V2A.667.667 0 0 0 18 1.333Zm-8.667 8v-4h1.334v4h4v1.334h-4v4H9.333v-4h-4V9.333h4ZM0 12V8h1.333v4H0Zm18.667 0V8H20v4h-1.333ZM0 18v-3.333h1.333V18a.667.667 0 0 0 .667.667h3.333V20H2a2 2 0 0 1-2-2Zm18.667 0v-3.333H20V18a2 2 0 0 1-2 2h-3.333v-1.333H18a.667.667 0 0 0 .667-.667ZM12 20H8v-1.333h4V20Z"
        clipRule="evenodd"
      />
    </svg>
  );
}
