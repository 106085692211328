/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import EditIcon from '../../../../assets/svg-icons/EditIcon';
import EyeIcon from '../../../../assets/svg-icons/EyeIcon';
import PlusIcon from '../../../../assets/svg-icons/PlusIcon';
import SectionAddIcon from '../../../../assets/svg-icons/SectionAddIcon';
import Button from '../../../../components/button/button';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../components/popover/popover';

export default function FormActionButtons({
  onAddSection,
  onAddQuestion,
  onSave,
  onPreview,
  disabled,
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className="flex gap-3 justify-end px-6">
      <Popover
        open={isOpen}
        onOpenChange={(open) => {
          if (disabled) return;
          setIsOpen(open);
        }}
      >
        <PopoverTrigger>
          <Button
            variant="outline"
            type="button"
            className="w-[42px] h-[42px] p-0 rounded"
            disabled={disabled}
          >
            <PlusIcon color={disabled ? '#979ba1' : undefined} />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          side="top"
          sideOffset={10}
          align="end"
          className="w-[139px] py-2 px-3 flex flex-col gap-4"
        >
          <div
            className="flex items-center gap-4 cursor-pointer"
            onClick={() => {
              onAddSection();
              setIsOpen(false);
            }}
          >
            <SectionAddIcon className="text-theme-color-secondary-main" />
            <p className="text-neutral-color-main mb-0 text-sm">Section</p>
          </div>

          <div className="flex items-center gap-4 cursor-pointer" onClick={onAddQuestion}>
            <EditIcon className="text-theme-color-secondary-main" />
            <p className="text-neutral-color-main mb-0 text-sm">Question</p>
          </div>
        </PopoverContent>
      </Popover>

      <Button
        variant="outline"
        type="button"
        className="w-[42px] h-[42px] p-0 rounded"
        onClick={onPreview}
      >
        <EyeIcon />
      </Button>
      <Button
        variant="primary"
        type="button"
        className="h-[42px] min-w-[100px] p-0"
        onClick={onSave}
        disabled={disabled}
      >
        Save
      </Button>
    </div>
  );
}
