/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import { IconButton } from '@material-ui/core';
import {
  getRemindersByPagination,
  setDateFilterState,
  setFilterEndDate,
  setFilterStartDate,
} from '../../redux/features/reminder/reminderSlice';
import IconVerticalBox from '../../assets/svg-icons/IconVerticalBox';
import IconDownload from '../../assets/svg-icons/IconDownload';
import ReloadIcon from '../../assets/svg-icons/ReloadIcon';
import classes from '../../components/assetsTable/assetsTable.module.css';
import ExportPM from './Componets/ExportPM';
import PMAdvanceFilter from './Componets/PMAdvanceFilter';
import DateRangeFilter from './Componets/DateRangeFilter';
import DeleteIcon from '../../assets/svg-icons/DeleteIcon';

const PMFilter = ({ setISShowFilter, iSShowFilter, allColumns, setIsLoading }) => {
  const dispatch = useDispatch();
  const [iSShowExportPopup, setISShowExportPopup] = useState(false);
  // Advance filter dialog start
  const [openAdvFilter, setOpenAdvFilter] = useState(false);

  const handleOpenAdvFilter = () => {
    setOpenAdvFilter(true);
  };

  const handleCloseAdvFilter = () => {
    setOpenAdvFilter(false);
  };
  // Advance filter dialog end

  const { user } = useSelector((state) => state.user);

  const {
    rowPerPage,
    currentPage,
    searchValue,
    filterStartDate,
    filterEndDate,
    dateFilterState,
  } = useSelector((state) => state.reminder);

  const resetState = () => {

  };

  const handleEraserFilterClick = async () => {
    dispatch(setFilterStartDate(''));
    dispatch(setFilterEndDate(''));
    dispatch(
      setDateFilterState([
        {
          startDate: '',
          endDate: '',
          key: 'selection',
        },
      ]),
    );
    setIsLoading(true);
    const filters = {
      rowPerPage,
      currentPage,
      searchValue,
      startDate: '',
      endDate: '',
    };
    await dispatch(getRemindersByPagination(filters));
    setIsLoading(false);
  };

  // useEffect(() => {
  //   setLocations(sublocations || [])
  // }, [sublocations])

  const [show, setShow] = useState(false);

  const getDataRows = async () => {
    setIsLoading(true);
    const filters = {
      rowPerPage,
      currentPage,
      searchValue,
      startDate: dateFilterState?.find((item) => item.startDate)?.startDate
        ? moment(dateFilterState?.find((item) => item.startDate)?.startDate).format('YYYY-MM-DD')
        : '',
      endDate: dateFilterState?.find((item) => item.startDate)?.startDate
        ? moment(dateFilterState?.find((item) => item.endDate)?.endDate).format('YYYY-MM-DD')
        : '',
    };
    await dispatch(getRemindersByPagination(filters));
    setIsLoading(false);
  };

  const handelFetchFilters = () => {
    getDataRows();
  };

  const handleClose = () => {
    setShow(false);
  };
  const handleUpdate = async () => {
    setShow(false);
    dispatch(setFilterStartDate(dateFilterState?.find((item) => item.startDate)?.startDate));
    dispatch(setFilterEndDate(dateFilterState?.find((item) => item.endDate)?.endDate));
    // await getDataRows();
  };

  return (
    <div className="filters-container">
      <div className="filters-left-wrapper">
        <div className="filters-left-wrapper-period">
          <label style={{ color: '#36383C', fontWeight: 400 }}>Period </label>
          <div
            className="block w-full px-2 py-2 bg-white border border-gray-300 rounded-md "
            style={{ fontSize: '14px', cursor: 'pointer' }}
            onClick={() => setShow(!show)}
          >
            {filterStartDate ? (
              <>
                <span>{moment(filterStartDate).format('DD MMM YYYY')}</span> -{' '}
                <span>{moment(filterEndDate).format('DD MMM YYYY')}</span>{' '}
              </>
            ) : (
              'All time'
            )}
          </div>
          {show && (
            <>
              <div className="tck-overlay" onClick={() => setShow(!show)} />
              <DateRangeFilter
                show={show}
                handleUpdate={handleUpdate}
                handleClose={handleClose}
                state={dateFilterState}
                setState={setDateFilterState}
              />
            </>
          )}
        </div>
        <button
          style={{
            alignSelf: 'flex-end',
            color: 'black',
            padding: '.375rem .7rem',
            display: 'flex',
            alignItems: 'center',
            marginRight: '10px',
          }}
          className="new-btn btn"
          onClick={handleEraserFilterClick}
          title="Reset"
        >
          <ReloadIcon /> <span className="ml-2">Reset</span>
        </button>
        <button
          style={{
            alignSelf: 'flex-end',
            padding: '.375rem .7rem',
            color: 'black',
          }}
          className="new-btn btn"
          onClick={handelFetchFilters}
          title="Reload"
        >
          {/* <ReloadIcon /> */}
          <FontAwesomeIcon icon={faRotate} style={{ color: 'black' }} />{' '}
          <span className="ml-2">Reload</span>
        </button>
      </div>
      <div className="filters-right-wrapper">
        <PMAdvanceFilter
          openAdvFilter={openAdvFilter}
          handleOpenAdvFilter={handleOpenAdvFilter}
          handleCloseAdvFilter={handleCloseAdvFilter}
          getDataRows={getDataRows}
          dateFilterState={dateFilterState}
          setDateFilterState={setDateFilterState}
        />
        {/* <Button
          variant="contained"
          style={{
            backgroundColor: '#36383C',
            color: '#F8F9F9',
            fontSize: '14px',
            fontWeight: '600',
            padding: '8px 16px',
            borderRadius: '8px',
          }}
          onClick={handleOpenAdvFilter}
          className="adv-button"
        >
          Advance filter
        </Button> */}

        <div style={{ position: 'relative' }}>
          <IconButton
            style={{
              backgroundColor: '#F8F9F9',
              borderRadius: '8px',
              border: '1px solid #E1E1E1',
            }}
            variant="contained"
            title="Column selection"
            className="outlined-button"
            onClick={() => setISShowFilter(!iSShowFilter)}
          >
            <IconVerticalBox />
          </IconButton>
          {iSShowFilter && (
            <>
              <div className="tck-overlay" onClick={() => setISShowFilter(!iSShowFilter)} />
              <div className={classes.newFilterTable}>
                <div className={classes.newFilterTableCheckboxs}>
                  <p className="weekly-frequency-label ml-0">Filter column</p>
                  {/* Loop through columns data to create checkbox */}
                  {allColumns.map((column) => {
                    if (column.id !== 'delete-table-column') {
                      return (
                        <div key={column.id} className={classes.newFilterTableCheckboxs}>
                          <label className="weekly-frequency-switch black-switch">
                            <input type="checkbox" {...column.getToggleHiddenProps()} />
                            <span className="weekly-frequency-slider" />
                          </label>
                          <span className="weekly-frequency-label">{column.Header}</span>
                        </div>
                      );
                    }
                  })}
                  <br />
                </div>
              </div>
            </>
          )}
        </div>
        {/* {user?.role !== 'Technical Team' && (
            <IconButton
              title="Delete"
              variant="contained"
              style={{
                backgroundColor: '#F8F9F9',
                borderRadius: '8px',
                border: '1px solid #E1E1E1',
              }}
              onClick={() => setISShowExportPopup(!iSShowExportPopup)}
              className="outlined-button"
            >
              <DeleteIcon />
            </IconButton>
        )} */}
      </div>
    </div>
  );
};

export default PMFilter;
