/* eslint-disable import/prefer-default-export */
import * as React from 'react';
import { cn } from '../../utils/utilClassNames';

const badgeVariantStyles = {
  default: 'border-transparent bg-theme-color-alpha-secondary text-theme-color-secondary-main',
  secondary: 'border-transparent bg-neutral-color-aplha-neutral text-neutral-color-main',
  warning: 'border-transparent bg-generic-color-alpha-orange-16 text-generic-color-orange-main',
  success: 'border-transparent bg-[#20BB7D29] text-[#20BB7D]',
  danger: 'border-transparent bg-[#D9314A29] text-[#D9314A]',
};

function Badge({ className, variant = 'default', ...props }) {
  const variantClass = badgeVariantStyles[variant] || badgeVariantStyles.default;

  return (
    <div
      className={cn(
        'inline-flex items-center rounded-md border px-2 py-1 text-xs font transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 text-center justify-center',
        variantClass,
        className,
      )}
      {...props}
    />
  );
}

export { Badge };
