/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import {
  addMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
} from 'date-fns';
import CorrectIcon from '../../../assets/svg-icons/CorrectIcon';

const DateRangeFilter = ({ show, handleClose, handleUpdate, state, setState, advance }) => {
  const dispatch = useDispatch();
  const optionOfFilter = ['This year', 'This month', 'This week', 'Today', 'Custom range'];
  const [filterDateOption, setFilterDateOption] = useState('Custom range');
  // Define different date ranges for the options
  const handleMenuItemClick = (option) => {
    setFilterDateOption(option);
    let newRange;
    const today = new Date();

    switch (option) {
      case 'This year':
        newRange = {
          startDate: startOfYear(today),
          endDate: endOfYear(today),
          key: 'selection',
        };
        break;
      case 'This month':
        newRange = {
          startDate: startOfMonth(today),
          endDate: endOfMonth(today),
          key: 'selection',
        };
        break;
      case 'This week':
        newRange = {
          startDate: startOfWeek(today),
          endDate: endOfWeek(today),
          key: 'selection',
        };
        break;
      case 'Today':
        newRange = {
          startDate: today,
          endDate: today,
          key: 'selection',
        };
        break;
      case 'Custom range':
        // Let the user select their own range; no preset
        return;
      default:
        return;
    }
    // Set the new range state
    dispatch(setState([newRange]));
  };
  return (
    show && (
      <div
        className={
          !advance
            ? 'custom-date-picker-wrapper'
            : 'custom-date-picker-wrapper custom-date-picker-wrapper-2'
        }
      >
        <div className="custom-date-picker">
          <div className="custom-date-picker-side-menu">
            <ul className="menuList-for-date-range">
              {optionOfFilter.map((item) => (
                <li
                  className={
                    filterDateOption === item
                      ? `menuItem-for-date-range active`
                      : `menuItem-for-date-range`
                  }
                  onClick={() => handleMenuItemClick(item)}
                >
                  {item}{' '}
                  {filterDateOption === item && (
                    <span>
                      <CorrectIcon />
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="date-range-picker">
            <h6>Range of date</h6>
            <DateRange
              editableDateInputs={true}
              onChange={(item) => dispatch(setState([item.selection]))}
              moveRangeOnFirstSelection={false}
              ranges={state}
              months={2}
              direction="horizontal"
            />
          </div>
        </div>
        <div className="custom-date-picker-active-button">
          <button type="button" className="black-button" onClick={handleUpdate}>
            Save
          </button>
        </div>
      </div>
    )
  );
};

export default DateRangeFilter;
