/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import { toTitleCase } from '../../../../utils/text';

export default function FormSurveyRatingSummary({ field }) {
  const totalItems = field?.responses.length;
  const result = field?.responses.reduce((acc, item) => {
    acc[item] = acc[item] || { count: 0, percentage: 0 };
    acc[item].count++;
    acc[item].percentage = ((acc[item].count / totalItems) * 100).toFixed(2);
    if (acc[item].percentage % 1 === 0) {
      acc[item].percentage = `${parseInt(acc[item].percentage, 10)}%`;
    } else {
      acc[item].percentage = `${acc[item].percentage}%`;
    }
    return acc;
  }, {});

  const data = Object.entries(result).map(([key, value]) => ({
    name: key,
    value: value.count,
  }));

  const getAverage = () => {
    let sum = 0;
    let average = 0;
    for (let i = 1; i <= 5; i++) {
      sum += i * (result[i]?.count || 0);
    }
    average = sum / totalItems;
    return average;
  };

  return (
    <div className="grid grid-cols-[300px_1fr] gap-6">
      <div>
        <div className="pb-2 mb-2 border-b border-neutral-color-divider">
          <h2 className="text-neutral-color-darker font-semibold text-md">{field?.field_label}</h2>

          <p className="text-right text-neutral-color-main">{toTitleCase(field?.field_type)}</p>
        </div>

        <div>
          <h2 className="text-neutral-color-main text-base mb-2">{totalItems} responses</h2>
          {Object.entries(result).map(([key, value]) => (
            <h2 className="text-neutral-color-dark text-sm">
              {key} : {value.count} ({value.percentage})
            </h2>
          ))}
        </div>
      </div>

      <div className="bg-neutral-variant-color-lighter h-[272px] flex items-center justify-center gap-4 w-full p-[44px]">
        <div className="flex items-center gap-4">
          <div className="text-center">
            <h2 className="font-semibold text-[32px] text-neutral-color-dark">{totalItems}</h2>
            <p className="text-neutral-color-main text-sm">Responses</p>
          </div>

          <div className="h-[30px] w-[1px] bg-neutral-color-divider" />

          <div className="text-center">
            <h2 className="font-semibold text-[32px] text-neutral-color-dark">{getAverage()}</h2>
            <p className="text-neutral-color-main text-sm">Average</p>
          </div>
        </div>

        <div>
          <div className="flex items-center gap-12">
            {Object.entries(result).map(([key, value]) => {
              return (
                <ProgressItemHorizontal
                  percent={value.percentage}
                  title={key}
                  count={value.count}
                  color="#4472B8A3"
                />
              );
            })}
          </div>

          <div></div>
        </div>
      </div>
    </div>
  );
}

function ProgressItemHorizontal({ color, count, percent, title }) {
  return (
    <div className="flex flex-col justify-center items-center">
      <span className="text-[13px] text-neutral-color-darker font-semibold text-center">{`${count} (${percent})`}</span>
      <div className="flex-1 relative">
        <OPBarHorizonal percentDue={percent ?? 0} fillColor={color} />

        {/* <div>
            <h2 className="absolute -right-7 top-0 text-[16px]">100</h2>
            <h2 className="absolute -right-7 bottom-[96px] translate-y-1.5 text-[16px]">80</h2>
            <h2 className="absolute -right-7 bottom-[72px] translate-y-1.5  text-[16px]">60</h2>
            <h2 className="absolute -right-7 bottom-[48px] translate-y-1.5  text-[16px]">40</h2>
            <h2 className="absolute -right-7 bottom-[24px] translate-y-1.5  text-[16px]">20</h2>
            <h2 className="absolute -right-7 bottom-0 text-[16px]">0</h2>
          </div> */}
      </div>
      <span className="text-[13px] text-neutral-color-darker font-semibold text-center mt-1">
        {title}
      </span>
    </div>
  );
}

function OPBarHorizonal({ percentDue, fillColor }) {
  const progressBarStyle = {
    width: '12px',
    height: '120px',
    backgroundColor: '#e0e0e0',
    borderRadius: '200px',
    overflow: 'hidden',
    marginTop: '4px',
  };

  const progressFillStyle = {
    width: '100%',
    height: `${percentDue}%`,
    backgroundColor: fillColor ?? 'purple',
    transition: 'width 0.3s ease',
  };

  return (
    <div>
      <div style={progressBarStyle} className="transform rotate-180">
        <div style={progressFillStyle} />
      </div>
      {/* <div
          style={{
            minWidth: '70px',
            marginLeft: '8px',
            marginRight: '12px',
            textAlign: 'end',
            fontWeight: '600',
            fontSize: '14px',
            color: '#4A4C50',
            marginTop: '2px',
            // border: '1px solid black'
          }}
        >
          70 ({percentDue}%)
        </div> */}
    </div>
  );
}
