import React from 'react';

export default function UploadPlusIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M4.888 9.483a.85.85 0 1 0 0 1.7v-1.7Zm1.556 1.7a.85.85 0 1 0 0-1.7v1.7Zm-1.556-4.81a.85.85 0 0 0 0 1.7v-1.7Zm4.667 1.7a.85.85 0 1 0 0-1.7v1.7ZM4.888 3.26a.85.85 0 0 0 0 1.7v-1.7Zm4.667 1.7a.85.85 0 1 0 0-1.7v1.7Zm2.261 2.261a.85.85 0 0 0 1.7 0h-1.7ZM6.444 15.85a.85.85 0 0 0 0-1.7v1.7Zm-3.828-1.025.392-.754-.392.754Zm-.664-.663-.754.391.754-.392Zm9.876-12.987-.392.754.392-.754Zm.663.663-.754.392.754-.392Zm-9.875-.663L2.224.421l.392.754Zm-.664.663-.754-.391.754.391Zm7.603 9.979a.85.85 0 0 0 0 1.7v-1.7Zm4.667 1.7a.85.85 0 0 0 0-1.7v1.7Zm-1.483-3.184a.85.85 0 0 0-1.7 0h1.7ZM11.039 15a.85.85 0 1 0 1.7 0h-1.7Zm-6.15-3.817h1.555v-1.7H4.888v1.7Zm0-3.11h4.666v-1.7H4.888v1.7Zm0-3.112h4.666v-1.7H4.888v1.7ZM4.281 1.85h5.88V.15h-5.88v1.7Zm7.534 1.654v3.718h1.7V3.504h-1.7ZM6.444 14.15H4.282v1.7h2.162v-1.7Zm-3.817-1.654V3.504h-1.7v8.992h1.7Zm1.655 1.654c-.457 0-.748 0-.969-.019-.21-.017-.278-.046-.305-.06l-.784 1.508c.31.161.632.22.949.246.307.026.68.025 1.109.025v-1.7ZM.927 12.496c0 .428 0 .802.025 1.109.026.317.085.638.246.948l1.509-.783c-.014-.027-.043-.096-.06-.306-.019-.22-.02-.511-.02-.968h-1.7Zm2.08 1.575a.706.706 0 0 1-.3-.301l-1.509.783c.228.44.587.798 1.026 1.026l.784-1.508ZM10.163 1.85c.457 0 .748 0 .968.019.21.017.279.046.306.06L12.22.421c-.31-.161-.632-.22-.95-.246-.306-.026-.68-.025-1.108-.025v1.7Zm3.354 1.654c0-.428 0-.802-.025-1.109-.026-.317-.085-.638-.246-.948l-1.508.783c.014.027.043.096.06.306.018.22.02.511.02.968h1.7Zm-2.08-1.575a.705.705 0 0 1 .3.301l1.51-.783A2.406 2.406 0 0 0 12.22.42l-.784 1.508ZM4.282.15c-.429 0-.802 0-1.11.025C2.857.2 2.535.26 2.225.42l.784 1.508c.027-.014.095-.043.305-.06.22-.018.512-.019.969-.019V.15ZM2.627 3.504c0-.457.001-.748.02-.968.017-.21.046-.279.06-.306l-1.509-.783c-.16.31-.22.631-.246.948-.025.307-.025.68-.025 1.11h1.7ZM2.224.421c-.44.228-.798.586-1.026 1.026l1.509.783a.706.706 0 0 1 .3-.3L2.225.42Zm7.331 13.096h4.667v-1.7H9.555v1.7Zm1.484-3.184V15h1.7v-4.667h-1.7Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
