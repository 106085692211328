import { closestCenter, DndContext } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import useDragSorting from '../../../../hooks/useDragSorting';
import ParentSection from '../Section/ParentSection';
import { FormStatus } from '../../config/forms/constant';

export default function FormDetailBuilder({ fields, dropdownOpenList, setOpenDropdownList }) {
  const form = useFormContext();

  const { sensors, measuringConfig } = useDragSorting();

  const containers = form.watch('forms');

  const { form: formDetail } = useSelector((state) => state.form);

  const isPublished = formDetail?.status === FormStatus.Published;

  const handleDragEnd = (event) => {
    if (isPublished) return;

    const { active, over } = event;

    if (!over) return;

    if (active.id !== over?.id && over !== null) {
      const oldIndex = containers.findIndex((item) => item.id === active.id);
      const newIndex = containers.findIndex((item) => item.id === over?.id);
      const newData = arrayMove(containers, oldIndex, newIndex);
      const newOrder = newData.map((item, index) => ({
        ...item,
        order: index + 1,
      }));

      form.setValue('forms', newOrder);
    }
  };

  return (
    <div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        // eslint-disable-next-line react/jsx-no-bind
        onDragEnd={handleDragEnd}
        measuring={measuringConfig}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      >
        <SortableContext
          items={fields?.map((value) => {
            return value.id.toString();
          })}
          strategy={verticalListSortingStrategy}
        >
          {fields?.length > 0 ? (
            fields?.map((item, index) => (
              <div key={item.id} className="mb-4">
                <ParentSection
                  index={index}
                  id={item.id}
                  totalSection={fields?.length}
                  dropdownOpenList={dropdownOpenList}
                  setOpenDropdownList={setOpenDropdownList}
                />
              </div>
            ))
          ) : (
            <h2 className="text-neutral-color-main text-sm text-center">No content(s) yet</h2>
          )}
        </SortableContext>
      </DndContext>
    </div>
  );
}
