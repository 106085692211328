/* eslint-disable import/prefer-default-export */

export const capitalizeFirstLetter = (text) => {
  if (!text) {
    return '';
  }
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const toTitleCase = (text) => {
  if (!text) {
    return '';
  }
  return text
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
