import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormControl, FormField, FormItem, FormMessage } from '../../../../components/form/form';
import SignatureInput from '../../../../components/input/signatureInput';
import FormPreviewHeader from '../FormPreview/FormPreviewHeader';

export default function FormRequestSignature() {
  const form = useFormContext();

  return (
    <div>
      <FormPreviewHeader />

      <div className="bg-white rounded-2xl py-6 px-10 mx-auto space-y-4 mt-6">
        <div className="bg-neutral-color-aplha-neutral-8 rounded-[8px] px-2 py-3">
          <p className="font-semibold text-lg text-neutral-color-darker mb-0">Signature</p>
        </div>

        <div className="bg-neutral-variant-color-lighter rounded-[8px] border border-neutral-color-divider">
          <p className="mb-0 text-neutral-color-main text-sm px-4 py-3">
            Kindly provide your signature below to confirm your submission of this form. Your
            signature will complete the submission process.
          </p>
        </div>

        <FormField
          control={form.control}
          name="signature"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <SignatureInput onChange={field.onChange} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  );
}
