import React from 'react';

export default function SuccessIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={82} height={81} fill="none" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M41 0C18.633 0 .5 18.133.5 40.5.5 62.869 18.633 81 41 81c22.369 0 40.5-18.131 40.5-40.5C81.5 18.133 63.369 0 41 0Zm0 76.017C21.46 76.017 5.562 60.04 5.562 40.5 5.563 20.96 21.46 5.062 41 5.062c19.54 0 35.438 15.898 35.438 35.438 0 19.54-15.898 35.517-35.438 35.517Zm16.163-50.336L33.401 49.592l-10.7-10.7a2.532 2.532 0 0 0-3.581 3.579L31.648 55c.989.986 2.591.986 3.58 0 .115-.115.212-.239.3-.368l25.217-25.372a2.534 2.534 0 0 0 0-3.58 2.534 2.534 0 0 0-3.582 0Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h81v81H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
