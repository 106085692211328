/* eslint-disable react/require-default-props */
/* eslint-disable react/button-has-type */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { cn } from '../../utils/utilClassNames';

const variantStyles = {
  primary: [
    // base
    '!bg-neutral-color-darker text-white rounded-[8px] hover:!bg-neutral-color-darker/95',
  ],
  secondary: [
    // base
    '!bg-neutral-color-aplha-neutral text-neutral-color-main rounded-[8px]',
  ],
  tersiary: [
    // base
    '!bg-tnb-psd-color-blue-dark text-white rounded-[8px]',
  ],
  outline: [
    // base
    'bg-[#ffffff] border-neutral-color-divider rounded-[8px] border ',
  ],
  transparent: [
    // base
    'bg-transparent rounded-[8px] border border-neutral-color-theme-color-light font-semibold',
  ],
};

const sizeStyles = {
  large: 'h-[42px] text-md gap-2 px-[8px] [&_svg]:size-4 font-normal',
  regular: 'h-8 text-md gap-2 px-[8px] [&_svg]:size-4 font-normal',
  small: 'h-6 text-sm gap-1 px-[4px] [&_svg]:size-3',
};

const blockStyles = {
  true: 'w-full',
};

const disabledStyles = {
  true: 'disabled:!bg-neutral-variant-color-light [&_svg]:disabled:!text-neutral-color-neutral-variant-color-main cursor-not-allowed',
};

const Button = forwardRef(
  (
    {
      children,
      className,
      variant = 'primary',
      block,
      isLoading,
      disabled,
      onClick,
      type = 'button',
      size = 'regular',
      asChild,
      ...props
    },
    ref,
  ) => {
    const variantClass = variantStyles[variant] || variantStyles.primary;
    const sizeClass = sizeStyles[size] || sizeStyles.regular;
    const blockClass = block ? blockStyles.true : '';
    const disabledClass = disabled ? disabledStyles.true : '';

    return (
      <button
        ref={ref}
        type={type}
        className={cn(
          'group flex items-center justify-center m-[0px] outline-none transition-all text-center',
          variantClass,
          sizeClass,
          blockClass,
          disabledClass,
          className,
        )}
        disabled={isLoading || disabled}
        onClick={(e) => {
          if (isLoading || disabled) {
            e.preventDefault();
          }
          onClick?.(e);
        }}
        {...props}
      >
        {children}
      </button>
    );
  },
);
Button.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tersiary', 'outline', 'transparent']),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  size: PropTypes.oneOf(['large', 'regular', 'small']),
  asChild: PropTypes.bool,
};

export default Button;
