import React from 'react';

export default function TimeIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={18} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.7}
        d="M8.003 3.31a6.429 6.429 0 1 0 0 12.857 6.429 6.429 0 0 0 0-12.857Zm0 0V1.167m4.286 3.571.714-.714M6.574 1.167h2.857M8.003 6.881v2.857"
      />
    </svg>
  );
}
