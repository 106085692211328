import React, { useMemo, useState } from 'react';
import Table from '../../../../components/crudTable/crudTable';
import { listSurveySummaryLongTextColumns } from '../../config/tables/columns';
import Pagination from '../../../../components/pagination/pagination';
import { toTitleCase } from '../../../../utils/text';

export default function FormSurveyLongTextSummary({ field }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRowPerPage, setTotalRowPerPage] = useState(10);

  const data = field?.responses;

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * totalRowPerPage;
    const endIndex = startIndex + totalRowPerPage;
    return data?.slice(startIndex, endIndex);
  }, [data, currentPage, totalRowPerPage]);

  return (
    <div className="grid grid-cols-[300px_1fr] gap-6">
      <div>
        <div className="pb-2 mb-2 border-b border-neutral-color-divider">
          <h2 className="text-neutral-color-darker font-semibold text-md">{field.field_label}</h2>

          <p className="text-right text-neutral-color-main">{toTitleCase(field.field_type)}</p>
        </div>

        <div>
          <h2 className="text-neutral-color-main text-base mb-2">
            {field.response_count} responses
          </h2>
          <h2 className="text-neutral-color-dark text-sm">
            Answer : {field.response_count} (100%)
          </h2>
        </div>
      </div>

      <div className="-mt-2">
        <Table
          columns={listSurveySummaryLongTextColumns()}
          data={paginatedData.map((item) => {
            return {
              answer: item,
            };
          })}
        />

        <div className="h-[1px] w-full bg-neutral-color-divider mb-4" />
        <Pagination
          className="mb-0 pb-0"
          totalRows={data?.length}
          rowPerPage={totalRowPerPage}
          pageChangeHandler={setCurrentPage}
          changeRowsPerPage={setTotalRowPerPage}
          currentPage={currentPage}
          withRedux={false}
        />
      </div>
    </div>
  );
}
