import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';

import FormDetailConfiguration from './components/Form/FormDetailConfiguration';
import FormDetailHeader from './components/FormDetail/FormDetailHeader';
import FormDetailTab from './components/FormDetail/FormDetailTab';
import FormDetailBuilder from './components/Form/FormDetailBuilder';
import FormActionButtons from './components/Form/FormActionButtons';
import FormDetailResponses from './components/Form/FormDetailResponses';
import { Form } from '../../components/form/form';
import { getFormDetailById, updateFormById } from '../../redux/features/forms/formSlice';
import Loader from '../../components/loader/loader';
import { FieldType, FormStatus } from './config/forms/constant';

export default function FormDetail() {
  const [selectedTab, setSelectedTab] = useState('formDetails');
  const [dropdownOpenList, setOpenDropdownList] = React.useState([]);

  const form = useForm({
    mode: 'onChange',
  });

  const {
    activeIndex,
    form: formDetail,
    isFormDetailGetLoading,
    isFormDetailGetSuccess,
  } = useSelector((state) => state.form);

  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getFormDetailById(id));
    }
  }, []);

  useEffect(() => {
    if (isFormDetailGetSuccess) {
      form.reset({
        title: formDetail.title,
        forms: formDetail.sections.map((section) => {
          return {
            id: Math.random(),
            title: section.title,
            description: section.description,
            items: section.fields?.map((item) => {
              return {
                id: Math.random(),
                question: item.label,
                answer: item.field_type,
                content: item.options
                  ? JSON.parse(item.options).map((option) => {
                      return {
                        id: option.id,
                        text: option.text,
                        type: option.type || undefined,
                      };
                    })
                  : undefined,
                isRequired: item.is_required,
              };
            }),
          };
        }),
      });
    }
  }, [isFormDetailGetSuccess, formDetail]);

  const { fields, insert } = useFieldArray({
    control: form.control,
    name: 'forms',
  });

  const isPublished = formDetail.status === FormStatus.Published;

  const activeParentIndex = activeIndex.split('-')[0];
  const activeChildIndex = activeIndex.split('-')[1];

  if (isFormDetailGetLoading) {
    return <Loader />;
  }

  const onSave = form.handleSubmit(async (values) => {
    if (isPublished) {
      return;
    }
    const payload = {
      title: values.title,
      description: formDetail.description,
      category: formDetail.category,
      form_type: formDetail.form_type,
      status: formDetail.status || 'draft',
      sections: values.forms.map((item, index) => {
        return {
          title: item.title,
          description: item.description,
          order: index + 1,
          fields: item.items.map((value, idx) => {
            return {
              label: value.question,
              field_type: value.answer,
              order: idx + 1,
              options: value.content
                ? JSON.stringify(value.content.map((text) => text))
                : undefined,
              is_required: value.isRequired,
            };
          }),
        };
      }),
    };

    try {
      const resultAction = await dispatch(updateFormById({ ...payload, id }));
      unwrapResult(resultAction);
      toast.success('Form saved successfully');
    } catch (error) {
      toast.error('Failed to save form');
    }
  });

  return (
    <Form {...form}>
      <div className=" bg-white p-4 mb-20">
        <FormDetailHeader />
        <FormDetailTab setSelectedTab={setSelectedTab} selectedTab={selectedTab} />

        {selectedTab === 'formDetails' && (
          <FormDetailBuilder
            fields={form.watch('forms') || []}
            setOpenDropdownList={setOpenDropdownList}
            dropdownOpenList={dropdownOpenList}
          />
        )}
        {selectedTab === 'configurations' && (
          <FormDetailConfiguration title={form.watch('title')} />
        )}
        {selectedTab === 'responses' && <FormDetailResponses type={formDetail.form_type} />}

        {selectedTab === 'formDetails' && (
          <div className="fixed bottom-0 left-0 right-0 bg-[#ffffff] py-6 shadow-lg">
            <FormActionButtons
              disabled={isPublished}
              onAddSection={() => {
                if (isPublished) return;
                // insert section based on active parent index
                insert(activeParentIndex + 1, { id: Math.random(), items: [] });
              }}
              onAddQuestion={() => {
                if (isPublished) return;
                if (fields.length === 0) {
                  toast.error('Please add a section first');
                  return;
                }

                const selectedId = form.watch(`forms.${activeParentIndex}.id`)?.toString();
                // open dropdown for the active parent index
                setOpenDropdownList((prev) => {
                  if (prev.includes(selectedId)) {
                    return prev;
                  }
                  return [...prev, selectedId];
                });

                const items = form.watch(`forms.${activeParentIndex}.items`) || [];
                items.splice(activeChildIndex, 0, {
                  id: Math.random(),
                  answer: FieldType.ShortText,
                  question: '',
                  content: undefined,
                });
                form.setValue(`forms.${activeParentIndex}.items`, items);
              }}
              onSave={onSave}
              onPreview={() => {
                window.sessionStorage.setItem('form', JSON.stringify(form.watch()));
                window.open(`/forms/preview/${id}`, '_blank');
              }}
            />
          </div>
        )}
      </div>
    </Form>
  );
}
