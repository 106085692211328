import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../components/dialog/dialog';
import Button from '../../../../components/button/button';

export default function RejectApprovalFormModal({ open, onClose, onReject }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Reject this form?</DialogTitle>
          <DialogDescription className="mt-4">
            Are you sure you want to proceed with this action? Once confirmed, it cannot be changed.
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="gap-2">
          <Button className="w-[100px]" size="large" variant="outline" onClick={onReject}>
            Yes, confirm
          </Button>
          <Button className="w-[100px]" size="large" onClick={onClose}>
            No, go back
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
