/* eslint-disable no-shadow */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-else-return */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import CalendarIcon from '../../../../assets/svg-icons/CalendarIcon';
import CheckboxIcon from '../../../../assets/svg-icons/CheckBoxIcon';
import DownIcon from '../../../../assets/svg-icons/DownIcon';
import RadioIcon from '../../../../assets/svg-icons/RadioIcon';
import TimeIcon from '../../../../assets/svg-icons/TimeIcon';
import Table from '../../../../components/crudTable/crudTable';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../../../components/form/form';
import TextArea from '../../../../components/input/textArea';
import TextInput from '../../../../components/input/textInput';
import Label from '../../../../components/label/label';
import { cn } from '../../../../utils/utilClassNames';
import { FieldType } from '../../config/forms/constant';
import StarIcon from '../../../../assets/svg-icons/StarIcon';

export default function FormResponseDetailFormAnswer() {
  const form = useForm();

  const { form: formDetail, formResponseDetail } = useSelector((state) => state.form);

  const sections = formResponseDetail?.section_responses;

  return (
    <Form {...form}>
      <div className="mt-4">
        <h2 className="text-base font-semibold text-neutral-color-darker mb-4">Form Answer</h2>

        <div className="border border-neutral-color-divider p-4 rounded-lg">
          <h2 className="text-neutral-color-darker font-semibold text-lg mb-2">
            {formDetail?.title || ''}
          </h2>
          <h3 className="text-neutral-color-main font-normal text-sm border-b border-neutral-color-divider pb-2">
            {formDetail?.description || ''}
          </h3>

          <div className="mt-2">
            {sections?.map((section, sectionIdx) => (
              <div
                key={sectionIdx}
                className={cn(
                  'pb-4',
                  sectionIdx !== sections.length - 1
                    ? 'border-b border-neutral-color-divider mb-4'
                    : '',
                )}
              >
                <h2 className="text-sm font-semibold text-neutral-color-darker">{section.title}</h2>

                <div className="flex flex-col gap-4 mt-3">
                  {section?.field_responses?.map((item, index) => {
                    const name = `forms.${index}.${item.question}`;

                    return (
                      <div key={item.field}>
                        <Label className="mb-2">{item.field_label}</Label>

                        {(() => {
                          if (item.field_type === FieldType.ShortText) {
                            return (
                              <FormField
                                control={form.control}
                                name={name}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormControl>
                                      <TextInput disabled {...field} value={item.value} />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            );
                          } else if (item.field_type === FieldType.LongText) {
                            return (
                              <FormField
                                control={form.control}
                                name={name}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormControl>
                                      <TextArea
                                        disabled
                                        className="!h-[126px]"
                                        onChange={field.onChange}
                                        {...field}
                                        value={item.value}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            );
                          } else if (item.field_type === FieldType.Checkboxes) {
                            return (
                              <FormField
                                control={form.control}
                                name={name}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormControl>
                                      <TextInput
                                        disabled
                                        variant="filled"
                                        containerClassName="md:!w-1/2"
                                        suffix={
                                          <CheckboxIcon className="text-neutral-color-main" />
                                        }
                                        {...field}
                                        value={item.value}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            );
                          } else if (item.field_type === FieldType.MultipleChoices) {
                            return (
                              <FormField
                                control={form.control}
                                name={name}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormControl>
                                      <TextInput
                                        disabled
                                        variant="filled"
                                        containerClassName="md:!w-1/2"
                                        suffix={<RadioIcon className="text-neutral-color-main" />}
                                        {...field}
                                        value={item.value}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            );
                          } else if (item.field_type === FieldType.Dropdown) {
                            return (
                              <FormField
                                control={form.control}
                                name={name}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormControl>
                                      <TextInput
                                        disabled
                                        variant="filled"
                                        containerClassName="md:!w-1/2"
                                        suffix={<DownIcon className="text-neutral-color-main" />}
                                        {...field}
                                        value={item.value}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            );
                          } else if (item.field_type === FieldType.Image) {
                            return (
                              <FormField
                                control={form.control}
                                name={name}
                                render={() => (
                                  <FormItem>
                                    <FormControl>
                                      <div
                                        className={cn(
                                          'rounded-[10px] border-[0.83px] border-dashed border-gray flex items-center justify-center size-[130px]',
                                        )}
                                      >
                                        <div className="relative w-full h-full">
                                          <img
                                            src={item.content}
                                            alt="submit answer"
                                            className="h-full w-full object-contain rounded"
                                          />
                                        </div>
                                      </div>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            );
                          } else if (item.field_type === FieldType.Date) {
                            return (
                              <FormField
                                control={form.control}
                                name={name}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormControl>
                                      <TextInput
                                        {...field}
                                        disabled
                                        variant="filled"
                                        containerClassName="md:!w-1/2"
                                        suffix={
                                          <CalendarIcon className="text-neutral-color-main" />
                                        }
                                        value={format(new Date(item.value), 'MMM, d yyyy')}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            );
                          } else if (item.field_type === FieldType.Time) {
                            const date = new Date();
                            const time = item?.value?.split(':');
                            date.setHours(time[0], time[1], 0);

                            // Format the date to include AM/PM
                            const formattedTime = format(date, 'hh:mm a');

                            return (
                              <FormField
                                control={form.control}
                                name={name}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormControl>
                                      <TextInput
                                        {...field}
                                        disabled
                                        variant="filled"
                                        containerClassName="md:!w-1/2"
                                        suffix={<TimeIcon className="text-neutral-color-main" />}
                                        value={formattedTime}
                                      />
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            );
                          } else if (item.field_type === FieldType.Rating) {
                            const fieldId = item.field;

                            const allFields = formDetail?.sections
                              ?.map((section) => section.fields)
                              .flat(Infinity);

                            const selectedFields = allFields?.find((field) => field.id === fieldId);

                            const option = selectedFields?.options
                              ? JSON.parse(selectedFields.options)[0].text
                              : [];

                            const arrayOptions = Array.from({ length: option }, (_, i) => i + 1);
                            return (
                              <div className="flex items-start gap-6 text-center text-sm h-fit md:w-1/2 justify-center">
                                {arrayOptions.map((option, idx) => {
                                  return (
                                    <div key={idx}>
                                      <StarIcon
                                        className={cn(
                                          'cursor-pointer',
                                          idx < item.value
                                            ? 'text-generic-color-alpha-orange-92 fill-generic-color-alpha-orange-92'
                                            : '',
                                        )}
                                      />
                                      <p className="mb-0">{option}</p>
                                    </div>
                                  );
                                })}
                              </div>
                            );
                          } else if (item.field_type === FieldType.FileUpload) {
                            return (
                              <FormField
                                control={form.control}
                                name={name}
                                render={() => (
                                  <FormItem>
                                    <FormControl>
                                      <div
                                        className={cn(
                                          'rounded-[10px] border-[0.83px] border-dashed border-gray flex items-center justify-center size-[130px]',
                                        )}
                                      >
                                        <div className="relative w-full h-full flex items-center justify-center flex-col">
                                          <span role="img" aria-label="document">
                                            📄
                                          </span>
                                          <p className="mb-0 text-sm">{item.content}</p>
                                        </div>
                                      </div>
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            );
                          } else if (item.field_type === FieldType.Table) {
                            const columns = Object.keys(item.value[0]);

                            const tableColumns = columns?.map((column) => {
                              return {
                                Header: column,
                                accessor: column,
                              };
                            });
                            return <Table columns={tableColumns} data={item.value} />;
                          } else {
                            return null;
                          }
                        })()}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Form>
  );
}
