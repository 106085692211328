import React from 'react';
import { useSelector } from 'react-redux';
import { Badge } from '../../../../components/badge/badge';
import Tabs from '../../../../components/tabs/tabs';
import { capitalizeFirstLetter } from '../../../../utils/text';
import { FormStatus } from '../../config/forms/constant';

export default function FormDetailTab({ setSelectedTab, selectedTab }) {
  const { form: formDetail } = useSelector((state) => state.form);

  return (
    <div className="relative">
      <Tabs
        tabList={[
          {
            label: 'Form Details',
            value: 'formDetails',
            onClick: (tab) => {
              setSelectedTab(tab);
            },
          },
          {
            label: 'Configurations',
            value: 'configurations',
            onClick: (tab) => {
              setSelectedTab(tab);
            },
          },
          {
            label: 'Responses',
            value: 'responses',
            disabled: formDetail.status === FormStatus.Draft,
            onClick: (tab) => {
              setSelectedTab(tab);
            },
          },
        ]}
        selectedTab={selectedTab}
        showTooltip={false}
      />

      <div className="flex items-center gap-2 absolute right-0 top-0">
        <p className="text-sm text-neutral-color-main mb-0">Status</p>
        <Badge
          variant={formDetail.status === FormStatus.Draft ? 'secondary' : 'default'}
          className="min-w-[67px] rounded-[4px]"
        >
          {capitalizeFirstLetter(formDetail.status)}
        </Badge>
      </div>
    </div>
  );
}
