/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';

import FormDetailResponsesRejected from '../FormDetail/FormDetailResponsesRejected';
import FormDetailResponsesApproved from '../FormDetail/FormDetailResponsesApproved';
import RejectApprovalFormModal from '../Modal/RejectApprovalFormModal';
import Button from '../../../../components/button/button';
import ApproveApprovalFormModal from '../Modal/ApproveApprovalFormModal';
import {
  approveRejectForm,
  getFormResponseDetailById,
} from '../../../../redux/features/forms/formSlice';

export default function FormResponseDetailApprovalTabContent() {
  const [isRejectApprovalModalOpen, setIsRejectApprovalModalOpen] = useState(false);
  const [isApproveApprovalModalOpen, setIsApproveApprovalModalOpen] = useState(false);
  const { responseId } = useParams();

  const dispatch = useDispatch();

  const { formResponseDetail } = useSelector((state) => state.form);
  const { user } = useSelector((state) => state.user);

  const onReject = async () => {
    try {
      const result = await dispatch(
        approveRejectForm({
          form_response_id: responseId,
          decision: 'reject',
        }),
      );
      unwrapResult(result);
      dispatch(getFormResponseDetailById(responseId));
      setIsRejectApprovalModalOpen(false);
      toast.success('Form response rejected successfully');
    } catch (error) {
      toast.error('Error rejecting form response');
    }
  };

  const onApprove = async () => {
    try {
      const result = await dispatch(
        approveRejectForm({
          form_response_id: responseId,
          decision: 'approve',
        }),
      );
      unwrapResult(result);
      dispatch(getFormResponseDetailById(responseId));
      setIsApproveApprovalModalOpen(false);
      toast.success('Form response approved successfully');
    } catch (error) {
      toast.error('Error approving form response');
    }
  };

  const type = formResponseDetail?.response_status;

  const isInit = !['approved', 'rejected', 'draft'].includes(type);
  const isApprover = formResponseDetail.approvers?.some((item) => item.id === user.id);

  if (!isApprover) {
    return (
      <div className="border border-neutral-color-divider py-3 px-4 rounded-lg bg-neutral-variant-color-lighter">
        <h2 className="mb-0 font-semibold text-neutral-color-darker text-sm">
          You are not an approver, you can’t approve or reject the request.
        </h2>
      </div>
    );
  } else if (isInit) {
    return (
      <>
        <div className="border border-neutral-color-divider py-3 px-4 rounded-lg bg-neutral-variant-color-lighter">
          <h2 className="mb-0 font-semibold text-neutral-color-darker text-sm">
            Please review the form details carefully before you approved or rejected the request.
          </h2>
        </div>

        <div className="flex justify-end gap-4 mt-4">
          <Button
            className="w-[83px] h-[42px] rounded-[8px]"
            onClick={() => setIsApproveApprovalModalOpen(true)}
          >
            Approve
          </Button>

          <Button
            variant="outline"
            className="w-[83px] h-[42px] rounded-[8px]"
            onClick={() => setIsRejectApprovalModalOpen(true)}
          >
            Reject
          </Button>

          <RejectApprovalFormModal
            open={isRejectApprovalModalOpen}
            onClose={() => setIsRejectApprovalModalOpen(false)}
            onReject={onReject}
          />

          <ApproveApprovalFormModal
            open={isApproveApprovalModalOpen}
            onClose={() => setIsApproveApprovalModalOpen(false)}
            onApprove={onApprove}
          />
        </div>
      </>
    );
  } else if (type === 'draft') {
    return <FormDetailResponsesRejected />;
  } else if (type === 'approved') {
    return <FormDetailResponsesApproved />;
  }
}
