import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import Button from '../../../../components/button/button';
import PrintIcon from '../../../../assets/svg-icons/PrintIcon';
import { FormControl, FormField, FormMessage, FormItem } from '../../../../components/form/form';
import TextInput from '../../../../components/input/textInput';

export default function FormDetailHeader({ onClickPrint, showPrint }) {
  const history = useHistory();

  const form = useFormContext();

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-6">
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={faArrowLeft}
          onClick={() => history.push('/forms')}
        />
        <div>
          <h2 className="text-neutral-color-main text-sm">Title</h2>

          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <TextInput
                    variant="ghost"
                    className="placeholder:font-bold text-bold flex-1 w-full !text-2xl py-0"
                    placeholder="Input title"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </div>

      {showPrint && (
        <Button className="w-[42px] h-[42px]" onClick={onClickPrint}>
          <PrintIcon />
        </Button>
      )}
    </div>
  );
}
