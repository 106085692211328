import React from 'react';
import { format } from 'date-fns';

import { Badge } from '../../../../components/badge/badge';
import { toTitleCase } from '../../../../utils/text';

export default function FormSurveyDateSummary({ field }) {
  const totalItems = field?.responses.length;

  const grouped = field?.responses.reduce((acc, dateStr) => {
    const date = new Date(dateStr);
    const month = date.toISOString().slice(0, 7); // Format: YYYY-MM
    const day = date.toISOString().slice(8, 10); // Format: DD

    if (!acc[month]) {
      acc[month] = {};
    }

    if (!acc[month][day]) {
      acc[month][day] = [];
    }

    acc[month][day].push(dateStr);
    return acc;
  }, {});

  return (
    <div className="grid grid-cols-[300px_1fr] gap-6">
      <div>
        <div className="pb-2 mb-2 border-b border-neutral-color-divider">
          <h2 className="text-neutral-color-darker font-semibold text-md">{field?.field_label}</h2>

          <p className="text-right text-neutral-color-main">{toTitleCase(field?.field_type)}</p>
        </div>

        <div>
          <h2 className="text-neutral-color-main text-base mb-2">{totalItems} responses</h2>
        </div>
      </div>

      <div className="bg-neutral-variant-color-lighter h-[272px] flex items-center justify-center gap-4 w-full p-[44px]">
        <div className="flex items-center gap-4">
          <div className="text-center">
            <h2 className="font-semibold text-[32px] text-neutral-color-dark">{totalItems}</h2>
            <p className="text-neutral-color-main text-sm">Responses</p>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          {Object.entries(grouped).map(([month, days]) => {
            const dayArrays = Object.keys(days)
              .map((day) => day)
              .sort((a, b) => a - b);

            return (
              <div className="flex items-center">
                <div className="w-[75px] flex items-center">
                  <h2 className="text-sm text-neutral-color-dark letter-[0.25%] mb-0">
                    {format(new Date(month), 'MMM yyyy')}
                  </h2>
                  <span className="block mx-2">:</span>
                </div>

                <div className="flex gap-2 flex-wrap">
                  {dayArrays.map((day) => {
                    return (
                      <Badge className="h-[28px] bg-[#72747814] text-[#4A4C50] rounded-[1000px]">
                        {day}
                        <Badge className="h-[16px] ml-2 bg-neutral-color-main text-[#ffffff]">
                          {days[day].length}
                        </Badge>
                      </Badge>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
