import React from 'react';

export default function EllipsisIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={2}
        d="M7.751 7.75h.5m-.5.5h.5m-5.167-.5h.5m-.5.5h.5m8.834-.5h.5m-.5.5h.5M8.668 8a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0ZM4.001 8a.667.667 0 1 1-1.333 0 .667.667 0 0 1 1.333 0Zm9.334 0A.667.667 0 1 1 12 8a.667.667 0 0 1 1.334 0Z"
      />
    </svg>
  );
}
