import React from 'react';

export default function SendIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.75}
        d="M3.676 7H6.92m-3.344-.38L1.102 2.167c-.374-.673.346-1.425 1.035-1.08L12.567 6.3a.783.783 0 0 1 0 1.4l-10.43 5.214c-.689.344-1.409-.408-1.035-1.08L3.577 7.38a.783.783 0 0 0 0-.76Z"
      />
    </svg>
  );
}
