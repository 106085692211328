/* eslint-disable no-else-return */
import React from 'react';
import { useFormContext } from 'react-hook-form';

import TextInput from '../../../../components/input/textInput';
import OptionInput from '../../../../components/input/optionInput';
import UploadInput from '../../../../components/input/uploadInput';
import TimeInput from '../../../../components/input/timeInput';
import RatingInput from '../../../../components/input/ratingInput';
import TableInput from '../../../../components/input/tableInput';
import DatepickerInput from '../../../../components/input/datepickerInput';
import { FormControl, FormField, FormItem, FormMessage } from '../../../../components/form/form';
import { FieldType } from '../../config/forms/constant';

export default function SelectAnswer({ type, name, disabled }) {
  const form = useFormContext();

  if (type === FieldType.ShortText) {
    return (
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <TextInput variant="filled" placeholder="Short answer text" disabled {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  } else if (type === FieldType.LongText) {
    return (
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <TextInput variant="filled" placeholder="Long answer text" disabled {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  } else if (
    type === FieldType.Checkboxes ||
    type === FieldType.MultipleChoices ||
    type === FieldType.Dropdown
  ) {
    return (
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <OptionInput
                disabled={disabled}
                items={field.value}
                onChange={(value, index) => {
                  const fieldName = `${name}.${index}.text`;
                  form.setValue(fieldName, value);
                }}
                onAdd={() => {
                  form.setValue(name, [
                    ...form.watch(name),
                    {
                      id: Math.random(),
                      text: '',
                    },
                  ]);
                }}
                onRemove={(id) => {
                  form.setValue(
                    name,
                    field.value.filter((item) => item.id !== id),
                  );
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  } else if (type === FieldType.Image) {
    return (
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <UploadInput
                disabled
                title="Choose/drop file max 5MB
jpg/png format"
                onChange={(acceptedFiles) => {
                  field.onChange(acceptedFiles);
                }}
                // max size 5 MB
                maxSize={5 * 1024 * 1024}
                // only accept jpg and png
                accept={{
                  'image/png': [],
                  'image/jpeg': [],
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  } else if (type === FieldType.Date) {
    return (
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <DatepickerInput disabled value={field.value} onChange={field.onChange} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  } else if (type === FieldType.FileUpload) {
    return (
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <UploadInput
                disabled
                title="Choose/drop file max 5MB .pdf format"
                onChange={(acceptedFiles) => {
                  field.onChange(acceptedFiles);
                }}
                // max size 5 MB
                maxSize={5 * 1024 * 1024}
                // only accept pdf
                accept={{
                  'application/pdf': [],
                }}
                type="file"
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  } else if (type === FieldType.Time) {
    return (
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <TimeInput
                disabled
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  } else if (type === FieldType.Table) {
    return (
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <TableInput
                disabled={disabled}
                items={field.value}
                onTextChange={(value, index) => {
                  const fieldName = `${name}.${index}.text`;
                  form.setValue(fieldName, value);
                }}
                onAdd={() => {
                  if (disabled) return;
                  form.setValue(name, [
                    ...form.watch(name),
                    {
                      id: Math.random(),
                      text: '',
                    },
                  ]);
                }}
                onSelectChange={(value, index) => {
                  const fieldName = `${name}.${index}.type`;
                  form.setValue(fieldName, value);
                }}
                onRemove={(id) => {
                  if (disabled) return;
                  form.setValue(
                    name,
                    field.value.filter((item) => item.id !== id),
                  );
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  } else if (type === FieldType.Rating) {
    return (
      <FormField
        control={form.control}
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <RatingInput
                disabled={disabled}
                value={field.value[0]?.text}
                onChange={(val) => {
                  const fieldName = `${name}.0.text`;
                  form.setValue(fieldName, val);
                }}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  } else {
    return null;
  }
}
