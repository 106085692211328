import React from 'react';
import { useSelector } from 'react-redux';
import {
  FormInfoContainer,
  FormInfoDescription,
  FormInfoDescriptionTitle,
  FormInfoTitle,
} from './FormInfo';
import CircleIcon from '../../../../assets/svg-icons/CircleIcon';

export default function FormListInfo() {
  const { forms } = useSelector((state) => state.form);

  return (
    <div className="flex items-center gap-4 mt-4 mb-6">
      <FormInfoContainer>
        <FormInfoTitle>{forms.total_forms || 0}</FormInfoTitle>
        <FormInfoDescription>
          <CircleIcon className="text-theme-color-primary-alpha-primary" />

          <FormInfoDescriptionTitle>Total Form</FormInfoDescriptionTitle>
        </FormInfoDescription>
      </FormInfoContainer>

      <FormInfoContainer>
        <FormInfoTitle>{forms.request_count || 0}</FormInfoTitle>
        <FormInfoDescription>
          <CircleIcon className="text-theme-color-primary-alpha-secondary" />

          <FormInfoDescriptionTitle>Request form</FormInfoDescriptionTitle>
        </FormInfoDescription>
      </FormInfoContainer>

      <FormInfoContainer>
        <FormInfoTitle>{forms.survey_count || 0}</FormInfoTitle>
        <FormInfoDescription>
          <CircleIcon className="text-darkGrey" />

          <FormInfoDescriptionTitle>Survey form</FormInfoDescriptionTitle>
        </FormInfoDescription>
      </FormInfoContainer>
    </div>
  );
}
