import React, { forwardRef } from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import DownIcon from '../../assets/svg-icons/DownIcon';
import { cn } from '../../utils/utilClassNames';

const Accordion = AccordionPrimitive.Root;

const AccordionItem = forwardRef(({ className, ...props }, ref) => {
  return <AccordionPrimitive.Item ref={ref} className={cn(className)} {...props} />;
});
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = forwardRef(({ className, children, ...props }, ref) => {
  return (
    <AccordionPrimitive.Header className="flex mb-0">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          'flex items-center justify-between text-sm font-medium transition-all text-left [&[data-state=open]>svg]:rotate-180',
          className,
        )}
        {...props}
      >
        {children}

        <DownIcon className="shrink-0 transition-transform duration-200" />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  );
});
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = forwardRef(({ className, children, ...props }, ref) => {
  return (
    <AccordionPrimitive.Content
      ref={ref}
      className="text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down flex-1"
      {...props}
    >
      <div className={cn('pb-4 pt-0', className)}>{children}</div>
    </AccordionPrimitive.Content>
  );
});
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
