import React from 'react';

export default function RadioIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <g fill="currentColor" clipPath="url(#a)">
        <g clipPath="url(#b)">
          <path d="M7.998 4.4a3.61 3.61 0 0 0-3.6 3.6c0 1.98 1.62 3.6 3.6 3.6s3.6-1.62 3.6-3.6-1.62-3.6-3.6-3.6Z" />
          <path d="M8 1.7c3.51 0 6.3 2.79 6.3 6.3s-2.79 6.3-6.3 6.3S1.7 11.51 1.7 8 4.49 1.7 8 1.7Zm0-.9C4.04.8.8 4.04.8 8s3.24 7.2 7.2 7.2 7.2-3.24 7.2-7.2S11.96.8 8 .8Z" />
        </g>
        <g clipPath="url(#c)">
          <path d="M7.998 4.4a3.61 3.61 0 0 0-3.6 3.6c0 1.98 1.62 3.6 3.6 3.6s3.6-1.62 3.6-3.6-1.62-3.6-3.6-3.6Z" />
          <path d="M8 1.7c3.51 0 6.3 2.79 6.3 6.3s-2.79 6.3-6.3 6.3S1.7 11.51 1.7 8 4.49 1.7 8 1.7Zm0-.9C4.04.8.8 4.04.8 8s3.24 7.2 7.2 7.2 7.2-3.24 7.2-7.2S11.96.8 8 .8Z" />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
        <clipPath id="b">
          <path fill="#fff" d="M.8.8h14.4v14.4H.8z" />
        </clipPath>
        <clipPath id="c">
          <path fill="#fff" d="M.8.8h14.4v14.4H.8z" />
        </clipPath>
      </defs>
    </svg>
  );
}
