import React from 'react';
import PropTypes from 'prop-types';

export default function Switch({ onChange, checked, name, disabled }) {
  return (
    <label className="weekly-frequency-switch black-switch m-0">
      <input
        id="checkbox-toggle"
        type="checkbox"
        onChange={onChange}
        checked={checked}
        name={name}
        disabled={disabled}
      />
      <span className="weekly-frequency-slider" />
    </label>
  );
}

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
};
