import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getRemindersByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/reminder/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of Reminder by its company ID failed",
    res
  );
};
const getRemindersByPagination = async (data) => {
  const res = await http.get(
    `${BASE_URL}/reminder/?search=${data.searchValue}&page=${data.currentPage}&page_size=${data.rowPerPage}&start_date=${data.startDate}&end_date=${data.endDate}`
  );
  return assert(
    res,
    res.data,
    "Retrieval of Reminder by its company ID failed",
    res
  );
};

const getReminderSummary = async () => {
  const res = await http.get(
    `${BASE_URL}/reminder-summary/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of Reminder by its ID failed",
    res
  );
};
const getReminderById = async (ReminderId) => {
  const res = await http.get(
    `${BASE_URL}/reminder/${ReminderId}/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of Reminder by its ID failed",
    res
  );
};

const createReminder = async (data) => {
  const res = await http.post(
    `${BASE_URL}/reminder/`, data
  );

  return assert(res, res.data, "Creation of Reminder failed", res);
};

const updateReminder = async (data) => {
  const res = await http.put(
    `${BASE_URL}/reminder/${data.id}/`, data
  );
  return assert(res, res.data, "Update of Reminder failed", res);
};
const patchReminder = async (data) => {
  const res = await http.patch(
    `${BASE_URL}/reminder/${data.id}/`, data
  );
  return assert(res, res.data, "Update of Reminder failed", res);
};

const deleteReminder = async (reminderId) => {
  const res = await http.delete(
    `${BASE_URL}/reminder/${reminderId}/`
  );
  return assert(res, reminderId, "Deletion of Reminder failed", res);
};
const deleteReminderAttachment = async (attachId) => {
  const res = await http.delete(`${BASE_URL}/reminder-attachment/${attachId}/`);
  return assert(res, attachId, 'Deletion of WorkOrder failed', res);
};
const ReminderService = {
  getRemindersByAuthentication,
  getRemindersByPagination,
  getReminderById,
  createReminder,
  updateReminder,
  patchReminder,
  deleteReminder,
  deleteReminderAttachment,
  getReminderSummary
};

export default ReminderService;
