/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { cn } from '../../../../utils/utilClassNames';
import Switch from '../../../../components/switch/switch';
import Label from '../../../../components/label/label';
import Button from '../../../../components/button/button';
import classes from '../../../../components/assetsTable/assetsTable.module.css';
import IconVerticalBox from '../../../../assets/svg-icons/IconVerticalBox';
import { Tooltip, TooltipTrigger } from '../../../../components/tooltip/tooltipV2';
import TrashIcon from '../../../../assets/svg-icons/TrashIcon';
import {
  getFormDetailById,
  toggleAcceptingResponse,
} from '../../../../redux/features/forms/formSlice';

export default function FormSurveyDetailResponsesHeader({
  selectedTab,
  setSelectedTab,
  allColumns,
  setSelectedFilter,
  selectedFilter,
  selectedForms,
  onOpenBulkDelete,
}) {
  const [isColumnFilterOpen, setIsColumnFilterOpen] = React.useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();

  const { form } = useSelector((state) => state.form);

  const isAcceptingResponse = form?.status !== 'closed';

  const handleToggleResponse = async () => {
    try {
      const result = await dispatch(
        toggleAcceptingResponse({ formId: id, status: isAcceptingResponse ? 'closed' : 'open' }),
      );
      unwrapResult(result);
      toast.success('Toggle form accepting successfully');
      dispatch(getFormDetailById(id));
    } catch (error) {
      toast.error('Error toggling form accepting');
    }
  };

  return (
    <div className="flex items-baseline justify-between gap-2">
      <div className="flex gap-2 items-center">
        {['Responses', 'Summary', 'Activities'].map((item) => {
          return (
            <div
              onClick={() => setSelectedTab(item)}
              key={item}
              className={cn(
                'h-[42px] w-[97px] cursor-pointer rounded-[4px] flex items-center text-center justify-center font-semibold text-sm',
                selectedTab === item
                  ? 'bg-theme-color-alpha-secondary text-theme-color-secondary-main'
                  : 'bg-neutral-color-divider text-neutral-color-neutral-variant-color-main',
              )}
            >
              {item}
            </div>
          );
        })}
      </div>

      <div className="flex items-center gap-2 h-full">
        <Switch checked={isAcceptingResponse} onChange={handleToggleResponse} />
        <Label>Accepting Responses</Label>

        {selectedTab === 'Responses' && (
          <>
            <div className="relative">
              <Button
                variant="outline"
                className="h-[42px] w-[42px] p-0 !bg-neutral-variant-color-lighter"
                onClick={() => setIsColumnFilterOpen(true)}
              >
                <IconVerticalBox className="text-neutral-color-darker flex-shrink-0" />
              </Button>

              {isColumnFilterOpen && (
                <>
                  <div
                    className="tck-overlay"
                    onClick={() => setIsColumnFilterOpen(!isColumnFilterOpen)}
                  />
                  <div className={classes.newFilterTable}>
                    <div className={classes.newFilterTableCheckboxs}>
                      <p className="weekly-frequency-label ml-0">Filter column</p>
                      {/* Loop through columns data to create checkbox */}
                      {allColumns.map((column) => {
                        if (column.id !== 'delete-table-column') {
                          return (
                            <div key={column.id} className={classes.newFilterTableCheckboxs}>
                              <Switch
                                name={column.accessor}
                                checked={selectedFilter.includes(column.accessor)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedFilter([...selectedFilter, e.target.name]);
                                  } else {
                                    setSelectedFilter(
                                      selectedFilter.filter((item) => item !== e.target.name),
                                    );
                                  }
                                }}
                              />
                              <span className="weekly-frequency-label">{column.Header}</span>
                            </div>
                          );
                        }
                      })}
                      <br />
                    </div>
                  </div>
                </>
              )}
            </div>

            <Tooltip content="Bulk Delete" className="w-[83px]" side="top">
              <TooltipTrigger>
                <Button
                  variant="outline"
                  className="h-[42px] w-[42px] p-0 !bg-neutral-variant-color-lighter"
                  disabled={selectedForms?.length === 0}
                  onClick={onOpenBulkDelete}
                >
                  <TrashIcon className="text-neutral-color-darker flex-shrink-0" />
                </Button>
              </TooltipTrigger>
            </Tooltip>
          </>
        )}
      </div>
    </div>
  );
}
