/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import React, { useState, useRef } from 'react';
import { cn } from '../../utils/utilClassNames';

const Tooltip = ({ children, content, side = 'top', className, ...props }) => {
  const [visible, setVisible] = useState(false);
  const tooltipRef = useRef(null);

  const showTooltip = () => setVisible(true);
  const hideTooltip = () => setVisible(false);

  return (
    <div className="relative inline-block" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
      {children}
      {visible && (
        <div
          ref={tooltipRef}
          className={cn(
            'absolute z-[9999] text-xs shadow-md rounded-md bg-white p-[12px] transition-opacity duration-300',
            visible ? 'opacity-100' : 'opacity-0',
            side === 'top' && 'bottom-full left-1/2 transform -translate-x-1/2 mb-2',
            side === 'bottom' && 'top-full left-1/2 transform -translate-x-1/2 mt-2',
            side === 'left' && 'right-full top-1/2 transform -translate-y-1/2 mr-2',
            side === 'right' && 'left-full top-1/2 transform -translate-y-1/2 ml-2',
            className,
          )}
          {...props}
        >
          <div
            className={cn(
              'absolute w-2 h-2 bg-white transform rotate-45',
              side === 'top' && 'bottom-[-4px] left-1/2 transform -translate-x-1/2',
              side === 'bottom' && 'top-[-4px] left-1/2 transform -translate-x-1/2',
              side === 'left' && 'right-[-4px] top-1/2 transform -translate-y-1/2',
              side === 'right' && 'left-[-4px] top-1/2 transform -translate-y-1/2',
            )}
          />
          {content}
        </div>
      )}
    </div>
  );
};

const TooltipTrigger = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export { Tooltip, TooltipTrigger };
