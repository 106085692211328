import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../components/dialog/dialog';
import Button from '../../../../components/button/button';

export default function DeleteFormModal({ open, onClose, onDelete, isDeleteLoading }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Delete this form?</DialogTitle>
          <DialogDescription>This form will be permanently deleted.</DialogDescription>
        </DialogHeader>

        <DialogFooter className="gap-2">
          <Button className="w-[100px]" size="large" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button className="w-[100px]" size="large" onClick={onDelete} disabled={isDeleteLoading}>
            {isDeleteLoading ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
