
/* eslint-disable import/newline-after-import */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faPlus } from '@fortawesome/free-solid-svg-icons';
import classes from "../../assetsPage/assestsPage.module.css"
import { selectActions } from '../../../redux/features/selectOptions/selectSlice';
import { openReminderModalCreate, resetReminder, setReminder, setSearchValue, setTab } from '../../../redux/features/reminder/reminderSlice';
import { setAsset } from '../../../redux/features/asset/assetSlice';
import IconSearch from '../../../assets/svg-icons/IconSearch';


function PMHeader() {
  const dispatch = useDispatch()
  const { searchValue } = useSelector(state => state.reminder)
  const { user } = useSelector((state) => state.user);
  const handelChange = (e) => {
    dispatch(setSearchValue(e.target.value))
  }


  const handelReset = () => {
    dispatch(setReminder({}))
    dispatch(resetReminder())
    dispatch(setAsset(null))
    dispatch(selectActions.reset())
    dispatch(setTab("reminder-tab"))
    dispatch(openReminderModalCreate())
  }


  return (
      <div className={classes.assetsTop}>
        <h4 className={classes.assetsTitle}>Reminder</h4>
        <div className={classes.assetActions}>
          <div style={{
            display: 'flex',
            backgroundColor: '#F8F9F9',
            border: '1px solid #E1E1E1',
            borderRadius: '8px',
            alignItems: 'center',
            paddingRight: '16px'
            /* width: 250px; */
          }}>
            <input type="text" placeholder='Search' value={searchValue} onChange={handelChange} />
            <IconSearch/>
          </div>
          {user?.role !== "Technical Team" &&
            <button
              onClick={handelReset}
              type='button'
              className={classes.assetfaPlus}>
              <span><FontAwesomeIcon icon={faPlus} /></span>
            </button>
          }
        </div>
      </div>
  )
}

export default PMHeader