import React from 'react';
import Label from '../label/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../select/selectV2';
import StarIcon from '../../assets/svg-icons/StarIcon';

export default function RatingInput({ value, onChange, disabled }) {
  return (
    <div>
      <Label className="mb-2">Number of rating</Label>
      <Select
        onValueChange={(val) => {
          onChange(val);
        }}
        disabled={disabled}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select rating" />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {[3, 4, 5, 6, 7, 8, 9, 10]?.map((item) => {
              return (
                <SelectItem value={item} key={item}>
                  {item}
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>

      <div className="mt-4 flex gap-10 flex-wrap">
        {value &&
          Array(value)
            .fill(0)
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="flex flex-col items-center">
                <StarIcon className={disabled ? 'text-neutral-color-main' : ''} />
                <span className={disabled ? 'text-neutral-color-main' : ''}>{index + 1}</span>
              </div>
            ))}
      </div>
    </div>
  );
}
