/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { tableSelectOption } from '../../pages/form/config/forms/selectOption';
import Label from '../label/label';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../select/selectV2';
import TextInput from './textInput';
import PlusIcon from '../../assets/svg-icons/PlusIcon';
import CloseIcon from '../../assets/svg-icons/CloseIcon';
import { cn } from '../../utils/utilClassNames';

export default function TableInput({
  onTextChange,
  onAdd,
  onRemove,
  onSelectChange,
  items,
  disabled,
}) {
  const isArray = items instanceof Array;

  return (
    <div className="space-y-4">
      {isArray &&
        items.map((item, index) => {
          return (
            <div
              key={item.id}
              className="bg-neutral-variant-color-lighter py-[10px] px-4 rounded-[4px] relative"
            >
              <div className="grid md:grid-cols-2 gap-4">
                <div>
                  <Label isRequired>Column name</Label>
                  <TextInput
                    variant="filled"
                    placeholder="Add column name"
                    onChange={(e) => onTextChange(e.target.value, index)}
                    defaultValue={item.text}
                    disabled={disabled}
                  />
                </div>

                <div className="self-end">
                  <Select
                    defaultValue={item.type}
                    onValueChange={(value) => onSelectChange(value, index)}
                    disabled={disabled}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {tableSelectOption?.map((option) => {
                          return (
                            <SelectItem value={option.value} key={option.value} className="px-3">
                              {option.icon && (
                                <option.icon
                                  className={cn(
                                    disabled
                                      ? 'text-neutral-color-main'
                                      : 'text-theme-color-secondary-main',
                                    'inline-block mr-2',
                                  )}
                                />
                              )}
                              {option.label}
                            </SelectItem>
                          );
                        })}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <CloseIcon
                className={cn(
                  'absolute right-5 top-1',
                  disabled ? 'cursor-not-allowed text-neutral-color-main' : '',
                )}
                onClick={() => onRemove(item.id)}
              />
            </div>
          );
        })}

      <div className="grid md:grid-cols-2 gap-4 mt-4" onClick={onAdd}>
        <div
          className={cn(
            'border border-neutral-color-divider rounded-lg h-[42px] flex items-center justify-center gap-2',
            disabled ? 'cursor-not-allowed bg-[#e9ecefda]' : '',
          )}
        >
          <PlusIcon color={disabled ? '#979ba1' : undefined} />
          <span
            className={cn(
              'text-neutral-color-dark font-semibold',
              disabled ? 'text-[#979ba1]' : '',
            )}
          >
            Add new column
          </span>
        </div>
      </div>
    </div>
  );
}
