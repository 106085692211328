/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';
import { listFormColumns } from '../config/tables/columns';

export const useFilter = ({
  setIsDeleteFormModalOpen,
  setIsShareFormModalOpen,
  setIsDuplicateFormModalOpen,
}) => {
  const allColumns = listFormColumns({
    setIsDeleteFormModalOpen,
    setIsShareFormModalOpen,
    setIsDuplicateFormModalOpen,
  });

  const [selectedFilter, setSelectedFilter] = useState(
    allColumns?.map((item) => item.accessor) || [],
  );

  const filteredColumns = React.useMemo(() => {
    return allColumns.filter((item) => selectedFilter.includes(item.accessor));
  }, [selectedFilter]);

  return {
    filteredColumns,
    selectedFilter,
    setSelectedFilter,
    allColumns,
  };
};
