import React from 'react';

export default function EyeIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={12} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeWidth={1.75}
        d="M1.94 6.782a1.872 1.872 0 0 1 0-1.563 6.668 6.668 0 0 1 12.122 0 1.871 1.871 0 0 1 0 1.563 6.668 6.668 0 0 1-12.122 0Z"
      />
      <path stroke="currentColor" strokeWidth={1.75} d="M10 6a2 2 0 1 1-3.999 0 2 2 0 0 1 4 0Z" />
    </svg>
  );
}
