import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import moment from 'moment';

import Pagination from '../../components/pagination/pagination';
import FormFilter from './components/FormFilter';
import FormListHeader from './components/FormList/FormListHeader';
import FormListTab from './components/FormList/FormListTab';
import { useFilter } from './hooks/useFilter';
import FormListInfo from './components/Form/FormListInfo';
import ShareFormModal from './components/Modal/ShareFormModal';
import DeleteFormModal from './components/Modal/DeleteFormModal';
import {
  bulkDeleteFormByIds,
  deleteFormById,
  duplicateFormById,
  getFormCategoryList,
  getFormList,
  setCurrentPage,
  setRowPerPage,
} from '../../redux/features/forms/formSlice';
import Table from '../../components/crudTable/crudTable';
import DuplicateFormModal from './components/Modal/DuplicateFormModal';
import BulkDeleteFormModal from './components/Modal/BulkDeleteFormModal';
import Loader from '../../components/loader/loader';

export default function Form() {
  const [isShareFormModalOpen, setIsShareFormModalOpen] = React.useState(false);
  const [isDeleteFormModalOpen, setIsDeleteFormModalOpen] = React.useState(false);
  const [isDuplicateFormModalOpen, setIsDuplicateFormModalOpen] = React.useState(false);
  const [isBulkDeleteFormModalOpen, setIsBulkDeleteFormModalOpen] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const { filteredColumns, setSelectedFilter, selectedFilter, allColumns } = useFilter({
    setIsDeleteFormModalOpen,
    setIsShareFormModalOpen,
    setIsDuplicateFormModalOpen,
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const {
    forms,
    rowPerPage,
    currentPage,
    isFormDeleteLoading,
    selectedFormId,
    isFormDuplicateLoading,
    isFormBulkDeleteLoading,
    filterCategory,
    filterFormType,
    filterStatus,
    filterCreatedOnDateState,
    filterPublishedOnDateState,
    isFormGetLoading,
    searchListValue,
    filterPublishedBy,
  } = useSelector((state) => state.form);

  const getFormData = () => {
    dispatch(
      getFormList({
        form_type: filterFormType,
        status: filterStatus,
        category: filterCategory,
        created_at_start: filterCreatedOnDateState?.find((item) => item.startDate)?.startDate
          ? moment(filterCreatedOnDateState?.find((item) => item.startDate)?.startDate).format(
              'YYYY-MM-DD',
            )
          : '',
        created_at_end: filterCreatedOnDateState?.find((item) => item.startDate)?.startDate
          ? moment(filterCreatedOnDateState?.find((item) => item.endDate)?.endDate).format(
              'YYYY-MM-DD',
            )
          : '',
        published_on_start: filterPublishedOnDateState?.find((item) => item.startDate)?.startDate
          ? moment(filterPublishedOnDateState?.find((item) => item.startDate)?.startDate).format(
              'YYYY-MM-DD',
            )
          : '',
        published_on_end: filterPublishedOnDateState?.find((item) => item.startDate)?.startDate
          ? moment(filterPublishedOnDateState?.find((item) => item.endDate)?.endDate).format(
              'YYYY-MM-DD',
            )
          : '',
        page: currentPage,
        page_size: rowPerPage,
        title: searchListValue,
        created_by_id: filterPublishedBy.map((author) => author.value).join(','),
      }),
    );
  };

  useEffect(() => {
    dispatch(getFormCategoryList());
  }, []);

  useEffect(() => {
    getFormData();
  }, [currentPage, rowPerPage, searchListValue]);

  const onDeleteFormById = async () => {
    try {
      const result = await dispatch(deleteFormById(selectedFormId));
      unwrapResult(result);
      toast.success('Form deleted successfully');
      getFormData();
      setIsDeleteFormModalOpen(false);
    } catch (error) {
      toast.error('Error deleting form');
    }
  };

  const onDuplicateFormById = async () => {
    try {
      const result = await dispatch(duplicateFormById(selectedFormId));
      unwrapResult(result);
      toast.success('Form duplicated successfully');
      getFormData();
      setIsDuplicateFormModalOpen(false);
    } catch (error) {
      toast.error('Error duplicating form');
    }
  };

  const onBulkDeleteFormByIds = async () => {
    const formIds = selectedRows.map((row) => row.id);
    try {
      const result = await dispatch(bulkDeleteFormByIds({ formIds }));
      unwrapResult(result);
      toast.success('Selected forms deleted successfully');
      getFormData();
      setIsBulkDeleteFormModalOpen(false);
    } catch (error) {
      toast.error('Error deleting selected forms');
    }
  };

  return (
    <div className="p-4">
      <FormListHeader getFormData={getFormData} />

      {isFormGetLoading ? (
        <Loader />
      ) : (
        <>
          <FormListTab />

          <FormListInfo />

          <FormFilter
            allColumns={allColumns}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
            onOpenBulkDelete={() => setIsBulkDeleteFormModalOpen(true)}
            selectedForms={selectedRows}
            getFormData={getFormData}
          />

          <Table
            columns={filteredColumns}
            data={forms?.results || []}
            onRowClick={(original) => {
              history.push(`/forms/detail/${original.id}`);
            }}
            onSelectionChange={(selectedFlatRows) => {
              setSelectedRows(selectedFlatRows);
            }}
          />

          <div className="h-[1px] w-full bg-neutral-color-divider mb-4" />
          <Pagination
            totalRows={forms.count}
            rowPerPage={rowPerPage}
            pageChangeHandler={setCurrentPage}
            changeRowsPerPage={setRowPerPage}
            currentPage={currentPage}
          />
        </>
      )}

      <ShareFormModal open={isShareFormModalOpen} onClose={() => setIsShareFormModalOpen(false)} />

      <DeleteFormModal
        open={isDeleteFormModalOpen}
        onClose={() => setIsDeleteFormModalOpen(false)}
        onDelete={onDeleteFormById}
        isDeleteLoading={isFormDeleteLoading}
      />

      <DuplicateFormModal
        open={isDuplicateFormModalOpen}
        onClose={() => setIsDuplicateFormModalOpen(false)}
        onDuplicate={onDuplicateFormById}
        isDuplicateLoading={isFormDuplicateLoading}
      />

      <BulkDeleteFormModal
        open={isBulkDeleteFormModalOpen}
        onClose={() => setIsBulkDeleteFormModalOpen(false)}
        onDelete={onBulkDeleteFormByIds}
        isDeleting={isFormBulkDeleteLoading}
      />
    </div>
  );
}
