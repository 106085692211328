import React from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncDebounce } from 'react-table';

import HeaderPage from '../../../../components/headerPage/headerPage';
import AddNewFormModal from '../Modal/AddNewFormModal';
import { setSearchListValue } from '../../../../redux/features/forms/formSlice';

function FormListHeader({ getFormData }) {
  const [isCreateNewFormModalOpen, setIsCreateNewFormModalOpen] = React.useState(false);

  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = React.useState('');

  const debounceSearch = useAsyncDebounce((value) => {
    dispatch(setSearchListValue(value));
  }, 500);
  const handleChange = (e) => {
    setSearchValue(e.target.value);
    debounceSearch(e.target.value);
  };

  return (
    <>
      <HeaderPage
        title="Form"
        search={{
          value: searchValue,
          onChange: handleChange,
          placeholder: 'Search',
        }}
        handleAdd={() => setIsCreateNewFormModalOpen(true)}
      />

      <AddNewFormModal
        open={isCreateNewFormModalOpen}
        onClose={() => setIsCreateNewFormModalOpen(false)}
        getFormData={getFormData}
      />
    </>
  );
}

export default FormListHeader;
