import React from 'react';
import { cn } from '../../utils/utilClassNames';

const variantStyles = {
  solid:
    '!bg-white border-1 !border-neutral-color-divider disabled:!bg-neutral-color-theme-color-light',
  filled: '!bg-neutral-color-theme-color-light border-1 !border-neutral-color-divider',
  ghost: '!bg-white !border-none !px-0',
};

const sizeStyles = {
  regular: '!h-[42px] text-[14px] leading-[28px]',
  small: '!h-5 text-sm p-0',
};

const prefixStyles = {
  true: '!pl-9',
};

const suffixStyles = {
  true: '!pr-[34px]',
};

const TextInput = React.forwardRef(
  (
    {
      name,
      value,
      placeholder,
      prefix,
      id,
      onChange,
      className,
      type = 'text',
      size = 'regular',
      variant = 'solid',
      containerClassName,
      suffix,
      ...props
    },
    ref,
  ) => {
    const variantClass = variantStyles[variant] || variantStyles.solid;
    const sizeClass = sizeStyles[size] || sizeStyles.regular;
    const prefixClass = prefix ? prefixStyles.true : '';
    const suffixClass = suffix ? suffixStyles.true : '';

    return (
      <div className={cn('relative h-10 w-full', containerClassName)}>
        {prefix && (
          <div
            data-prefix
            className={cn(
              'absolute top-1/2 -translate-y-1/2 translate-x-3 transform text-body-small',
            )}
          >
            {prefix}
          </div>
        )}
        <input
          ref={ref}
          name={name}
          type={type}
          className={cn(
            'shadow-none form-control',
            variantClass,
            sizeClass,
            prefixClass,
            suffixClass,
            className,
          )}
          placeholder={placeholder}
          id={id}
          value={value}
          onChange={onChange}
          {...props}
        />
        {suffix && (
          <div data-suffix className="absolute right-3 top-1/2 -translate-y-1/2 transform">
            {suffix}
          </div>
        )}
      </div>
    );
  },
);

export default TextInput;
