/* eslint-disable max-len */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import reminderService from './reminderService';
import { smartTryCatch } from '../../../utils';

const initState = {
  // data
  reminders: [],
  reminder: {},
  reminderSummary: null,

  // tab
  tab: 'reminder-tab',
  searchValue: '',
  // fields
  reminderName: '',
  ticketType: '',
  current: '',
  title: '',
  description: '',
  priority: '',
  category: '',
  due_finish: '',
  startService: '',
  start_date: '',
  completed_date: '',
  assigned_to: [],
  asset: '',
  location: '',
  time: '',
  status: '',
  interval: '',
  frequency: '',
  unit: '',
  reading_attachment: null,
  reading_attachment_file: null,
  initial_reading: '',
  attachments: [],
  files: [],
  isScheduleEnabled: true,
  showReminderForm: false,
  startDate: '',
  endDate: '',
  timeGap: null,
  reminderTiming: '',

  // filters
  filterStartDate: '',
  filterEndDate: '',
  dateFilterState: [
    {
      startDate: '',
      endDate: '',
      key: 'selection',
    },
  ],
  // pagination
  currentPage: 1,
  rowPerPage: 10,

  // modal
  reminderModalCreate: false,
  reminderModalUpdate: false,
  reminderModalDelete: false,

  // success
  isReminderGetSuccess: false,
  isReminderDeleteSuccess: false,
  isReminderCreateSuccess: false,
  isReminderUpdateSuccess: false,
  isDeleteReminderAttachmentSuccess: false,
  // loading
  isReminderCreateLoading: false,
  isReminderDeleteLoading: false,
  isReminderGetLoading: false,
  isReminderUpdateLoading: false,

  // error
  isReminderCreateError: false,
  isReminderGetError: false,
  isReminderDeleteError: false,
  isReminderUpdateError: false,
};

export const createReminder = createAsyncThunk('createReminder', async (data, thunkAPI) => {
  const res = await smartTryCatch(reminderService.createReminder, data, thunkAPI);
  return res;
});

export const getRemindersByAuthentication = createAsyncThunk(
  'getRemindersByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(reminderService.getRemindersByAuthentication, data, thunkAPI);
    return res;
  },
);
export const getRemindersByPagination = createAsyncThunk(
  'getRemindersByPagination',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(reminderService.getRemindersByPagination, data, thunkAPI);
    return res;
  },
);

export const getReminderById = createAsyncThunk('getReminderById', async (data, thunkAPI) => {
  const res = await smartTryCatch(reminderService.getReminderById, data, thunkAPI);
  return res;
});

export const deleteReminder = createAsyncThunk('deleteReminder', async (data, thunkAPI) => {
  const res = await smartTryCatch(reminderService.deleteReminder, data, thunkAPI);
  return res;
});
export const deleteReminderAttachment = createAsyncThunk(
  'deleteReminderAttachment',
  async (data, thunkAPI) => {
    const deleteReminderAttachmentRES = await smartTryCatch(
      reminderService.deleteReminderAttachment,
      data,
      thunkAPI,
    );
    return deleteReminderAttachmentRES;
  },
);
export const updateReminder = createAsyncThunk('updateReminder', async (data, thunkAPI) => {
  const res = await smartTryCatch(reminderService.updateReminder, data, thunkAPI);
  return res;
});
export const getReminderSummary = createAsyncThunk('getReminderSummary', async (data, thunkAPI) => {
  const res = await smartTryCatch(reminderService.getReminderSummary, data, thunkAPI);
  return res;
});
export const patchReminder = createAsyncThunk('patchReminder', async (data, thunkAPI) => {
  const res = await smartTryCatch(reminderService.patchReminder, data, thunkAPI);
  return res;
});

export const reminderSlice = createSlice({
  name: 'reminder',
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.isReminderCreateError = false;
      state.isReminderCreateSuccess = false;
      state.isReminderCreateLoading = false;
      state.isReminderGetError = false;
      state.isReminderGetSuccess = false;
      state.isReminderGetLoading = false;
      state.isReminderDeleteError = false;
      state.isReminderDeleteSuccess = false;
      state.isReminderDeleteLoading = false;
      state.isReminderUpdateError = false;
      state.isReminderUpdateSuccess = false;
      state.isReminderUpdateLoading = false;
      state.isDeleteReminderAttachmentSuccess = false;
    },
    openReminderModalCreate: (state) => {
      state.reminderModalCreate = true;
    },
    closeReminderModalCreate: (state) => {
      state.reminderModalCreate = false;
      state.attachments = [];
    },
    openReminderModalUpdate: (state) => {
      state.reminderModalUpdate = true;
    },
    closeReminderModalUpdate: (state) => {
      state.reminderModalUpdate = false;
      state.files = [];
      state.attachments = [];
    },
    openReminderModalDelete: (state) => {
      state.reminderModalDelete = true;
    },
    closeReminderModalDelete: (state) => {
      state.reminderModalDelete = false;
    },
    setTab: (state, action) => {
      state.tab = action.payload;
    },
    setTicketType: (state, action) => {
      state.ticketType = action.payload;
    },
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setPriority: (state, action) => {
      state.priority = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setDueFinish: (state, action) => {
      state.due_finish = action.payload;
    },
    setDueStart: (state, action) => {
      state.startService = action.payload;
    },
    setAssignedTo: (state, action) => {
      state.assigned_to = action.payload;
    },
    setLocation: (state, action) => {
      state.location = Number(action.payload);
    },
    setAsset: (state, action) => {
      state.asset = Number(action.payload);
    },
    setInterval: (state, action) => {
      state.interval = action.payload;
    },
    setFrequency: (state, action) => {
      state.frequency = action.payload;
    },
    setUnit: (state, action) => {
      state.unit = action.payload;
    },

    setTime: (state, action) => {
      state.time = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setStartedDate: (state, action) => {
      state.start_date = action.payload;
    },
    setCompletedDate: (state, action) => {
      state.completed_date = action.payload;
    },

    setReadingAttachment: (state, action) => {
      state.reading_attachment = action.payload;
    },
    setReadingAttachmentFile: (state, action) => {
      state.reading_attachment_file = action.payload;
    },
    setInitialReading: (state, action) => {
      state.initial_reading = action.payload;
    },
    addAttachment: (state, action) => {
      state.attachments.push(...action.payload);
    },
    setFiles: (state, action) => {
      state.files = [...state.files, ...action.payload];
    },
    getAttachment: (state, action) => {
      state.attachments = action.payload;
    },

    setReminder: (state, action) => {
      state.reminder = action.payload;
    },
    setReminderName: (state, action) => {
      state.reminderName = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setIsScheduleEnabled: (state, action) => {
      state.isScheduleEnabled = action.payload;
    },
    setFilterStartDate: (state, action) => {
      state.filterStartDate = action.payload;
    },
    setFilterEndDate: (state, action) => {
      state.filterEndDate = action.payload;
    },
    setDateFilterState: (state, action) => {
      state.dateFilterState = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setRowPerPage: (state, action) => {
      state.rowPerPage = action.payload;
    },
    setShowReminderForm: (state, action) => {
      state.showReminderForm = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setTimeGap: (state, action) => {
      state.timeGap = action.payload;
    },
    setReminderTiming: (state, action) => {
      state.reminderTiming = action.payload;
    },
    resetReminder: (state) => {
      state.ticketType = '';
      state.current = '';
      state.title = '';
      state.description = '';
      state.priority = '';
      state.category = '';
      state.due_finish = '';
      state.startService = '';
      state.assigned_to = [];
      state.asset = '';
      state.location = '';
      state.time = '';
      state.status = '';
      state.start_date = '';
      state.completed_date = '';
      state.interval = '';
      state.frequency = '';
      state.unit = '';
      state.reading_attachment = null;
      state.reading_attachment_file = null;
      state.initial_reading = '';
      state.attachments = [];
      state.files = [];
      state.isScheduleEnabled = true;
      state.reminderModalDelete = false;
      state.showReminderForm = false;
      state.startDate = '';
      state.endDate = '';
      state.timeGap = null;
      state.reminderTiming = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRemindersByAuthentication.pending, (state) => {
        state.isReminderGetLoading = true;
        state.isReminderGetSuccess = false;
        state.isReminderGetError = false;
      })
      .addCase(getRemindersByAuthentication.fulfilled, (state, action) => {
        state.isReminderGetLoading = false;
        state.isReminderGetSuccess = true;
        state.isReminderGetError = false;
        state.reminders = action.payload;
      })
      .addCase(getRemindersByAuthentication.rejected, (state) => {
        state.isReminderGetLoading = false;
        state.isReminderGetSuccess = false;
        state.isReminderGetError = true;
      })
      .addCase(getRemindersByPagination.pending, (state) => {
        state.isReminderGetLoading = true;
        state.isReminderGetSuccess = false;
        state.isReminderGetError = false;
      })
      .addCase(getRemindersByPagination.fulfilled, (state, action) => {
        state.isReminderGetLoading = false;
        state.isReminderGetSuccess = true;
        state.isReminderGetError = false;
        state.reminders = action.payload;
      })
      .addCase(getRemindersByPagination.rejected, (state) => {
        state.isReminderGetLoading = false;
        state.isReminderGetSuccess = false;
        state.isReminderGetError = true;
      })
      .addCase(getReminderSummary.pending, (state) => {
        state.isReminderGetLoading = true;
        state.isReminderGetSuccess = false;
        state.isReminderGetError = false;
      })
      .addCase(getReminderSummary.fulfilled, (state, action) => {
        state.isReminderGetLoading = false;
        state.isReminderGetSuccess = true;
        state.isReminderGetError = false;
        state.reminderSummary = action.payload;
      })
      .addCase(getReminderSummary.rejected, (state) => {
        state.isReminderGetLoading = false;
        state.isReminderGetSuccess = false;
        state.isReminderGetError = true;
      })
      .addCase(getReminderById.pending, (state) => {
        state.isReminderGetLoading = true;
        state.isReminderGetSuccess = false;
        state.isReminderGetError = false;
      })
      .addCase(getReminderById.fulfilled, (state, action) => {
        state.isReminderGetLoading = false;
        state.isReminderGetSuccess = true;
        state.isReminderGetError = false;
        state.reminder = action.payload;
      })
      .addCase(getReminderById.rejected, (state) => {
        state.isReminderGetLoading = false;
        state.isReminderGetSuccess = false;
        state.isReminderGetError = true;
      })
      .addCase(createReminder.pending, (state) => {
        state.isReminderCreateLoading = true;
        state.isReminderCreateSuccess = false;
        state.isReminderCreateError = false;
      })
      .addCase(createReminder.fulfilled, (state, action) => {
        state.isReminderCreateLoading = false;
        state.isReminderCreateSuccess = true;
        state.isReminderCreateError = false;
        state.reminders.results = [action.payload, ...state.reminders.results];
        state.attachments = [];
      })
      .addCase(createReminder.rejected, (state) => {
        state.isReminderCreateLoading = false;
        state.isReminderCreateSuccess = false;
        state.isReminderCreateError = true;
      })
      .addCase(deleteReminder.pending, (state) => {
        state.isReminderDeleteLoading = true;
        state.isReminderDeleteSuccess = false;
        state.isReminderDeleteError = false;
      })
      .addCase(deleteReminder.fulfilled, (state, action) => {
        state.isReminderDeleteLoading = false;
        state.isReminderDeleteSuccess = true;
        state.isReminderDeleteError = false;
        state.reminders.results = state.reminders.results.filter(
          (reminder) => reminder.id !== action.payload,
        );
      })
      .addCase(deleteReminder.rejected, (state) => {
        state.isReminderDeleteLoading = false;
        state.isReminderDeleteSuccess = false;
        state.isReminderDeleteError = true;
      })
      .addCase(updateReminder.pending, (state) => {
        state.isReminderUpdateLoading = true;
        state.isReminderUpdateSuccess = false;
        state.isReminderUpdateError = false;
      })
      .addCase(updateReminder.fulfilled, (state, action) => {
        state.isReminderUpdateLoading = false;
        state.isReminderUpdateSuccess = true;
        state.isReminderUpdateError = false;
        state.reminders.results = state.reminders.results.map((reminder) =>
          reminder.id === action.payload.id ? action.payload : reminder,
        );
        state.reminder = action.payload;
        state.files = [];
      })
      .addCase(updateReminder.rejected, (state) => {
        state.isReminderUpdateLoading = false;
        state.isReminderUpdateSuccess = false;
        state.isReminderUpdateError = true;
      })
      .addCase(patchReminder.pending, (state) => {
        state.isReminderUpdateLoading = true;
        state.isReminderUpdateSuccess = false;
        state.isReminderUpdateError = false;
      })
      .addCase(patchReminder.fulfilled, (state, action) => {
        state.isReminderUpdateLoading = false;
        state.isReminderUpdateSuccess = true;
        state.isReminderUpdateError = false;
        state.reminders.results = state.reminders.results.map((reminder) =>
          reminder.id === action.payload.id ? action.payload : reminder,
        );
        state.reminder = action.payload;
        state.files = [];
      })
      .addCase(patchReminder.rejected, (state) => {
        state.isReminderUpdateLoading = false;
        state.isReminderUpdateSuccess = false;
        state.isReminderUpdateError = true;
      })
      .addCase(deleteReminderAttachment.pending, (state) => {
        state.isDeleteReminderAttachmentSuccess = false;
      })
      .addCase(deleteReminderAttachment.fulfilled, (state, action) => {
        state.isDeleteReminderAttachmentSuccess = true;
        state.attachments = state.attachments.filter(
          (attachment) => attachment.id !== action.payload,
        );
      })
      .addCase(deleteReminderAttachment.rejected, (state) => {
        state.isDeleteReminderAttachmentSuccess = false;
      });
  },
});

export const {
  reset,
  resetReminder,
  openReminderModalCreate,
  closeReminderModalCreate,
  openReminderModalUpdate,
  closeReminderModalUpdate,
  openReminderModalDelete,
  closeReminderModalDelete,
  setReminder,
  setReminderName,
  setSearchValue,
  setTab,
  setTicketType,
  setCurrent,
  setTitle,
  setDescription,
  setPriority,
  setCategory,
  setDueFinish,
  setDueStart,
  setAssignedTo,
  setLocation,
  setAsset,
  setInterval,
  setFrequency,
  setUnit,
  setTime,
  setStatus,
  setStartedDate,
  setCompletedDate,
  setReadingAttachment,
  setInitialReading,
  addAttachment,
  setFiles,
  getAttachment,
  setReadingAttachmentFile,
  setIsScheduleEnabled,
  setCurrentPage,
  setRowPerPage,
  setShowReminderForm,
  setStartDate,
  setEndDate,
  setTimeGap,
  setReminderTiming,
  setFilterStartDate,
  setFilterEndDate,
  setDateFilterState,
} = reminderSlice.actions;

export default reminderSlice.reducer;
