import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Button from '../button/button';
import { cn } from '../../utils/utilClassNames';

export default function SignatureInput({
  onChange,
  className,
  height = 300,
  defaultValue,
  disabled,
  inputRef,
}) {
  const ref = useRef();
  const [isEmpty, setIsEmpty] = useState(true);

  useEffect(() => {
    if (ref.current) {
      setIsEmpty(ref?.current?.isEmpty());
    }
  }, [ref]);

  useImperativeHandle(
    inputRef,
    () => {
      return {
        clear() {
          ref.current.clear();
          setIsEmpty(ref.current.isEmpty());
        },
      };
    },
    [],
  );

  const handleEnd = () => {
    if (ref.current) {
      setIsEmpty(ref.current.isEmpty());
      const trimmedCanvas = ref.current.getTrimmedCanvas();

      onChange(trimmedCanvas.toDataURL());
    }
  };

  useEffect(() => {
    if (ref.current) {
      setIsEmpty(ref?.current?.isEmpty());
      if (defaultValue) {
        ref.current.fromDataURL(defaultValue);
        setIsEmpty(false);
      }
    }
  }, [ref, defaultValue]);

  useEffect(() => {
    if (disabled && ref.current) {
      ref.current.off(); // Disable canvas interactions
    } else if (ref.current) {
      ref.current.on(); // Enable canvas interactions
    }
  }, [disabled]);

  return (
    <div className="relative">
      <SignatureCanvas
        penColor="black"
        canvasProps={{
          height,
          className: cn(
            'sigCanvas w-full h-[300px] border border-neutral-color-divider rounded-[8px]',
            disabled ? 'bg-[#7274781F] cursor-not-allowed' : '',
            className,
          ),
        }}
        minDistance={0}
        ref={ref}
        onEnd={handleEnd}
      />

      {isEmpty ? (
        <p className="mb-0 pointer-events-none text-center absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform text-neutral-color-main">
          Sign here
        </p>
      ) : (
        <Button
          variant="outline"
          className={cn(
            'absolute bottom-4 w-[83px] h-[42px] rounded-[8px] right-4 !bg-neutral-color-theme-color-light',
            disabled ? 'hidden' : '',
          )}
          onClick={() => {
            ref.current.clear();
            setIsEmpty(ref.current.isEmpty());
            onChange('');
          }}
        >
          Clear
        </Button>
      )}
    </div>
  );
}
