/* eslint-disable no-unused-vars */
import React from 'react';
import { format, parse } from 'date-fns';

import { Badge } from '../../../../components/badge/badge';
import { toTitleCase } from '../../../../utils/text';

export default function FormSurveyTimeSummary({ field }) {
  const totalItems = field?.responses.length;

  const groupedByHour = field?.responses.reduce((acc, time) => {
    const date = parse(time, 'HH:mm', new Date()); // Parse time to Date object
    const hour = format(date, 'hh a'); // Format to 12-hour format with AM/PM (e.g., 10 AM, 2 PM)

    if (!acc[hour]) {
      acc[hour] = [];
    }

    acc[hour].push(format(date, 'hh:mm a')); // Add formatted time to group
    return acc;
  }, {});

  return (
    <div className="grid grid-cols-[300px_1fr] gap-6">
      <div>
        <div className="pb-2 mb-2 border-b border-neutral-color-divider">
          <h2 className="text-neutral-color-darker font-semibold text-md">{field?.field_label}</h2>

          <p className="text-right text-neutral-color-main">{toTitleCase(field?.field_type)}</p>
        </div>

        <div>
          <h2 className="text-neutral-color-main text-base mb-2">{totalItems} responses</h2>
        </div>
      </div>

      <div className="bg-neutral-variant-color-lighter h-[272px] flex items-center justify-center gap-4 w-full p-[44px]">
        <div className="flex items-center gap-4">
          <div className="text-center">
            <h2 className="font-semibold text-[32px] text-neutral-color-dark">{totalItems}</h2>
            <p className="text-neutral-color-main text-sm">Responses</p>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          {Object.entries(groupedByHour).map(([hour, allHours]) => {
            const splittedHour = hour.split(' ');

            const timeCount = allHours.reduce((acc, time) => {
              acc[time] = (acc[time] || 0) + 1; // Increment the count for each time
              return acc;
            }, {});
            const keys = Object.keys(timeCount);
            return (
              <div className="flex items-center">
                <div className="w-[90px] flex items-center justify-start">
                  <h2 className="text-sm text-neutral-color-dark letter-[0.25%] mb-0">{`${splittedHour[0]} : __${splittedHour[1]} `}</h2>
                  <span className="block mx-2">:</span>
                </div>

                <div className="flex gap-2 flex-wrap">
                  {keys?.map((key) => {
                    return (
                      <Badge className="h-[28px] bg-[#72747814] text-[#4A4C50] rounded-[1000px]">
                        {key}
                        <Badge className="h-[16px] ml-2 bg-neutral-color-main text-[#ffffff]">
                          {timeCount[key]}
                        </Badge>
                      </Badge>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
