import React from 'react';

export default function MailIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeWidth={2}
        d="m1.999 3.333 5.181 4.03a1.333 1.333 0 0 0 1.637 0L14 3.333m-10.534 10h9.067c.747 0 1.12 0 1.405-.145.251-.128.455-.332.583-.583.145-.285.145-.658.145-1.405V4.8c0-.747 0-1.12-.145-1.405a1.333 1.333 0 0 0-.583-.583c-.285-.145-.658-.145-1.405-.145H3.465c-.746 0-1.12 0-1.405.145-.25.128-.455.332-.583.583-.145.285-.145.658-.145 1.405v6.4c0 .747 0 1.12.145 1.405.128.251.332.455.583.583.285.145.659.145 1.405.145Z"
      />
    </svg>
  );
}
