import React from 'react';
import TextInput from './textInput';
import CloseIcon from '../../assets/svg-icons/CloseIcon';
import { cn } from '../../utils/utilClassNames';

export default function OptionInput({ items, onAdd, onRemove, onChange, disabled }) {
  const isArray = items instanceof Array;
  return (
    <>
      {isArray &&
        items.map((item, index) => {
          return (
            <div key={item.id} className="relative mb-3">
              <TextInput
                variant="filled"
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    onAdd();
                  }
                }}
                onChange={(event) => {
                  onChange(event.target.value, index);
                }}
                placeholder="Type option here"
                defaultValue={item.text}
                disabled={disabled}
              />
              {items.length > 1 && (
                <CloseIcon
                  className={cn(
                    'absolute -right-6 top-1/2 transform -translate-y-1/2',
                    disabled
                      ? 'cursor-not-allowed text-neutral-color-main'
                      : 'text-neutral-color-darker',
                  )}
                  onClick={() => onRemove(item.id)}
                />
              )}
            </div>
          );
        })}

      <p className="text-xs text-theme-color-primary-main mt-3">
        Please &quot;Enter&quot; to add new option
      </p>
    </>
  );
}
