/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function PopUpReceiveNotifModal({ 
    showModal, 
    handleClose, 
    // handleConfirm, 
    // modalTitle, 
    modalBodyText 
}) {
  return (
    <Modal show={showModal} onHide={handleClose} style={{ zIndex: 1000000}}>
      <Modal.Header closeButton>
        {/* <Modal.Title>{modalTitle}</Modal.Title> */}
        <Modal.Title>New notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalBodyText}</Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Close
        </Button>
        {/* <Button variant='danger' onClick={handleConfirm}>
          Confirm
        </Button> */}
      </Modal.Footer>
    </Modal>
  );
}

export default PopUpReceiveNotifModal;