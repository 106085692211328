import React from 'react';

import TextInput from './textInput';
import { cn } from '../../utils/utilClassNames';

export default function TimeInput({ className, ...props }) {
  return (
    <div className="relative flex items-center">
      <TextInput type="time" className={cn('custom-time-input', className)} {...props} />
      <span className="absolute left-14 text-neutral-color-dark text-sm">MYT</span>
    </div>
  );
}
