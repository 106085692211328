/* eslint-disable react/no-array-index-key */
/* eslint-disable no-else-return */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { pdf } from '@react-pdf/renderer';

import Drawer from '../../../../components/drawer/drawer';
import DrawerContent from '../../../../components/drawer/drawerContent';
import DrawerHeader from '../../../../components/drawer/drawerHeader';
import IconPrinter from '../../../workOrder/Componets/icons/IconPrinter';
import Button from '../../../../components/button/button';
import Label from '../../../../components/label/label';
import TextInput from '../../../../components/input/textInput';
import StarIcon from '../../../../assets/svg-icons/StarIcon';

import { getFormResponseDetailById } from '../../../../redux/features/forms/formSlice';
import { FieldType } from '../../config/forms/constant';
import TextArea from '../../../../components/input/textArea';
import { cn } from '../../../../utils/utilClassNames';
import Table from '../../../../components/crudTable/crudTable';
import SurveyResponsePdf from '../../pdf/SurveyResponsePdf';
import QRCode from '../../../../assets/img/inventory_qr_code.png';

function SurveyFormDetailResponseModal({ open, onClose }) {
  const { formResponseDetail, selectedFormResponseId, form } = useSelector((state) => state.form);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedFormResponseId) {
      dispatch(getFormResponseDetailById(selectedFormResponseId));
    }
  }, [selectedFormResponseId]);

  const sections = formResponseDetail?.section_responses;

  const generatePdf = async () => {
    // Generate the PDF document as a Blob
    const blob = await pdf(
      <SurveyResponsePdf
        permitId={formResponseDetail?.form_response_number}
        companyName={formResponseDetail?.submitted_by?.company}
        qrCode={QRCode}
        formName={form?.title || ''}
        sections={formResponseDetail?.section_responses}
        email={formResponseDetail?.submitted_by?.email}
      />,
    ).toBlob();

    // Create an Object URL for the Blob
    const url = URL.createObjectURL(blob);

    // Open the PDF in a new tab
    window.open(url, '_blank');
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        className: 'w-full max-w-[600px] h-full',
      }}
    >
      <DrawerHeader title={formResponseDetail?.form_response_number || ''} onClose={onClose}>
        <Button
          onClick={generatePdf}
          className="w-[42px] h-[42px] !bg-[#ffffff] mr-1"
          variant="outline"
        >
          <IconPrinter />
        </Button>
      </DrawerHeader>

      <DrawerContent>
        <form className="flex flex-col gap-4" noValidate>
          <div className="border-b border-gray pb-4">
            <h2 className="text-neutral-color-darker font-semibold text-base mb-2">
              Respondent information
            </h2>

            <div>
              <Label className="mb-2">Email</Label>
              <TextInput disabled value={formResponseDetail?.submitted_by?.email} />
            </div>
          </div>

          <div>
            <h2 className="text-neutral-color-darker font-semibold text-base mb-2">Form details</h2>

            <div className="border border-neutral-color-light rounded-lg p-4">
              <div className="pb-2 border-b border-neutral-color-divider">
                <h3 className="font-semibold text-lg text-neutral-color-darker mb-0">
                  {form?.title || ''}
                </h3>
                <p className="text-sm text-neutral-color-main mb-0">{form?.description || ''}</p>
              </div>

              {sections?.map((section, sectionIdx) => {
                return (
                  <div className="mt-2 space-y-2" key={sectionIdx}>
                    <h4 className="font-semibold text-sm text-neutral-color-darker">
                      {section.title}
                    </h4>

                    {section?.field_responses?.map((item, fieldIdx) => {
                      return (
                        <div key={fieldIdx}>
                          <Label className="mb-2">{item.field_label}</Label>
                          {/* TODO image, upload image */}
                          {(() => {
                            if (
                              item.field_type === FieldType.ShortText ||
                              item.field_type === FieldType.Checkboxes ||
                              item.field_type === FieldType.Dropdown ||
                              item.field_type === FieldType.MultipleChoices
                            ) {
                              return <TextInput value={item.value} disabled />;
                            } else if (item.field_type === FieldType.LongText) {
                              return (
                                <TextArea disabled className="!h-[126px]" value={item.value} />
                              );
                            } else if (item.field_type === FieldType.Image) {
                              return (
                                <div
                                  className={cn(
                                    'rounded-[10px] border-[0.83px] border-dashed border-gray flex items-center justify-center size-[130px]',
                                  )}
                                >
                                  <div className="relative w-full h-full">
                                    <img
                                      src={item.value}
                                      alt="submit answer"
                                      className="h-full w-full object-contain rounded"
                                    />
                                  </div>
                                </div>
                              );
                            } else if (item.field_type === FieldType.FileUpload) {
                              return (
                                <div
                                  className={cn(
                                    'rounded-[10px] border-[0.83px] border-dashed border-gray flex items-center justify-center size-[130px]',
                                  )}
                                >
                                  <div className="relative w-full h-full flex items-center justify-center flex-col">
                                    <span role="img" aria-label="document">
                                      📄
                                    </span>
                                    <p className="mb-0 text-sm">{item.content}</p>
                                  </div>
                                </div>
                              );
                            } else if (item.field_type === FieldType.Date) {
                              const dateValue = item.value
                                ? format(new Date(item.value), 'MMM, d yyyy')
                                : '-';
                              return <TextInput value={dateValue} disabled />;
                            } else if (item.field_type === FieldType.Time) {
                              const date = new Date();
                              const time = item?.value?.split(':');
                              date.setHours(time[0], time[1], 0);

                              // Format the date to include AM/PM
                              const formattedTime = format(date, 'hh:mm a');

                              return <TextInput value={formattedTime} disabled />;
                            } else if (item.field_type === FieldType.Rating) {
                              const fieldId = item.field;

                              const allFields = form?.sections
                                ?.map((sectionItem) => sectionItem.fields)
                                .flat(Infinity);

                              const selectedFields = allFields?.find(
                                (field) => field.id === fieldId,
                              );

                              const option = selectedFields?.options
                                ? JSON.parse(selectedFields.options)[0].text
                                : [];

                              const arrayOptions = Array.from({ length: option }, (_, i) => i + 1);

                              return (
                                <div className="flex items-start gap-6 text-center text-sm h-fit md:w-1/2 justify-center">
                                  {arrayOptions.map((optionItem, idx) => {
                                    return (
                                      <div key={idx}>
                                        <StarIcon
                                          className={cn(
                                            'cursor-pointer',
                                            idx < item.value
                                              ? 'text-generic-color-alpha-orange-92 fill-generic-color-alpha-orange-92'
                                              : '',
                                          )}
                                        />
                                        <p className="mb-0">{optionItem}</p>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            } else if (item.field_type === FieldType.Table) {
                              const columns = item.content || [];

                              const tableColumns = columns?.map((column) => {
                                return {
                                  Header: column.text,
                                  accessor: column.text,
                                };
                              });
                              return <Table columns={tableColumns} data={item.data} />;
                            } else {
                              return null;
                            }
                          })()}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </form>
      </DrawerContent>
    </Drawer>
  );
}

export default SurveyFormDetailResponseModal;

SurveyFormDetailResponseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
