/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react';
import { pdf } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import QRCode from '../../assets/img/inventory_qr_code.png';

import FormDetailResponsesTab from './components/FormDetail/FormDetailResponsesTab';
import FormResponseDetailFormDetailTabContent from './components/FormResponseDetail/FormResponseDetailFormDetailTabContent';
import FormResponseDetailApproverTabContent from './components/FormResponseDetail/FormResponseDetailApproverTabContent';
import FormResponseDetailApprovalTabContent from './components/FormResponseDetail/FormResponseDetailApprovalTabContent';
import FormResponseDetailActivityTabContent from './components/FormResponseDetail/FormResponseDetailActivityTabContent';
import RequestResponsePdf from './pdf/RequestResponsePdf';
import FormResponseDetailHeader from './components/FormResponseDetail/FormResponseDetailHeader';
import { FormResponseStatus } from './config/forms/constant';
import { getFormDetailById, getFormResponseDetailById } from '../../redux/features/forms/formSlice';

export default function FormResponseDetail() {
  const [selectedTab, setSelectedTab] = useState('formDetails');
  const { id, responseId } = useParams();

  const dispatch = useDispatch();
  const { formResponseDetail, form } = useSelector((state) => state.form);

  useEffect(() => {
    if (id) {
      dispatch(getFormDetailById(id));
    }

    if (responseId) {
      dispatch(getFormResponseDetailById(responseId));
    }
  }, [responseId, id]);

  const generatePdf = async () => {
    // Generate the PDF document as a Blob
    const blob = await pdf(
      <RequestResponsePdf
        permitId={formResponseDetail?.form_response_number || ''}
        companyName={formResponseDetail?.submitted_by?.company || ''}
        qrCode={QRCode}
        requestedBy={formResponseDetail?.submitted_by?.fullname || ''}
        requesterSignature={formResponseDetail?.requester_signature_url}
        status={formResponseDetail?.response_status}
        creationDate={
          formResponseDetail?.submitted_at
            ? format(new Date(formResponseDetail?.submitted_at), 'dd/MM/yyyy')
            : '-'
        }
        formName={form?.title || ''}
        sections={formResponseDetail?.section_responses}
        approvedDate={
          formResponseDetail?.approved_at
            ? format(new Date(formResponseDetail?.approved_at), 'dd/MM/yyyy')
            : '-'
        }
        startDate={
          formResponseDetail?.start_time
            ? format(new Date(formResponseDetail?.start_time), 'dd/MM/yyyy')
            : '-'
        }
        endDate={
          formResponseDetail?.end_time
            ? format(new Date(formResponseDetail?.end_time), 'dd/MM/yyyy')
            : '-'
        }
        extensionDate={
          formResponseDetail?.extension_date
            ? format(new Date(formResponseDetail?.extension_date), 'dd/MM/yyyy')
            : '-'
        }
        approvers={formResponseDetail?.approvers.map((approver) => approver.name)}
        approversSignatures={formResponseDetail?.approvers_signatures}
      />,
    ).toBlob();

    // Create an Object URL for the Blob
    const url = URL.createObjectURL(blob);

    // Open the PDF in a new tab
    window.open(url, '_blank');
  };

  const status = formResponseDetail?.status;

  return (
    <div className="max-w-screen-2xl mx-auto">
      <div className="my-4 bg-white px-4 pb-4">
        <FormResponseDetailHeader
          onClickPrint={generatePdf}
          showPrint
          disabledPrint={status !== FormResponseStatus.Closed}
        />
        <FormDetailResponsesTab setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
        {selectedTab === 'formDetails' && <FormResponseDetailFormDetailTabContent />}
        {selectedTab === 'approver' && <FormResponseDetailApproverTabContent />}
        {selectedTab === 'approval' && <FormResponseDetailApprovalTabContent />}
        {selectedTab === 'activities' && <FormResponseDetailActivityTabContent />}
      </div>
    </div>
  );
}
