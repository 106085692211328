/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IconVerticalBox from '../../../assets/svg-icons/IconVerticalBox';
import ResetIcon from '../../../assets/svg-icons/ResetIcon';
import Rotate360Icon from '../../../assets/svg-icons/Rotate360Icon';
import Button from '../../../components/button/button';
import TrashIcon from '../../../assets/svg-icons/TrashIcon';
import { Tooltip, TooltipTrigger } from '../../../components/tooltip/tooltipV2';
import AdvanceFilterModal from './Modal/AdvanceFilterModal';
import classes from '../../../components/assetsTable/assetsTable.module.css';
import Switch from '../../../components/switch/switch';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../components/select/selectV2';
import Label from '../../../components/label/label';
import { FormStatus, FormType } from '../config/forms/constant';
import { capitalizeFirstLetter } from '../../../utils/text';
import {
  getFormList,
  resetBasicFilter,
  setFilterCategory,
  setFilterFormType,
  setFilterStatus,
} from '../../../redux/features/forms/formSlice';

export default function FormFilter({
  allColumns,
  setSelectedFilter,
  selectedFilter,
  onOpenBulkDelete,
  selectedForms,
  getFormData,
}) {
  const statusOption = Object.values(FormStatus).map((status) => ({
    label: status,
    value: status,
  }));

  const typeOption = Object.values(FormType).map((type) => ({
    label: capitalizeFirstLetter(type),
    value: type,
  }));

  const [isAdvancedFilterOpen, setIsAdvancedFilterOpen] = React.useState(false);
  const [isColumnFilterOpen, setIsColumnFilterOpen] = React.useState(false);

  const { filterCategory, filterFormType, filterStatus, formCategories } = useSelector(
    (state) => state.form,
  );
  const dispatch = useDispatch();

  const onReset = () => {
    dispatch(resetBasicFilter());

    dispatch(getFormList());
  };

  const onReload = () => {
    getFormData();
  };

  return (
    <div className="justify-between flex items-end gap-4">
      <div className="flex space-between items-end w-full max-w-[800px] gap-4">
        <div className="w-full">
          <Label className="font-semibold">Category</Label>
          <Select
            value={filterCategory}
            onValueChange={(value) => {
              dispatch(setFilterCategory(value));
            }}
          >
            <SelectTrigger className="!bg-[#ffffff]">
              <SelectValue placeholder="All Category" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {formCategories?.map((option) => {
                  return (
                    <SelectItem value={option.name} key={option.id} className="px-3">
                      {option.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="w-full">
          <Label className="font-semibold">Form Type</Label>
          <Select
            value={filterFormType}
            onValueChange={(value) => {
              dispatch(setFilterFormType(value));
            }}
          >
            <SelectTrigger className="!bg-[#ffffff]">
              <SelectValue placeholder="All type" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {typeOption?.map((option) => {
                  return (
                    <SelectItem value={option.value} key={option.value} className="px-3">
                      {option.label}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="w-full">
          <Label className="font-semibold">Status</Label>
          <Select
            value={filterStatus}
            onValueChange={(value) => {
              dispatch(setFilterStatus(value));
            }}
          >
            <SelectTrigger className="!bg-[#ffffff]">
              <SelectValue placeholder="All status" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {statusOption?.map((option) => {
                  return (
                    <SelectItem value={option.value} key={option.value} className="px-3">
                      {capitalizeFirstLetter(option.label)}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <Button variant="outline" className="h-[42px] w-[94px] rounded-lg" onClick={onReset}>
          <ResetIcon />
          Reset
        </Button>

        <Button variant="outline" className="h-[42px] w-[94px] rounded-lg" onClick={onReload}>
          <Rotate360Icon />
          Reload
        </Button>
      </div>

      <div className="flex gap-3 items-center">
        <Button
          variant="primary"
          className="h-[42px] w-[123px] rounded-lg font-semibold"
          onClick={() => setIsAdvancedFilterOpen(true)}
        >
          Advance filter
        </Button>

        <div className="relative">
          <Button
            variant="outline"
            className="size-[42px] p-0"
            onClick={() => setIsColumnFilterOpen(true)}
          >
            <IconVerticalBox className="text-neutral-color-darker flex-shrink-0" />
          </Button>

          {isColumnFilterOpen && (
            <>
              <div
                className="tck-overlay"
                onClick={() => setIsColumnFilterOpen(!isColumnFilterOpen)}
              />
              <div className={classes.newFilterTable}>
                <div className={classes.newFilterTableCheckboxs}>
                  <p className="weekly-frequency-label ml-0">Filter column</p>
                  {/* Loop through columns data to create checkbox */}
                  {allColumns.map((column) => {
                    if (column.id !== 'selection') {
                      return (
                        <div key={column.id} className={classes.newFilterTableCheckboxs}>
                          <Switch
                            name={column.accessor}
                            checked={selectedFilter.includes(column.accessor)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedFilter([...selectedFilter, e.target.name]);
                              } else {
                                setSelectedFilter(
                                  selectedFilter.filter((item) => item !== e.target.name),
                                );
                              }
                            }}
                          />
                          <span className="weekly-frequency-label">{column.Header}</span>
                        </div>
                      );
                    }
                  })}
                  <br />
                </div>
              </div>
            </>
          )}
        </div>

        <Tooltip content="Bulk Delete" className="w-[83px]" side="top">
          <TooltipTrigger>
            <Button
              variant="outline"
              className="size-[42px] p-0"
              disabled={selectedForms.length === 0}
              onClick={onOpenBulkDelete}
            >
              <TrashIcon className="text-neutral-color-darker flex-shrink-0" />
            </Button>
          </TooltipTrigger>
        </Tooltip>
      </div>

      <AdvanceFilterModal
        open={isAdvancedFilterOpen}
        handleClose={() => {
          setIsAdvancedFilterOpen(false);
        }}
        getFormData={getFormData}
      />
    </div>
  );
}
