import React from 'react';

import FormPreviewHeader from '../FormPreview/FormPreviewHeader';

export default function FormRequestEnding() {
  return (
    <div>
      <FormPreviewHeader />

      <div className="bg-white rounded-2xl py-6 px-10 mx-auto space-y-4 mt-6">
        <div className="bg-neutral-color-aplha-neutral-8 rounded-[8px] px-2 py-3">
          <p className="font-semibold text-lg text-neutral-color-darker mb-0">
            Thank you. Your response has been recorded.
          </p>
        </div>

        <div className="bg-neutral-variant-color-lighter rounded-[8px] border border-neutral-color-divider">
          <p className="mb-0 text-neutral-color-main text-sm px-4 py-3 font-semibold">
            Your request will be reviewed, and you will be notified of the outcome.
          </p>
        </div>
      </div>
    </div>
  );
}
