import React from 'react';
import { useFormContext } from 'react-hook-form';

import FormPreviewHeader from '../FormPreview/FormPreviewHeader';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../../../components/form/form';
import TextInput from '../../../../components/input/textInput';
import LockIcon from '../../../../assets/svg-icons/LockIcon';

export default function FormSurveySecureAccess() {
  const form = useFormContext();

  return (
    <div className="space-y-6">
      <FormPreviewHeader />

      <div className="bg-white rounded-2xl py-6 px-10 mx-auto">
        <div className="bg-neutral-color-aplha-neutral-8 rounded-[8px] px-2 py-3">
          <p className="font-semibold text-lg text-neutral-color-darker mb-0">Secure Access</p>
        </div>

        <div className="space-y-[10px] pt-4">
          <div>
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="mb-2" isRequired>
                    Password
                  </FormLabel>
                  <FormControl>
                    <TextInput
                      className="!border-neutral-color-divider border"
                      type="password"
                      variant="filled"
                      prefix={<LockIcon />}
                      placeholder="Enter the password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
