import React from 'react';
import { cn } from '../../utils/utilClassNames';

export default function Label({ children, isRequired, className }) {
  return (
    <div className={cn('font-normal text-sm', className)}>
      {children}
      {isRequired && <span className="font-danger inline-block">*</span>}
    </div>
  );
}
