import React from 'react';

import FormPreviewHeader from '../FormPreview/FormPreviewHeader';
import FormSubmitDetailForm from './FormSubmitDetailForm';

export default function FormSubmitForm() {
  return (
    <div className="pb-2">
      <FormPreviewHeader />

      <FormSubmitDetailForm />
    </div>
  );
}
