import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../components/dialog/dialog';
import Button from '../../../../components/button/button';

export default function SaveApproveRequestModal({ open, onClose, onSaveApproval, isLoading }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Save the access?</DialogTitle>
          <DialogDescription className="mt-4">
            Are you sure you want to save? Once saved, it cannot be changed.
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="gap-2">
          <Button className="w-[100px]" size="large" variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button className="w-[100px]" size="large" onClick={onSaveApproval} disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
