import React from 'react';

export default function CloseIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={1.667}
        d="M15 5 5 15m10 0L5 5"
      />
    </svg>
  );
}
