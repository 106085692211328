import React from 'react';

export function FormInfoContainer({ children }) {
  return (
    <div className="bg-neutral-variant-color-lighter rounded-xl px-4 pt-2 pb-2 min-w-[160px]">
      {children}
    </div>
  );
}

export function FormInfoTitle({ children }) {
  return <h2 className="mb-0 text-neutral-color-dark font-bold text-[32px]">{children}</h2>;
}

export function FormInfoDescription({ children }) {
  return <div className="mt-2 flex items-center gap-2">{children}</div>;
}

export function FormInfoDescriptionTitle({ children }) {
  return <p className="mb-0 font-semibold text-neutral-color-dark text-sm">{children}</p>;
}
