import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../../../components/dialog/dialog';
import Button from '../../../../components/button/button';

export default function SaveApprovalModal({ open, onClose, onApprove, isLoading }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Save changes</DialogTitle>
          <DialogDescription className="mt-4">
            Are you sure you want to save the changes Once saved, it cannot be changed.
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="gap-2">
          <Button
            disabled={isLoading}
            className="w-[100px]"
            size="large"
            variant="outline"
            onClick={onApprove}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>

          <Button className="w-[100px]" size="large" onClick={onClose}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
