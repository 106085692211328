import { useMemo, useState } from 'react';
import { listResponsesColumns } from '../config/tables/columns';

/* eslint-disable import/prefer-default-export */
export const useDetailFormRequestResponsesFilter = ({
  setIsDeleteFormModalOpen,
  setIsTakeActionDrawerOpen,
}) => {
  const allColumns = listResponsesColumns({ setIsDeleteFormModalOpen, setIsTakeActionDrawerOpen });

  const [selectedFilter, setSelectedFilter] = useState(
    allColumns?.map((item) => item.accessor) || [],
  );

  const filteredColumns = useMemo(() => {
    return allColumns.filter((item) => selectedFilter.includes(item.accessor));
  }, [selectedFilter]);

  return {
    filteredColumns,
    selectedFilter,
    setSelectedFilter,
    allColumns,
  };
};
