import * as yup from 'yup';
import { FormResponseAction, FormType } from './constant';

export const createNewFormSchema = yup.object({
  title: yup.string().required('Field is required'),
  description: yup.string(),
  category: yup.string().required('Field is required'),
  form_type: yup.string().required('Field is required'),
});

export const formConfigSchema = yup.object().shape({
  description: yup.string().nullable(),
  document_reference: yup.string().required('Document reference is required'),
  category: yup.string().required('Category is required'),
  form_type: yup.string().required('Form type is required'),
  password: yup.string().when('form_type', {
    is: FormType.Survey,
    then: yup.string().required('Password is required for survey form'),
    otherwise: yup.string().nullable(),
  }),
});

export const submitFormSchema = yup.object().shape({
  form_type: yup.string(),
  email: yup.string().email().required('Email is required'),
  password: yup.string().required('Password is required'),
  signature: yup.string().required('Signature is required'),
  isDueDate: yup.boolean(),
  start_date: yup.string().when('isDueDate', {
    is: true,
    then: yup.string().required('Start date is required'),
    otherwise: yup.string().nullable(),
  }),
  start_time: yup.string().when('isDueDate', {
    is: true,
    then: yup.string().required('Start time is required'),
    otherwise: yup.string().nullable(),
  }),
  end_date: yup.string().when('isDueDate', {
    is: true,
    then: yup.string().required('End date is required'),
    otherwise: yup.string().nullable(),
  }),
  end_time: yup.string().when('isDueDate', {
    is: true,
    then: yup.string().required('End time is required'),
    otherwise: yup.string().nullable(),
  }),
});

export const approverFormSchema = yup.object().shape({
  approvers: yup.array().min(1, 'At least one approver is required'),
});

export const requestRejectedFormSchema = yup.object().shape({
  reason: yup.string().required('Reason is required'),
});

export const requestApprovedFormSchema = yup.object().shape({
  signature: yup.string().required('Signature is required'),
});

export const takeActionFormSchema = yup.object().shape({
  start_date: yup.string().required('Start date is required'),
  end_date: yup.string().required('End date is required'),
  action: yup.string().required('Action is required'),
  extension_date: yup.string().when('action', {
    is: FormResponseAction.Extension,
    then: yup.string().required('Extension date is required'),
    otherwise: yup.string().nullable(),
  }),
  reason: yup.string().when('action', {
    is: (value) =>
      value === FormResponseAction.Extension || value === FormResponseAction.ExtensionCancellation,
    then: yup.string().required('Reason is required'),
    otherwise: yup.string().nullable(),
  }),
  signature: yup.string().required('Signature is required'),
});
